import React from 'react'
import {useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function Support(props) {
	const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    props.ptitle('Support');
    useEffect(()=>{
        props.urlfun();
    }, [props]);
  return (
    <section className="p_bT">
	<div className="container-fluid">
		<div className="row" style={{padding: '1.25rem 0'}}>
		    <div className="col-lg-2"></div> 
			  
			<div className="col-lg-8">
				<div className="formBg">
					<form id="basic-form">
						<div className="row">
							<div className="col-lg-12 mb-3">
								<div>
									<h1 className="text-center contactHeading">Submit a request</h1>
								</div>
							</div>
							<div className="col-lg-6 margResInputFields">
								<div className="inputFields">
									<input required='' type='text' className="inputStyles" name="email" />
									<label alt='Email' placeholder='Email' className="textFields"></label>
								</div>
							</div>
							<div className="col-lg-6 margResInputFields">
								<div className="inputFields">
									<input required='' type='text' className="inputStyles" name="subject" />
									<label alt='Subject' placeholder='Subject' className="textFields"></label>
								</div>
							</div>
							<div className="col-lg-12 margResInputFields">
								<div className="inputFields">
									<textarea className="textareaStyles" required='' rows="5" placeholder="Description" name="message" style={{padding: '10px'}}></textarea>
								</div>
							</div>
							<div className="col-lg-12 margResInputFields">
								<div className="inputFields mt-4">
									<label><b>Attachments</b></label>
									<input type="file" name="upload_image" className="textareaStyles" />
								</div>
							</div>
							<div className="col-lg-4 mt-4">
								<button className="buttonSend" tabIndex="0" type="submit" style={{cursor: 'pointer'}}>
									<span className="">
										Submit
									</span>
									<span className="ml-2"><i className="icofont-paper-plane"></i></span> 
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="col-lg-2"></div>
		</div>
	</div>
</section>
  )
}
