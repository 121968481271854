import React from 'react'

export default function Template1(props) {
  return (
    <>
        <section className="invoiceDesign2Bg py-5">
            <div className="container mb-5" style={{pageBreakAfter: 'always', width: '100%', height: '100%', display: 'none'}}>
                <div className="row">
                    <div className="col-lg-8 col-md-6">
                        <div>
                            <img src={props.invoiceData.logo} className="logoImgInvoice2" alt="logo" />
                        </div>
                        <div className="row pt-5">
                            <div className="col-lg-6">
                                <h5 className="invoiceHeading2 ml-2">To: To Name</h5>
                                <h6 className="invoiceHeading2 ml-2 dateIssue">To Address</h6>
                                <h6 className="invoiceHeading2 ml-2 dateIssue"><i className="fas fa-phone-square-alt iconInvoice2"></i> <span className="mobileInvoice2 ml-2" style={{color: 'red'}}>To Num</span></h6>
                            </div>
                            <div className="col-lg-6">
                                <h5 className="invoiceHeading2 ml-2">From: From Name</h5>
                                <h6 className="invoiceHeading2 ml-2 dateIssue">From Address</h6>
                                <h6 className="invoiceHeading2 ml-2 dateIssue"><i className="fas fa-phone-square-alt iconInvoice2"></i> <span className="mobileInvoice2 ml-2" style={{color: 'red'}}>From Num</span></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pt-5">
                            <h6 className="invoiceHeading2 ml-2 mb-0 dateIssue">Date: <span className="ml-1 dateIssue" >Current Date</span></h6>
                            {/* <?php if ($posts->invoice_type == "Invoice") { ?> */}
                            <h6 className="invoiceHeading2 ml-2 my-1 dateIssue">Due Date: <span className="ml-1 dateIssue" >Due Date</span></h6>
                            {/* <?php } ?> */}
                            <h6 className="invoiceHeading2 ml-2 dateIssue">Invoice Type no: <span className="ml-1 dateIssue" >Invoice Number</span></h6>
                            {/* <?php if($posts->person_from_ntn != '0'){ ?> */}
                            <h6 className="invoiceHeading2 ml-2 dateIssue">Ntn# <span className="ml-1 dateIssue" >From NTN number</span></h6>
                            {/* <?php } ?> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pt-5 inv2design" style={{color: 'white'}}>
                            {/* <?php echo $posts->cover_letter; ?> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <?php } ?> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6">
                        {/* <?php if (!empty($posts->logo_image)) { ?> */}
                        <div>
                            <img src={props.invoiceData.logo} className="logoImgInvoice2" alt="logo" />
                        </div>
                        {/* <?php } ?> */}
                        <div className="row pt-5">
                            <div className="col-lg-6">
                                <h5 className="invoiceHeading2 ml-2">To: {props.invoiceData.cName}</h5>
                                <h6 className="invoiceHeading2 ml-2 dateIssue">{props.invoiceData.cAddress}</h6>
                                <h6 className="invoiceHeading2 ml-2 dateIssue"><i className="fas fa-phone-square-alt iconInvoice2"></i> <span className="mobileInvoice2 ml-2" style={{color: props.invoiceData.color }}>{props.invoiceData.cPhone}</span></h6>
                            </div>
                            <div className="col-lg-6">
                                <h5 className="invoiceHeading2 ml-2">From: {props.invoiceData.fName}</h5>
                                <h6 className="invoiceHeading2 ml-2 dateIssue">{props.invoiceData.fAddress}</h6>
                                <h6 className="invoiceHeading2 ml-2 dateIssue"><i className="fas fa-phone-square-alt iconInvoice2"></i> <span className="mobileInvoice2 ml-2" style={{color: props.invoiceData.color }}>{props.invoiceData.fPhone}</span></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pt-5">
                            <h3><span className="invoiceTitle2" style={{backgroundColor: props.invoiceData.color}}>{props.invoiceData.invoiceType}</span></h3>
                        </div>
                        <div className="pt-3">
                            <h5 className="invoiceHeading2 ml-2">{props.invoiceData.invoiceType} Details</h5>
                            <h6 className="invoiceHeading2 ml-2 mb-0 dateIssue">Date: <span className="ml-1 dateIssue" >{props.invoiceData.cDate}</span></h6>
                            {/* <?php if ($posts->invoice_type == "Invoice") { ?> */}
                            <h6 className="invoiceHeading2 ml-2 my-1 dateIssue">Due Date: <span className="ml-1 dateIssue" >{props.invoiceData.dDate}</span></h6>
                            {/* <?php } ?> */}
                            <h6 className="invoiceHeading2 ml-2 dateIssue">{props.invoiceData.invoiceType} No: <span className="ml-1 dateIssue" >{props.invoiceData.invoiceNumber}</span></h6>
                            {/* <?php if($posts->person_from_ntn != '0'){ ?> */}
                            {/* <h6 className="invoiceHeading2 ml-2 dateIssue">Ntn# <span className="ml-1 dateIssue" >From NTN</span></h6> */}
                            {/* <?php } ?> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pt-5">
                            <table className="table table-responsive tableRes2">
                                <thead className="tableHeadingBg2" style={{background:props.invoiceData.color}}>
                                    <tr className="invoiceTr2">
                                        <th>Item</th>
                                        <th>Item Description</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Total </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.invoiceData.item.map((element)=>{
                                        return <tr key={element.id}>
                                        <td style={{borderBottom: '1px solid'+props.invoiceData.color, fontWeight: '600'}}>{element.pName}</td>
                                        <td style={{borderBottom: '1px solid'+props.invoiceData.color, textAlign: 'left'}}>{element.pDescription}</td>
                                        <td style={{borderBottom: '1px solid'+props.invoiceData.color}}>{element.pQty}</td>
                                        <td style={{borderBottom: '1px solid'+props.invoiceData.color}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                        <td style={{borderBottom: '1px solid'+props.invoiceData.color}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                    </tr>
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row pt-2 pb-3">
                    <div className="col-lg-6 col-md-6">
                        <div className="pt-2">
                            {/* <?php if (!empty($posts->extra_note)) { ?> */}
                            <h5 className="infoInvoice2 pt-2 pb-3" style={{color: props.invoiceData.color}}>Additional Details:</h5>
                                <p className="mb-0 text-justify termsInvoice2" style={{width: '90% !important'}}>{props.invoiceData.aDetail}</p>
                            {/* <?php } ?> */}
                        </div>
                        
                        <div className="pt-0">
                            <h6 className="infoInvoice2 pt-3" style={{color: props.invoiceData.color}}>Thank you very much</h6>
                            { props.invoiceData.terms !== 'null' && <p className="mb-0 text-justify termsInvoice2" style={{width: '70%'}}>{props.invoiceData.terms}</p> }
                            
                            <p className="infoInvoice2 iconsSocial2 pt-3">
                                {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>}
                                {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                    <i className="fab fa-twitter-square ml-2"></i>
                                </a>}
                                {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                </a>}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="row pt-4">
                            <div className="col-lg-6 col-md-6">
                                <p className="text-center priceSub2">SubTotal</p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <p className="text-center priceSub2">{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
                            </div>
                        </div>
                        {props.invoiceData.discount && <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <p className="text-center priceSub2">Discount</p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                            
                                    <p className="text-center priceSub2">
                                       {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                    </p>
                                
                            </div>
                        </div>}
                        
                        {props.invoiceData.tax && <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <p className="text-center priceSub2">Tax</p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                
                                <p className="text-center priceSub2">
                                    {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                    </p>
                                
                            </div>
                        </div>}
                        
                        {props.invoiceData.shipment && <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <p className="text-center priceSub2">Shipping</p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                
                                    <p className="text-center priceSub2">{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                
                            </div>
                        </div>}
                        <div className="invoiceFooter2 pt-2" style={{backgroundColor: props.invoiceData.color}}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-right priceBalance2 mb-2"><b>Balance Due:</b></p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-center priceBalance2 mb-2"><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
