import React from 'react'
import Loading from '../../images/loading.svg';

export default function Spinner() {
  return (
    <>
        <div className="container">
            <div className="text-center">
                <img src={Loading} alt="Spinner" />
            </div>
        </div>
    </>
  )
}
