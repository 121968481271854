import React, { useEffect, useState } from 'react'
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Account(props) {
    const [name, setName] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [address, setAddress] = useState('');
    const [fb_link, setFbLink] = useState('');
    const [twitter_link, setTwitterLink] = useState('');
    const [insta_link, setInstalink] = useState('');
    const [two_step_verification, setTwoStep] = useState('off');
    const [terms_and_condition, setTerms] = useState('');
    // const [dblogo, setdblogo] = useState(null);
    const [logo, setLogo] = useState('');
    // const [logoSrc, setLogoSrc] = useState('');
    // const [img, setImg] = useState('');
    // const [src, setSrc] = useState('');
    props.ptitle("Account Setting");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [progress, setProgress] = useState(0);
    const getData = async (apiKey, bToken) => {
        setProgress(40);
        const token = bToken; 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_user_detail/${JSON.parse(localStorage.getItem("user_id"))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        console.log(apiResult);
        if(apiResult.data.success === true){
          let data = apiResult.data.user;
          setAddress(data.address);
          setEmail(data.email);
          setFbLink(data.fb_link);
          setInstalink(data.insta_link);
          setLogo(data.logo);
          // setdblogo(data.logo);
          setMobileNumber(data.mobile_number);
          setName(data.name);
          setTwitterLink(data.twitter_link);
          setTwoStep(data.two_step_verification);
          // setSrc(apiResult.data.src);
          setProgress(100);
        }
    }
    useEffect(()=>{
      getData(props.apiKey, props.bToken);
    }, [props.apiKey, props.bToken]);
    //handle Two Factor 
    const handle2Factor  = (e, value) =>{
      if(two_step_verification === 'off'){
        setTwoStep('on')
      }
      else{
        setTwoStep('off');
      }
    }
 const handleUpdate = async (e)=> {
  e.preventDefault();
  console.warn(logo);
  setProgress(40);
  const token = props.bToken; 
  const apiResult = await axios({
      method: 'post',
      url: `${props.apiKey}update_user_details`,
      data:{
        id: JSON.parse(localStorage.getItem("user_id")), name, address, email, fb_link, twitter_link, two_step_verification, mobile_number, insta_link, password, terms_and_condition, logo, data_url: logo
      },
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
      }
  });
  console.log(apiResult.data);
  toast.success("Account Setting Updated Successfully", {
    position: "bottom-right",
    aposition: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
});
  getData(props.apiKey, props.bToken);
 };
//  const setupAdminLogo = async (e) =>{
//   const token = props.bToken;
//     const apiResult = await axios({
//         method: 'post',
//         url: `${props.apiKey}file_save`,
//         data: {
//             image: e.target.files[0]
//         },
//         headers:{
//             Accept: 'application/javascript',
//             Authorization: `Bearer ${token}`,
//             "Content-type": "multipart/form-data",
//         }
//       });
//       console.log(apiResult);
//       if(apiResult.data.status === 200){
//       setLogo(e.target.files[0]);
//       // setLogoSrc(apiResult.data.src);
//       // setImg(apiResult.data.image);
//       }
//  }

 const handUploadImage = (e) => {
  setLogo(e.target.files[0]);
  const reader = new FileReader();

  reader.addEventListener("load", () => {
    console.log(reader.result);
    setLogo(reader.result);
  });

  reader.readAsDataURL(e.target.files[0]);
 }
  return (
    <>
      <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">Account Settings</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Account Settings</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>2-Step Verification</strong></label>
                      <label className="switch ml-5">
                        <input type="checkbox" value="on" name="two_step_verification" onClick={(e)=>handle2Factor(e, two_step_verification)} checked={two_step_verification === 'on'?true:false} />
                        <span className="sliderCheck round"></span>
                      </label>
                    </div>
                    <div className="form-group">
                      <label style={{background: '#eceeef', padding: '10px'}}>With 2-Step Verification you add an extra layer of security to your account in case your password is stolen. After you set up 2-Step Verification, you'll sign in to your account in two steps using: Something you know, like your password And Verification Code Will be Send on your given email address. </label>
                    </div>
                    <div className="form-group">
                      <label><strong>Name</strong></label>
                      <input type="text" value={name} onChange={(e)=>setName(e.target.value)} name="name" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Email</strong></label>
                      <input type="text" value={email} className="form-control" disabled />
                    </div>
                    {JSON.parse(localStorage.getItem('role')) === 'user'?
                    <>
                        {/* user Section */}
                        {
                          !logo?<>
                          <div className="form-group">
                            <label><strong>Upload Logo</strong></label>
                            <input type="file" name="upload_image" className="form-control" id="upload_image" onChange={handUploadImage} />
                            <div id="uploaded_image"></div>
                          </div>
                          </>:
                          <>
                          <div className="form-group">
                            <label><strong>Current Logo</strong></label>
                            <img src={logo} style={{width: '150px'}} alt="logo" />
                          </div>
                          <div className="form-group">
                            <label><strong>Change Logo</strong></label>
                            <input type="file" name="upload_image" className="form-control" id="upload_image" onChange={handUploadImage} />
                            <div id="uploaded_image"></div>
                          </div>
                          </>
                        }
                        
                    
                        
                        <div className="form-group">
                          <label><strong>Mobile Number</strong></label>
                          <input type="number" value={mobile_number} onChange={(e)=>setMobileNumber(e.target.value)} name="mobile_number" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label><strong>Address</strong></label>
                          <input type="address" value={address} onChange={(e)=>setAddress(e.target.value)} name="address" className="form-control" />
                        </div>
                    </>:
                    <>
                      {
                          !logo?<>
                          <div className="form-group">
                            <label><strong>Upload Logo</strong></label>
                            <input type="file" name="upload_image" className="form-control" id="upload_image" onChange={handUploadImage} />
                            <div id="uploaded_image"></div>
                          </div>
                          </>:
                          <>
                          <div className="form-group">
                            <label><strong>Current Logo</strong></label>
                            <img src={logo} style={{width: '150px'}} alt="logo" />
                          </div>
                          <div className="form-group">
                            <label><strong>Change Logo</strong></label>
                            <input type="file" name="upload_image" className="form-control" id="upload_image" onChange={handUploadImage} />
                            <div id="uploaded_image"></div>
                          </div>
                          </>
                        }
                      {/* {logo && <div className="form-group">
                            <label><strong>Current Logo</strong></label>
                            <img src={logoSrc+img} style={{width: '150px'}} alt="logo" />
                          </div>}
                        
                    
                        <div className="form-group">
                          <label><strong>Change Logo</strong></label>
                          <input type="file" name="upload_image" className="form-control" id="upload_image" onChange={handUploadImage}/>
                          <div id="uploaded_image"></div>
                        </div> */}
                    </>
                    }

                    <div className="form-group">
                      <label><strong>Password</strong></label>
                      <input type="password"  className="form-control" disabled />
                    </div>
                    <hr />
                    <div className="form-group">
                      <label><strong>Change Password</strong></label>
                      <input type="text" name="password" className="form-control"  value={password} onChange={(e)=>setPassword(e.target.value)} />
                    </div>
                    {JSON.parse(localStorage.getItem('role')) === 'user' &&
                    <>
                    <div className="form-group">
                      <label><strong>Terms And Conditions</strong></label>
                      <textarea className="form-control" onChange={(e)=>setTerms(e.target.value)} name="terms_and_condition">{terms_and_condition}</textarea>
                    </div>
                    <div className="form-group">
                      <label><strong>Facebook Link</strong></label>
                      <input type="text" value={fb_link} onChange={(e)=>setFbLink(e.target.value)} name="fb_link" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Twitter Link</strong></label>
                      <input type="text" value={twitter_link} onChange={(e)=>setTwitterLink(e.target.value)} name="twitter_link" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Instagram Link</strong></label>
                      <input type="text" value={insta_link} onChange={(e)=>setInstalink(e.target.value)} name="insta_link" className="form-control" />
                    </div>
                    </>
                    }                  
                    <div className="form-group">       
                      <input type="submit" value="Update" className="btn btn-primary" onClick={handleUpdate} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
draggable
 />
    </>
  )
}
