import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Spinner from '../Spinner';
import 'react-toastify/dist/ReactToastify.css';
export default function Singletblog(props) {
    const [blog, setBlog] = useState([]);
    const [share, setShare] = useState('0');
    const [imgUrl, setImgUrl] = useState('');
    const [blogId, setBlogId] = useState('');
    const [loading, setLoading] = useState(true);
    const getBlog = async (apiKey, bToken) =>{
        let slug = window.location.pathname.slice(15);
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}single_template_blog/${slug}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        setBlog(apiResult.data.blog);
        setImgUrl(apiResult.data.src);
        setShare(apiResult.data.blog[0].blog_share);
        setBlogId(apiResult.data.blog[0].id);
        setLoading(false);
    }
    useEffect(()=>{
        getBlog(props.apiKey, props.bToken);
    }, [props])
    const handleShareIncrease = async () =>{
        const token = props.bToken;
        const apiResult = await axios({
            method: 'post',
            url: `${props.apiKey}increase_template_share`,
            data: {
                id: blogId
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
        setShare(apiResult.data.share);
        if(apiResult.data.success === true){
            toast.success("Blog Shared SuccesssFully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
    }
  return (
    <>
    {loading === true ? <Spinner /> : ''}
        {blog.map((blog)=>{
            return <>
                <section style={{backgroundColor: '#f1f1f1' }} key={blog.id}>
                    <div className="container resInvoiceContain">
                        <div className="row">
                            <div className="col-lg-11">
                                <div className="pt-5">
                                    <div>
                                        <h1 className="mt-5 mb-4 blog-title-heading">{blog.blog_title}</h1>
                                    </div>
                                    <div className="text-center pb-5">
                                        <img src={imgUrl + blog.blog_image} className="mr-5 blog-img-single" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <div className="entry-sticky-shares d-lg-block d-md-none">
                                    <ul className="entry-shares">
                                        <li className="share-sum">{share} <br /> <span>shares</span></li>
                                        <li><a href={'https://twitter.com/intent/tweet?url=<' + blog.slug} title="Share on Twitter" rel="noopener noreferrer" target="_blank" className="share-btn" onClick={handleShareIncrease}  value="<?php echo $posts->id; ?>"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href={'https://www.facebook.com/sharer.php?u=' + imgUrl} title="Share on Twitter" rel="noopener noreferrer" target="_blank" className="share-btn" onClick={handleShareIncrease}  value="<?php echo $posts->id; ?>"><i className="fab fa-facebook-f" style={{color: '#66679d' }}></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{backgroundColor: 'white'}} className="py-5">
                    <div className="container resInvoiceContain">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-blog-description" style={{width: '85%', margin: 'auto' }} dangerouslySetInnerHTML={{__html: blog.blog_description}}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        })}

<ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
