import React from 'react'

export default function Template3(props) {
  return (
    <>
        <section style={{pageBreakAfter: 'always', marginLeft: '0px', width: '580px', Height: '100vh'}}>
            <div className="container resContainer">
                <div className="row">
                    <div className="col-2 col-lg-2 col-md-2" style={{paddingLeft: '0px'}}>
                        <div className="invoiceleft1HalfBg4">
                            <div className="invoiceHeading4">
                                <h1 className="text-uppercase" style={{fontSize: '25px'}}>{props.invoiceData.invoiceType}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 col-lg-9 col-md-9">
                        <div className="row">
                            <div className="col-8 col-lg-8 col-md-6">
                                <div className="row pt-5">
                                    <div className="col-6 col-lg-6">
                                        <h4 className="pt-4 invoice4Heading4" style={{fontSize: '16px'}}>{props.invoiceData.invoiceType} to:</h4>
                                        <h5 className="invoice4Heading5" style={{fontSize: '14px'}}>{props.invoiceData.cName}</h5>
                                        <h6 className="dateIssue addressInvoice4" style={{fontSize: '14px'}}>{props.invoiceData.cAddress}</h6>
                                        <h6 className="dateIssue addressInvoice4" style={{fontSize: '14px'}}>{props.invoiceData.cPhone}</h6>
                                    </div>
                                    <div className="col-6 col-lg-6">
                                        <h4 className="pt-4 invoice4Heading4" style={{fontSize: '16px'}}>From:</h4>
                                        <h5 className="invoice4Heading5" style={{fontSize: '14px'}}>{props.invoiceData.fName}</h5>
                                        <h6 className="dateIssue addressInvoice4" style={{fontSize: '14px'}}>{props.invoiceData.fAddress}</h6>
                                        <h6 className="dateIssue addressInvoice4" style={{fontSize: '14px'}}>{props.invoiceData.fPhone}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-lg-4 col-md-6">
                                <div className="pt-5 text-right" style={{background: `url(${props.invoiceData.logo})`,  width: '150px', height: '80px', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                                    {/* <img src={props.invoiceData.logo} className="logoImgInvoice2" alt="" /> */}
                                </div>
                                <div className="row">
                                    <div className="col-5 col-lg-5 col-md-5 pt-3">
                                        <h5 className="pt-4 invoice4DateHeading4" style={{fontSize: '14px'}}>{props.invoiceData.invoiceType}#</h5>
                                        <h5 className="invoice4DateHeading4" style={{fontSize: '11px'}}>Date</h5>
                                        <h5 className="invoice4DateHeading4" style={{fontSize: '11px'}}>DueDate</h5>
                                    </div>
                                    <div className="col-7 col-lg-7 col-md-7 pt-3">
                                        <h5 className="pt-4 text-right invoice4DateHeading5" style={{fontSize: '14px'}}>{props.invoiceData.invoiceNumber}</h5>
                                        <h5 className="text-right invoice4DateHeading5" style={{fontSize: '11px'}}>{props.invoiceData.cDate}</h5>
                                        <h5 className="text-right invoice4DateHeading5" style={{fontSize: '11px'}}>{props.invoiceData.dDate}</h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12 col-lg-12">
                                <table className="table table-responsive tableRes4">
                                    <thead className="tableHeadingBg" style={{fontSize: '12px', padding: '5px'}}>
                                        <tr style={{fontSize: '12px', padding: '5px'}}>
                                            <th style={{textAlign: 'left', padding: '5px' }}>Item</th>
                                            <th style={{textAlign: 'left', padding: '5px' }}>Description</th>
                                            <th style={{padding: '5px'}}>Qty</th>
                                            <th style={{textAlign: 'right', padding: '5px' }}>Price</th>
                                            <th style={{textAlign: 'right', padding: '5px' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{fontSize: '12px', padding: '5px'}}>                                       
                                        {props.invoiceData.item.map((element)=>{
                                            return <tr style={{padding: '5px'}}>
                                                    <td style={{textAlign: 'left', padding: '5px' }}>{element.pName}</td>
                                                    <td style={{textAlign: 'left', padding: '5px' }}>{element.pDescription}</td>
                                                    <td style={{padding: '5px'}}>Product QTY</td>
                                                    <td style={{textAlign: 'right', padding: '5px' }}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                    <td style={{textAlign: 'right', padding: '5px' }}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                                </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 col-lg-1 col-md-1" style={{paddingRight: '0px'}}>
                        <div className="bgInvoice4Right1" style={{backgroundColor: props.invoiceData.color}}></div>
                        <div className="bgInvoice4Right2"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1 col-lg-1 col-md-1" style={{paddingLeft: '0px'}}>
                        <div className="bgInvoice3Left" style={{backgroundColor: props.invoiceData.color, height: '370px'}}>
                            <h5 style={{fontSize: '14px'}}>Thank You Very Much</h5>
                        </div>
                    </div>
                    <div className="col-10 col-lg-10 col-md-10">
                        <div className="row">
                            <div className="col-6 col-lg-6 col-md-6">
                                
                            </div>
                            <div className="col-6 col-lg-6 col-md-6">
                                <div className="row pt-4">
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>SubTotal:</p>
                                    </div>
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
                                    </div>
                                </div>
                                {props.invoiceData.discount && <div className="row">
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>Discount:</p>
                                    </div>
                                    <div className="col-6 col-lg-6 col-md-6">
                                        
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>
                                            {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                        </p>
                                    </div>
                                </div>}
                                
                                {props.invoiceData.tax && <div className="row">
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>Tax:</p>
                                    </div>
                                    <div className="col-6 col-lg-6 col-md-6">
                                    
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>
                                            {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                        </p>
                                    </div>
                                </div>}
                                
                                {props.invoiceData.shipment && <div className="row">
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>Shipping:</p>
                                    </div>
                                    <div className="col-6 col-lg-6 col-md-6">
                                        
                                        <p className="text-right priceSub3" style={{fontSize: '12px'}}>{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                        
                                        
                                    </div>
                                </div>}
                                
                                <div className="invoiceFooter3 pt-2" style={{width: '70%', marginLeft: '30%'}}>
                                    <div className="row">
                                        <div className="col-6 col-lg-6 col-md-6">
                                            <p className="text-left priceBalance4 mb-2" style={{fontSize: '12px'}}><b>Balance Due:</b></p>
                                        </div>
                                        <div className="col-6 col-lg-6 col-md-6">
                                            <p className="text-right priceBalance4 mb-2" style={{fontSize: '12px'}}><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-lg-6">
                                <div className="pt-3 ml-5" style={{borderTop: '2px solid black'}}>
                                    {props.invoiceData.aDetail && <div><h5 className="terms4Invoice">Additional Details:</h5>
                                    <p className="terms4Invoicepara">{props.invoiceData.aDetail}</p></div>}
                                    <p className="infoInvoice2 iconsSocial2 pt-3">
                                        {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-facebook"></i>
                                        </a>}
                                        {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter-square ml-2"></i>
                                        </a>}
                                        {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                        </a>}
                                    </p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-6">
                                <div className="pt-3 ml-5" style={{borderTop: '2px solid black'}}>
                                    {props.invoiceData.terms && <div>
                                        <h5 className="terms4Invoice" style={{fontSize: '12px'}}>Terms & Conditions</h5>
                                    <small className="terms4Invoicepara" style={{fontSize: '12px'}}>{props.invoiceData.terms}</small>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 col-lg-1 col-md-1" style={{paddingRight: '0px'}}>
                        <div className="bgInvoice4Right">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
