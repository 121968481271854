import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
export default function InvoiceAction(props) {
    const [paymentType, setPaymentType] = useState('');
    const [bank , setBank] = useState(0);
    const [paypal, setPaypal] = useState(0);
    const [payoneer, setPayoneer] = useState(0);
    const [wise, setWise] = useState(0);
    const [skrill, setSkrill] = useState(0);
    const [getLink, setGetLink] = useState();
    const [sendMeEmail, setSendMeEmail] = useState('off');
    const [toEmailAddress, setToEmailAddress] = useState('');

    // get payment Details
    const checkpayments = async(apiKey, bToken) =>{
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_account_details/${JSON.parse(localStorage.getItem('user_email'))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          if(apiResult.data.success === true){
            let data = apiResult.data;
            setBank(data.bank);
            setPayoneer(data.payoneer);
            setWise(data.wise);
            setSkrill(data.skrill);
            setPaypal(data.paypal);
          }
    }
    useEffect(()=>{
        checkpayments(props.apiKey, props.bToken);
    }, [props.apiKey, props.bToken]);

    // Send Invoice
    const handleSendInvoice = async (event) =>{ 
        event.preventDefault();
        window.$('#paymentModal').modal('show');
    }

    // Download INvoice
    const handleDownloadInvoice = async (event) =>{ 
        event.preventDefault();
        let invoiceType = props.invoiceType;
        let currency = props.currency;
        let logoResult = props.logoResult;
        let fName = props.fName;
        let fPhone = props.fPhone;
        let fAddress = props.fAddress;
        let cName = props.cName;
        let cPhone = props.cPhone;
        let cAddress = props.cAddress;
        let invoiceNumber = props.invoiceNumber;
        let date = props.date;
        let itemBox = props.itemBox;
        let subTotal = props.subTotal;
        let tax = props.tax;
        let taxPer = props.taxPer;
        let discount = props.discount;
        let discountPer = props.discountPer;
        let total = props.total;
        let shipment = props.shipment;
        let aDetail = props.aDetail;
        let coverData = props.coverData;
        let coverSrc = props.coverSrc;
        let logodataurl = props.logodataurl;
        const token = props.bToken;
        if(fName && fAddress && fPhone && invoiceNumber && cName && cAddress && cPhone){
            const apiResulti = await axios({
            method: 'post',
            url: `${props.apiKey}check_invoice_number`,
            data: {
                invoiceNum: invoiceNumber, type: 1
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
            });
            console.warn(typeof apiResulti.data.status);
            if(apiResulti.data.status === 201){
                console.log('simple flow');
                window.open(`https://invoicies.com/Download/${invoiceNumber}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
            }
            else{
                let dataPreview = {sendType: '1', invoiceType, currency, logoResult, fName, fPhone, fAddress, cName, cPhone, cAddress, invoiceNumber, date , itemBox, subTotal, tax, taxPer, discount, discountPer, total, shipment, aDetail, coverData, coverSrc, dataUrl: logodataurl};
                const apiResult = await axios({
                    method: 'post',
                    url: `${props.apiKey}create_invoice`,
                    data: {
                        dataPreview, email: JSON.parse(localStorage.getItem('user_email'))
                    }, 
                    headers:{
                        Accept: 'application/javascript',
                        Authorization: `Bearer ${token}`
                    }
                });
                console.warn(apiResult);
                if(apiResult.data.status === 200){
                    toast.success("Invoice Created Successfully", {
                        position: "bottom-right",
                        aposition: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark'
                    });
                    // navigate(`/Download/${invoiceNumber}`);
                    window.open(`https://invoicies.com/Download/${invoiceNumber}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
                }
            }
        }
        else
        {
            toast.error("Please Fill the Credentionals", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
    }


    // Preview Invoice Action
    const handlePreviewInvoice = async (event) =>{ 
        event.preventDefault();
        let invoiceType = props.invoiceType;
        let currency = props.currency;
        let logoResult = props.logoResult;
        let fName = props.fName;
        let fPhone = props.fPhone;
        let fAddress = props.fAddress;
        let cName = props.cName;
        let cPhone = props.cPhone;
        let cAddress = props.cAddress;
        let invoiceNumber = props.invoiceNumber;
        let date = props.date;
        let itemBox = props.itemBox;
        let subTotal = props.subTotal;
        let tax = props.tax;
        let taxPer = props.taxPer;
        let discount = props.discount;
        let discountPer = props.discountPer;
        let total = props.total;
        let shipment = props.shipment;
        let aDetail = props.aDetail;
        let coverData = props.coverData;
        let coverSrc = props.coverSrc;
        const token = props.bToken;
        let logodataurl = props.logodataurl;
        if(fName && fAddress && fPhone && invoiceNumber && cName && cAddress && cPhone){
            const apiResulti = await axios({
            method: 'post',
            url: `${props.apiKey}check_invoice_number`,
            data: {
                invoiceNum: invoiceNumber, type: 2
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
            });
            console.warn(apiResulti.data.status);
            if(apiResulti.data.status === 201){
                console.log('simple flow');
                window.open(`https://invoicies.com/Preview/${invoiceNumber}`, '_blank');
            }
            else{
                let dataPreview = {sendType: '2', invoiceType, currency, logoResult, fName, fPhone, fAddress, cName, cPhone, cAddress, invoiceNumber, date , itemBox, subTotal, tax, taxPer, discount, discountPer, total, shipment, aDetail, coverData, coverSrc, dataUrl: logodataurl};
                const apiResult = await axios({
                    method: 'post',
                    url: `${props.apiKey}create_invoice`,
                    data: {
                        dataPreview, email: JSON.parse(localStorage.getItem('user_email'))
                    }, 
                    headers:{
                        Accept: 'application/javascript',
                        Authorization: `Bearer ${token}`
                    }
                });
                console.warn(apiResult);
                if(apiResult.data.status === 200){
                    window.open(`https://invoicies.com/Preview/${invoiceNumber}`, '_blank');
                }
            }
        }
        else
        {
            toast.error("Please Fill the Credentionals", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
    }

    // GetLink
    const handleGetLinkInvoice = async (event) =>{ 
        event.preventDefault();
        let invoiceType = props.invoiceType;
        let currency = props.currency;
        let logoResult = props.logoResult;
        let fName = props.fName;
        let fPhone = props.fPhone;
        let fAddress = props.fAddress;
        let cName = props.cName;
        let cPhone = props.cPhone;
        let cAddress = props.cAddress;
        let invoiceNumber = props.invoiceNumber;
        let date = props.date;
        let itemBox = props.itemBox;
        let subTotal = props.subTotal;
        let tax = props.tax;
        let taxPer = props.taxPer;
        let discount = props.discount;
        let discountPer = props.discountPer;
        let total = props.total;
        let shipment = props.shipment;
        let aDetail = props.aDetail;
        let coverData = props.coverData;
        let coverSrc = props.coverSrc;
        const token = props.bToken;
        let logodataurl = props.logodataurl;
        if(fName && fAddress && fPhone && invoiceNumber && cName && cAddress && cPhone){
            const apiResulti = await axios({
            method: 'post',
            url: `${props.apiKey}check_invoice_number`,
            data: {
                invoiceNum: invoiceNumber, type: 3
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
            });
            console.warn(apiResulti.data.status);
            if(apiResulti.data.status === 201){
                console.log('simple flow');
                window.open(`https://invoicies.com/Preview/${invoiceNumber}`, '_blank');
            }
            else{
                let dataPreview = {sendType: '3', invoiceType, currency, logoResult, fName, fPhone, fAddress, cName, cPhone, cAddress, invoiceNumber, date , itemBox, subTotal, tax, taxPer, discount, discountPer, total, shipment, aDetail, coverData, coverSrc, dataUrl: logodataurl};
                const apiResult = await axios({
                    method: 'post',
                    url: `${props.apiKey}create_invoice`,
                    data: {
                        dataPreview, email: JSON.parse(localStorage.getItem('user_email'))
                    }, 
                    headers:{
                        Accept: 'application/javascript',
                        Authorization: `Bearer ${token}`
                    }
                });
                console.warn(apiResult);
                if(apiResult.data.status === 200){
                    toast.success("Invoice Link Generated Successfully", {
                        position: "bottom-right",
                        aposition: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark'
                    });
                    setGetLink('Link');
                }
            }
        }
        else
        {
            toast.error("Please Fill the Credentionals", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
    }

    // setPayment
    const handlePaymentType = (e, type)=>{
        if(e.target.checked === false){
            setPaymentType("");
        }
        else{
            setPaymentType(type);
        }
    }
    // send invoice data
    const sendInvoiceData = async (e) =>{
        e.preventDefault();
        let invoiceType = props.invoiceType;
        let currency = props.currency;
        let logoResult = props.logoResult;
        let fName = props.fName;
        let fPhone = props.fPhone;
        let fAddress = props.fAddress;
        let cName = props.cName;
        let cPhone = props.cPhone;
        let cAddress = props.cAddress;
        let invoiceNumber = props.invoiceNumber;
        let date = props.date;
        let itemBox = props.itemBox;
        let subTotal = props.subTotal;
        let tax = props.tax;
        let taxPer = props.taxPer;
        let discount = props.discount;
        let discountPer = props.discountPer;
        let total = props.total;
        let shipment = props.shipment;
        let aDetail = props.aDetail;
        let coverData = props.coverData;
        let coverSrc = props.coverSrc;
        const token = props.bToken;
        let logodataurl = props.logodataurl;
        if(fName && fAddress && fPhone && invoiceNumber && cName && cAddress && cPhone){
            const apiResulti = await axios({
            method: 'post',
            url: `${props.apiKey}check_invoice_number`,
            data: {
                invoiceNum: invoiceNumber, type: 0
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
            });
            console.warn(apiResulti.data.status);
            if(apiResulti.data.status === 201){
                console.log('simple flow');
                window.open(`https://invoicies.com/Preview/${invoiceNumber}`, '_blank');
            }
            else{
                let dataPreview = {sendType: '0', paymentType, invoiceType, currency, logoResult, fName, fPhone, fAddress, cName, cPhone, cAddress, invoiceNumber, date , itemBox, subTotal, tax, taxPer, discount, discountPer, total, shipment, aDetail, coverData, coverSrc, dataUrl: logodataurl};
                const apiResult = await axios({
                    method: 'post',
                    url: `${props.apiKey}create_invoice`,
                    data: {
                        dataPreview, email: JSON.parse(localStorage.getItem('user_email'))
                    }, 
                    headers:{
                        Accept: 'application/javascript',
                        Authorization: `Bearer ${token}`
                    }
                });
                console.warn(apiResult);
                if(apiResult.data.status === 200){
                    toast.success("Invoice Send Successfully", {
                        position: "bottom-right",
                        aposition: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark'
                    });
                    window.$('#paymentModal').modal('hide');
                }
            }
        }
        else
        {
            toast.error("Please Fill the Credentionals", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }        
    }
    // Copy GetLnk
    const myCopyFunction = ()=>{
        var copyText = document.getElementById("showid6");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
    toast.success("Link Copied Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });

    }
    const handleCopyOnChange =() =>{}
    const setSendEmail = (e)=>{
        if(sendMeEmail === 'on'){
            setSendMeEmail('off');
        }
        else{
            setSendMeEmail('on');
        }
    }
  return (
    <>
        <div className="sticky" style={{padding: '1.25rem 0', top: '16%'}}>
            <div>
                <button className="buttonSend" tabIndex="0" type="button" style={{cursor: 'pointer'}} onClick={handleSendInvoice} >
                    <span>
                        Send
                    </span>
                    <span className="ml-2"><i className="icofont-paper-plane"></i></span> 
                </button>
            </div>
            <div className="mt-4">
                <button className="buttonDownload brnDowns" tabIndex="0" type="button" style={{cursor: 'pointer'}} onClick={handleDownloadInvoice} >
                    <span>
                        Download
                    </span>
                </button>
            </div>
            <div className="mt-4">
                <button className="buttonDownload getPreview" tabIndex="0" type="button" style={{cursor: 'pointer'}} onClick={handlePreviewInvoice}>
                    <span>
                        Preview
                    </span>
                </button>
            </div>
            {getLink && <div className="mt-4 idShow">
                <div className="d-flex">
                    <div style={{width: '75%'}}>
                        <input type="tel" id="showid6" style={{width: '100%', padding: '12px 4px', border: 'none'}} value={"https://invoicies.com/PreviewInvoice/"+props.invoiceNumber} onChange={handleCopyOnChange} />
                    </div>
                    <div style={{width: '25%'}}>
                        <button className="buttonDownload" tabIndex="0" type="button" style={{cursor: 'pointer', paddingLeft: '13px', paddingRight: '13px'}}>
                            <span id="showLink" onClick={myCopyFunction}>
                                <i className="fas fa-copy"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>}
            <div className="mt-4">
                <button className="buttonDownload buttonGet" tabIndex="0" type="button" onClick={handleGetLinkInvoice} style={{cursor: 'pointer'}}>
                    <span className="">
                        Get Link
                    </span>
                </button>
            </div>
        </div>

        <div className="modal fade" id="paymentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"><svg width="21" height="20" fill="none" viewBox="0 0 21 20"><path fill="#5096DA" d="M20.24.8a.42.42 0 00-.43-.06l-19.4 8a.42.42 0 00.01.8l5.34 2.06a.42.42 0 00.4-.04l7.08-5.04a.42.42 0 01.54.65l-5.9 5.69a.42.42 0 00-.13.3v5.68a.42.42 0 00.78.2l2.67-4.57a.21.21 0 01.29-.07l4.91 2.7a.42.42 0 00.62-.29l3.37-15.6a.43.43 0 00-.15-.4z"></path></svg> <span className="ml-3">Send Invoice</span></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    <div className="modal-body">
                    <div>
                            <h5 className="ml-3">To</h5>
                        </div>
                        <div className="col-lg-12 margResInput">
                            <div className="inputFields">
                                <input required='' type='email' id="ToEmailAddress" className="inputStyles" value={toEmailAddress} onChange={(e)=>setToEmailAddress(e.target.value)} />
                                <label alt='Email Address' placeholder='Email Address' className="textFields"></label>
                            </div>
                            <span id="toEmailError" style={{color: 'red'}}></span>
                        </div>
                        <div className="ml-3">
                            <input type="checkbox" id="copy" name="copy" value={sendMeEmail} onClick={setSendEmail} />
                            <label htmlFor="copy"> Send A Copy To Me</label><br />
                        </div>
                        {bank === 0 && wise === 0 && skrill === 0 && payoneer === 0 && paypal === 0?'':
                            <>
                                <div>
                                    <h5 className="ml-3">Payment Method</h5>
                                </div>
                                <div className="col-lg-12 margResInput">
                                    { paypal === 1 ? <>
                                    {
                                        paymentType === '' || paymentType === "paypal"?<>
                                        <div id="paypal1">
                                            <input type="checkbox" id="paypal" name="paypal" onClick={(e)=>handlePaymentType(e, 'paypal')} />
                                            <label htmlFor="Paypal"> Paypal</label><br />
                                        </div>
                                        </>: ""
                                    }
                                    </>:"" }
                                    
                                    {payoneer === 1 ? 
                                    <>
                                        {
                                            paymentType === "" || paymentType === 'payoneer' ? <>
                                                <div id="payoneer1">
                                                    <input type="checkbox" id="payoneer" name="payoneer" onClick={(e)=>handlePaymentType(e, 'payoneer')} />
                                                    <label htmlFor="payoneer"> Payoneer</label><br />
                                                </div>
                                            </>: ""
                                        }
                                    </>:""
                                    }
                                    
                                    {wise === 1 ? <>
                                        {
                                            paymentType === "" || paymentType === 'wise' ? <>
                                            <div id="wise1">
                                                <input type="checkbox" id="wise" name="wise" onClick={(e)=>handlePaymentType(e, 'wise')} />
                                                <label htmlFor="wise"> Wise</label><br />
                                            </div>
                                            </>:""
                                        }
                                    
                                    </>:""
                                    }
                                    
                                    {bank === 1 ? 
                                    <>
                                    {
                                            paymentType === "" || paymentType === 'bank' ? <>
                                                <div id="bank1">
                                                    <input type="checkbox" id="bank" name="bank" onClick={(e)=>handlePaymentType(e, 'bank')} />
                                                    <label htmlFor="bank"> Bank Account</label><br />
                                                </div>
                                            </>:""
                                    }
                                    </>:""
                                    }
                                    
                                    {skrill === 1 ? <>
                                        {
                                            paymentType === "" || paymentType === 'skrill' ? <>
                                                <div id="skrill1">
                                                    <input type="checkbox" id="skrill" name="skrill" onClick={(e)=>handlePaymentType(e, 'bank')} />
                                                    <label htmlFor="skrill"> Skrill Account</label><br />
                                                </div>
                                            </>:""
                                        }
                                    
                                    </>:""
                                    }
                                </div>
                            </>
                        }

                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary sendInvoice" style={{width: '100%'}} onClick={sendInvoiceData}>Send Invoice</button>
                        <div className="waitingMesg" style={{display:'none', width: '100%'}}>Wait Your Invoice Is Sending <img src="<?php echo base_url(); ?>assets/ui/images/sending.gif" style={{width:'20%'}} alt="default" /></div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
