import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import {Link, useNavigate} from "react-router-dom";

export default function List(props) {
    props.ptitle("Blog List");
    useEffect(()=>{
        props.urlfun();
    }, [props]);

    const [search, setSearch] = useState('');
    const [blog, setBlog] = useState([]);
    const [fiteredBlog, setFiteredBlog] = useState([]);
    const [progress, setProgress] = useState(0);
    const [newBlog, setNewBlog] = useState('201');
    const [updateBlog, setUpdateBlog] = useState('201');
    const navigate = useNavigate();

    useEffect(()=>{
        if(updateBlog === '200'){
          toast.success("Blog Updated Successfully", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
          localStorage.setItem("update_blog", JSON.stringify('201'));
        }
        else{
            setUpdateBlog(JSON.parse(localStorage.getItem("update_blog")));
        }
      }, [updateBlog]);
    useEffect(()=>{
        if(newBlog === '200'){
          toast.success("Blog Added Successfully", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          });
          localStorage.setItem("blog_add", JSON.stringify(201));
        }
        else{
            setNewBlog(JSON.parse(localStorage.getItem("blog_add")));
        }
      }, [newBlog]);

    useEffect(()=>{
        getDataFunc(props.apiKey, props.bToken);
      }, [props.apiKey, props.bToken]);


      useEffect(()=>{
        const result = blog.filter((blog)=>{
          return blog.blog_title.toLowerCase().match(search.toLowerCase());
        });
        setFiteredBlog(result);
      }, [search, blog]);
    

    // Get Blog Data
    const getDataFunc = async (apiKey, bToken) => {
        setProgress(20);
        const token = bToken; 
        const apiResult = await axios({
        method: 'get',
        url: `${apiKey}fetch_template_blogs`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
        });
        setFiteredBlog(apiResult.data.source);
        setBlog(apiResult.data.source);
        setProgress(100);
    }

    useEffect(()=>{
        getDataFunc(props.apiKey, props.bToken);
      }, [props.apiKey, props.bToken]);


    const column = [
        {
          name: "Sr",
          width: "80px",
          cell: (row, index) => index + 1,
        },
        {
          name: "Blog Title",
          selector: (row)=>row.blog_title,
          width: "350px",  
          sortable: true
        },
        {
          name: "Blog Description",
          selector: (row)=>row.blog_short_description,
          width: "550px",
          sortable: true
        },
        {
          name: "Aciton",
          cell: row=> <>
            <span className="btn btn-primary mr-2" onClick={()=> handleEditBlog(row.id)}>Edit</span>
            <span className="btn btn-danger" onClick={()=> handleDeleteBlog(row.id)}>Delete</span></>
        }
      ];

    //   Delete Blog
    const handleDeleteBlog = async (id) =>{
        setProgress(20);
        const token = props.bToken; 
        const apiResult = await axios({
        method: 'get',
        url: `${props.apiKey}delete_template_blog/${id}`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
        });
        if(apiResult.data.status === 200){
            toast.success("Blog Deleted Successfully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              });
              getDataFunc(props.apiKey, props.bToken);
        }
        setProgress(100);
    }
    const handleEditBlog = (id) =>{
        navigate(`/dashboard/edit-template-blog/${id}`);
    }

  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
    <div className="breadcrumb-holder">
      <div className="container-fluid">
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/dashboard/add-blog">Home</Link></li>
          <li className="breadcrumb-item active">All Template Blogs</li>
        </ul>
      </div>
    </div>
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4>All Template Blogs</h4>
                  </div>
                  <div>
                    <Link to="/dashboard/add-template-blog" type="button" className="btn btn-primary">Add Blog</Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                <DataTable 
                    columns={column} 
                    data={fiteredBlog} 
                    pagination
                    fixedHeader
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                        <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                    }
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ToastContainer position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover />
    </>
  )
}
