import React, { useEffect } from 'react'
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function WebMasterTool(props) {
    props.ptitle("Web Master Tools");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const [progress, setProgress] = useState(0);
  const [id, setId] = useState('');
  const [Baidu, setBaidu] = useState('');
  const [Bing, setBing] = useState('');
  const [Google, setGoogle] = useState('');
  const [Yandex, setYandex] = useState('');
    // Get Data  
  const getDataFunc = async (apiKey, bToken) => {
    setProgress(20);
    const token = bToken; 
    const apiResult = await axios({
      method: 'get',
      url: `${apiKey}get_webmastertools`,
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    if(apiResult.data.success === true){
      setId(apiResult.data.list[0].id);
      setBaidu(apiResult.data.list[0].baidu);
      setBing(apiResult.data.list[0].bing);
      setGoogle(apiResult.data.list[0].google);
      setYandex(apiResult.data.list[0].yandex);
    }
    setProgress(100);
  }
  useEffect(()=>{
    getDataFunc(props.apiKey, props.bToken);
  }, [props.apiKey, props.bToken]);

  const saveWebMaster = async (e) =>{
    e.preventDefault();
    setProgress(20);
    const token = props.bToken; 
    const apiResult = await axios({
      method: 'post',
      url: `${props.apiKey}update_webmastertools`,
      data: {
        id, baidu: Baidu, bing: Bing, google: Google, yandex: Yandex
      },
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    if(apiResult.data.success === true){
      toast.success("WebMaster Updated Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
    }
    setProgress(100);
  }
  return (
    <>
        <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Web Master Tools</li>
          </ul>
        </div>
      </div>
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Verification Codes</h4>
                </div>
                {/* <?php foreach ($post as $posts) {?> */}
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Biadu Verification Code</strong></label>
                      <input type="text" value={Baidu} onChange={(e)=>setBaidu(e.target.value)} name="baidu" className="form-control" />
                      <small className="mt-2">Get your Baidu verification code in <a href="https://www.bing.com/toolbox/webmaster/#/Dashboard/?url=https%3A%2F%2Fthepcdownloads.com">Baidu Webmaster Tools</a>.</small>
                    </div>
                    <div className="form-group">
                      <label><strong>Bing Verification Code</strong></label>
                      <input type="text" value={Bing} onChange={(e)=>setBing(e.target.value)} name="bing" className="form-control" />
                      <small className="mt-2">Get your Bing verification code in <a href="https://www.bing.com/toolbox/webmaster/#/Dashboard/?url=https%3A%2F%2Fthepcdownloads.com">Bing Webmaster Tools</a>.</small>
                    </div>
                    <div className="form-group">
                      <label><strong>Google Verification Code</strong></label>
                      <input type="text" value={Google} onChange={(e)=>setGoogle(e.target.value)} name="google" className="form-control" />
                      <small className="mt-2">Get your Google verification code in <a href="https://www.google.com/webmasters/verification/verification?hl=en&tid=alternate&siteUrl=https%3A%2F%2Fthepcdownloads.com/">Google Webmaster Tools</a>.</small>
                    </div>
                    <div className="form-group">
                      <label><strong>Yandex Verification Code</strong></label>
                      <input type="text" value={Yandex} onChange={(e)=>setYandex(e.target.value)} name="yandex" className="form-control" />
                      <small className="mt-2">Get your Yandex verification code in <a href="https://webmaster.yandex.com/sites/add/">Yandex Webmaster Tools</a>.</small>
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary" onClick={saveWebMaster} />
                    </div>
                  </form>
                </div>
                {/* <?php } ?> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover />
    </>
  )
}
