import axios from 'axios';
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Template1 from '../DownloadPreview/Template1';
import Template2 from '../DownloadPreview/Template2';
import Template3 from '../DownloadPreview/Template3';
import Template4 from '../DownloadPreview/Template4';
import Template5 from '../DownloadPreview/Template5';
import jsPDF from 'jspdf';
import Template0 from '../DownloadPreview/Template0';
export default function Download(props) {
    const navigate = useNavigate();
    const [pdf, setPDF] = useState('0');
    props.ptitle("Invoice Templates");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [logoDataUrl, setLogoDataUrl] = useState('');

    const [invoiceDesign, setInvoiceDesign] = useState('');
    // Master Color
    const [color, setColor] = useState('');
    // InvoiceType
    const [invoiceType, setInvoiceType] = useState('');
    // Logo
    const [logo, setLogo] = useState('');
    // Logo src
    const [src, setSrc] = useState('');
    // Client Data
    const [cName, setCName] = useState('');
    const [cPhone, setCPhone] = useState('');
    const [cAddress, setCAddress] = useState('');
    // user Data
    const [fName, setFName] = useState('');
    const [fPhone, setFPhone] = useState('');
    const [fAddress, setFAddress] = useState('');
    // date
    const [cDate, setCDate] = useState('');
    const [dDate, setDDate] = useState('');
    // Item 
    const [item, setItem] = useState([]);

    // invoiceNumber
    const [invoiceNumber, setInvoiceNumber] = useState('');

    // aditional Details
    const [aDetail, setADetail] = useState(''); 

    // terms
    const [terms, setTerms] = useState(null);
    // invoice Currency
    const [currencey, setCurrencey] = useState('');

    // discount
    const [discount, setDiscount] = useState('');
    const [discountType, setDiscountType] = useState('');

    // tax
    const [tax, setTax] = useState('');
    const [taxType, setTaxType] = useState('');

    // total
    const [subTotal, setSubTotal] =useState('');
    const [grandTotal, setGrandTotal] = useState('');
    const [shipment, setShipment] = useState('');

    // Social Accounts
    const[fb, setFb] = useState('');
    const[insta, setInsta] = useState('');
    const[twitter, setTwitter] = useState('');

    const getInvoiceType = async (apiKey, bToken, iType)=>{
        let linkhref = window.location.pathname.slice(10);
        const token = bToken
        const apiResult = await axios({
            method: 'post',
            url: `${apiKey}get_details_invoice`,
            data: {
                invoice_type: iType, invoice_number: linkhref 
            }, 
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        console.log(apiResult);
        if(apiResult.data.invoice !== null){
            let data = apiResult.data.invoice
            setInvoiceDesign(data.inv_design);
            setLogo(data.logo_image);
            setSrc(apiResult.data.src);
            setCName(data.person_to_name);
            setCPhone(data.person_to_num);
            setCAddress(data.person_to_address);
            setColor(apiResult.data.color);
            setFName(data.person_from_name);
            setFPhone(data.person_from_num);
            setFAddress(data.person_from_address);
            setInvoiceType(data.invoice_type);
            setCDate(data.invoice_current_date);
            setDDate(data.invoice_due_date);
            setInvoiceNumber(data.invoice_number);
            setItem(JSON.parse(data.product_name));
            setADetail(data.extra_note);
            setTerms(data.terms);
            setSubTotal(data.product_sub_total);
            setDiscount(data.product_discount);
            setDiscountType(data.discount_type);
            setCurrencey(data.invoice_currency);
            setTax(data.product_tax);
            setTaxType(data.tax_type);
            setGrandTotal(data.product_total);
            setShipment(data.product_shipment);
            setPDF('1');
            setLogoDataUrl(data.dataUrl);
        }
        const apiResult2 = await axios({
            method: 'get',
            url: `${apiKey}get_user_detail/${JSON.parse(localStorage.getItem("user_id"))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult2.data.success === true)
        {
            let data = apiResult2.data.user;
            setFb(data.fb_link);
            setInsta(data.insta_link);
            setTwitter(data.twitter_link);
        }
    }
    useEffect(()=>{
        getInvoiceType(props.apiKey, props.bToken, '1');
    }, [props.apiKey, props.bToken]);
    const savePDF = (logourl, inv_des) =>{
        // var imgData = src + logo;
        var doc = new jsPDF("p", "pt", "a4");
        // console.log(document.querySelector('#content'));
        doc.text(35,25, "PDF TEXT");
        // console.log("P IMAGE");
        console.log(logourl);
        // doc.addImage(logourl, 'JPEG', 15, 10, 50, 50);
        doc.html(document.querySelector('#content'), {
            callback: function(pdf){
                if(inv_des === '4'){
                    pdf.addImage(logourl, 'JPEG', 20, 10, 100, 100);
                }
                else if(inv_des === '5')
                {
                    pdf.addImage(logourl, 'JPEG', 450, 10, 100, 100);
                }
                else{
                    pdf.addImage(logourl, 'JPEG', 35, 35, 100, 100);
                }
                pdf.save("mypdf.pdf");
                setInterval(() => {
                    window.close();
                }, 700);
                
            }
        });
        // navigate("/create-invoice");
    }
    useEffect(()=>{
        if(pdf === '1'){
            savePDF(logoDataUrl, invoiceDesign)
        }
    }, [pdf,logoDataUrl,invoiceDesign, navigate])
  return (
    <>  
        {invoiceDesign === 'default'|| invoiceDesign === '0'?<> <div id="content"><Template0 invoiceData = {{ color: color, logo:src + logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter, dataUrl: logoDataUrl }} /> </div></>:'' }


        {invoiceDesign === '1' && <div id="content"><Template1 invoiceData = {{ color: color, logo:src + logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /></div> }


        {invoiceDesign === '2' && <div id="content"><Template2 invoiceData = {{ color: color, logo:src + logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /></div> }

        {invoiceDesign === '3' && <div id="content"><Template3 invoiceData = {{ color: color, logo:src + logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /></div> }


        {invoiceDesign === '4' && <div id="content"><Template4 invoiceData = {{ color: color, logo:src + logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /></div> }


        {invoiceDesign === '5' && <div id="content"><Template5 invoiceData = {{ color: color, logo:src + logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> </div> }

    </>
  )
}
