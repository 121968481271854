import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import {useNavigate} from "react-router-dom";

import axios from 'axios';

export default function CreateRequest(props) {
    props.ptitle("Create Request");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [image, setImage] = useState('');
    const navigate = useNavigate();
    // save Request 
    const saveRequest = async (e) => {
        e.preventDefault();
        if(image){
            const token = props.bToken; 
            const apiResult = await axios({
            method: 'post',
            url: `${props.apiKey}create_request_design`,
            data: {
                upload_image: image, email: JSON.parse(localStorage.getItem('user_email'))
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`,
                "Content-type": "multipart/form-data",
            }
            });
            if(apiResult.data.status === 200){
                toast.success("Request Generated Successfully", {
                    position: "bottom-right",
                    aposition: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                  });
                  localStorage.setItem('request_upload', JSON.stringify('200'));
                  navigate('/dashboard/all-request');
            }
        }
        else{
            toast.error("Please Upload an Image", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              });
        }
    }
  return (
    <>
        
      {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item active">Request Invoice Template Design</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Request Invoice Template Design</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Image/File</strong></label>
                      <input type="file" name="upload_image" className="form-control" onChange={(e)=>setImage(e.target.files[0])} />
                      <div id="uploaded_image"></div>
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary" onClick={saveRequest} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
