import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import DataTable from 'react-data-table-component';
export default function List(props) {
  props.ptitle("Admin List");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [search, setSearch] = useState('');
    const [admin, setAdmin] = useState([]);
    const [fiteredAdmin, setFiteredAdmin] = useState([]);
    const [status, setStatus] = useState([]);
    const [progress, setProgress] = useState(0);
    const [newAdmin, setNewAdmin] = useState('201');

    useEffect(()=>{
        if(newAdmin === '200'){
            toast.success("Admin Resgisterd Successfully", {
              position: "bottom-right",
              aposition: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark'
          });
          localStorage.setItem('admin_add_msg', JSON.stringify('201'));
        }
        else{
            setNewAdmin(JSON.parse(localStorage.getItem('admin_add_msg')));
        }
    }, [newAdmin]);
    // get all Data
    const getDataFunc = async (apiKey, bToken) => {
        setProgress(20);
        const token = bToken; 
        const apiResult = await axios({
        method: 'get',
        url: `${apiKey}fetch_admins`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
        });
        setFiteredAdmin(apiResult.data.source);
        setAdmin(apiResult.data.source);
        setProgress(100);
    }

    const column = [
        {
          name: "Sr",
          cell: (row, index) => index + 1,
        },
        {
          name: "Name",
          selector: (row)=>row.name,
          sortable: true
        },
        {
          name: "Email",
          selector: (row)=>row.email,
          sortable: true
        },
        {
          name: "Number",
          selector: (row)=>row.mobile_number,
          sortable: true
        },
        {
          name: "Address",
          selector: (row)=>row.address,
          sortable: true
        },
        {
            name: "Status",
            cell: row=><>
                {row.status === 'activeuser'?'Active':'InActive'}
            </>
        },
        {
          name: "Aciton",
          cell: (row, index)=> <>
                            <select className="form-control listing" value={status[index]} onChange={(e)=>adminStatus(e, index, row.id)}>
                              <option>--Select--</option>
                              <option value="inactive" >InActive</option>
                              <option value="activeuser">Active</option>
                            </select></>
        }
      ];
      useEffect(()=>{
        getDataFunc(props.apiKey, props.bToken);
      }, [props.apiKey, props.bToken]);
    
      useEffect(()=>{
        const result = admin.filter((admin)=>{
          return admin.name.toLowerCase().match(search.toLowerCase());
        });
        setFiteredAdmin(result);
      }, [search, admin]);
      const adminStatus = async(e, index, id) =>{
        let list = [...status];
        list[index] = e.target.value;
        setStatus(list);
        const token = props.bToken; 
        const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}update_user_status`,
        data:{
          id: id, status: e.target.value
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
        });
        setProgress(70);
        if(apiResult.data.status === 200){
          getDataFunc(props.apiKey, props.bToken);
          toast.success("Status Updated Successfully", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
        });
          setProgress(100);
        }
      }
  return (
    <>
        <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">All Admins</li>
          </ul>
        </div>
      </div>
      <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4>All Admins</h4>
                  </div>
                  <div>
                    <Link to="/dashboard/add-admin" type="button" className="btn btn-primary">Add Admin</Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable 
                  columns={column} 
                  data={fiteredAdmin} 
                  pagination
                  fixedHeader
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                  }
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
