import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function Color(props) {
    const [customColorBox, setCustomColorBox] = useState('');
    const [customColor, setCustomColor] = useState('#000000');
    const[colorPalete, setColorPalete] = useState(['#0188cc','#db0b0b', '#f26f21', '#15c6a3', '#10346b', 'black', 'red']);
    const [masterColor, setMasterColor] = useState("");
    const [progress, setProgress] = useState(0);
    const getUserData = async (apiKey, bToken) =>{
        setProgress(40);
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_user_detail/${JSON.parse(localStorage.getItem('user_id'))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          if(apiResult.data.success === true){
            setMasterColor(apiResult.data.user.master_color);
            setProgress(100);
          }
    }
    useEffect(()=>{
        getUserData(props.apiKey, props.bToken);
    }, [props.apiKey, props.bToken]);


    const handleCustomColorBox = ()=>{
        if(!customColorBox){
            setCustomColorBox("addColor");
        }
        else{
            setCustomColorBox("");
        }
    }
    const addColor = (masterColor, colorPalete)=>{
        if(masterColor !== ""){
            let result = [];
            result = colorPalete.filter(element => element === masterColor)
            if(result.length === 0){
                setColorPalete([...colorPalete, masterColor]);
            }
        }
    }
    useEffect(()=>{
        addColor(masterColor, colorPalete);
    }, [masterColor, colorPalete]);
    const hanldeSaveColor = async (e) =>{
        setProgress(40);
        e.preventDefault();
        const token = props.bToken;
        const apiResult = await axios({
            method: 'post',
            url: `${props.apiKey}update_user_detail`,
            data: {
                master_color: customColor, id: JSON.parse(localStorage.getItem("user_id"))
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          if(apiResult.data.status === 200){
            getUserData(props.apiKey, props.bToken);
            addColor(masterColor, colorPalete);
            setCustomColorBox("");
            setProgress(100);
            toast.success("Color Updated Successfully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              });
          }
    }
    const handleColor = async(cCode) =>{
        setProgress(40);
        const token = props.bToken;
        const apiResult = await axios({
            method: 'post',
            url: `${props.apiKey}update_user_detail`,
            data: {
                master_color: cCode, id: JSON.parse(localStorage.getItem("user_id"))
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          if(apiResult.data.status === 200){
            getUserData(props.apiKey, props.bToken);
            addColor(masterColor, colorPalete);
            setProgress(100);
            toast.success("Color Updated Successfully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              });
          }
    }
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
        <div className="form-group mb-4">
            <label className="col-md-12 p-0"><b>Master Color</b></label>
            <div className="d-flex">
                {
                    colorPalete.map((color, index)=>{
                        return <div key={index} className="bg-widths" style={{backgroundColor: color, cursor: 'pointer', border: '1px solid #f2f2f2'}} onClick={()=>handleColor(color)}>
                                {
                                    masterColor === color?
                                    <i className="fa fa-check-circle" style={{color: 'white'}}></i>:""
                                }
                            </div>
                        
                    })
                }
                <div>
                    <button type="button" className="btn btn-light" style={{marginLeft: '10px'}} onClick={handleCustomColorBox}>Custom</button>
                </div>
            </div>
            {customColorBox && <div className="col-sm-12 border-bottom p-0 mt-4" id="myDIV" >
                <input type="color" name="master_color" className="form-control p-0 border-0" value={customColor} placeholder="Enter Code" onChange={(e)=>setCustomColor(e.target.value)} />
                <input type="submit" value="Save" className="btn btn-primary" onClick={hanldeSaveColor} />
            </div>
            }
        </div>
        <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
