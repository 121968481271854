import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LoadingBar from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import {Link} from "react-router-dom";

export default function AllRequest(props) {
    props.ptitle("All Request");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [newrequest, setNewrequest] = useState('201');
    const [search, setSearch] = useState('');
    const [request, setrequest] = useState([]);
    const [progress, setProgress] = useState(0);
    const [fiteredrequest, setFiteredrequest] = useState([]);
    useEffect(()=>{
        if(newrequest === '200'){
            toast.success("Request Resgisterd Successfully", {
              position: "bottom-right",
              aposition: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark'
          });
          localStorage.setItem('request_upload', JSON.stringify('201'));
        }
        else{
          setNewrequest(JSON.parse(localStorage.getItem('request_upload')));
        }
      }, [newrequest]);

    const getDataFunc = async (apiKey, bToken) => {
        setProgress(20);
        const token = bToken; 
        const apiResult = await axios({
          method: 'get',
          url: `${apiKey}fetch_template_requests/${localStorage.getItem('user_id')}`,
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`
          }
        });
        setFiteredrequest(apiResult.data.source);
        setrequest(apiResult.data.source);
        setProgress(100);
      }
    const column = [
        {
          name: "Sr",
          cell: (row, index) => index + 1,
        },
        {
          name: "File",
          selector: (row)=>row.file,
          sortable: true
        },
        {
          name: "Status",
          selector: (row)=>row.status,
          sortable: true
        },
        {
            name: "Reason",
            selector: (row)=>row.reason,
            sortable: true
          }
      ]

      useEffect(()=>{
        getDataFunc(props.apiKey, props.bToken);
      }, [props.apiKey, props.bToken]);

      useEffect(()=>{
        const result = request.filter((request)=>{
          return request.file.toLowerCase().match(search.toLowerCase());
        });
        setFiteredrequest(result);
      }, [search, request]);    
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
<div className="breadcrumb-holder">
      <div className="container-fluid">
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><a href="index.html">Home</a></li>
          <li className="breadcrumb-item active">All Requests</li>
        </ul>
      </div>
    </div>
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4>All Requests</h4>
                  </div>
                  <div>
                    <Link to="/dashboard/create-request" type="button" className="btn btn-primary">Create Request</Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                <DataTable 
                  columns={column} 
                  data={fiteredrequest} 
                  pagination
                  fixedHeader
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                  }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
