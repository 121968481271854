import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import profleImage from '../../backend/backend/images/download.png';
export default function SideNav(props) {
    const [role, setRole] = useState('user');
    const [color, setColor] = useState('');
    const [username, setUsername] = useState(' ');
    const navigate = useNavigate();
    const activeClass = (event) => {
        if(event.target.href === window.location.href){
            event.target.className = "active";
        }
    }
    const setActive = () =>{
        if(window.location.pathname.slice(0, 10) === '/dashboard'){
            document.getElementById('user-home').className = "active";
        }
    }
    useEffect(()=>{
        setActive();
    }, [])
    useEffect(()=>{
        if(role === 'user')
        {
            setRole( JSON.parse(localStorage.getItem('role')));
            setColor('#5096DA');
        }
    }, [role])
    const userProfile = async (apiKey, bToken, id) =>{
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_user_detail/${id}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult.data.success === true){
            setUsername(apiResult.data.user.name);
            localStorage.setItem("user_logo", JSON.stringify(apiResult.data.user.logo))
        }
    }
    useEffect(()=>{
        userProfile(props.apiKey, props.bToken, JSON.parse(localStorage.getItem('user_id')));
    }, [props]);
    const handleLogout = async() =>{
        const token = props.bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${props.apiKey}logout`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          console.log(apiResult);
          if(apiResult.status === 200){
            localStorage.setItem('session_status', JSON.stringify(201));
            navigate("/");
          }
    }
  return (
    <>
    <Helmet>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossOrigin="anonymous"></link>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700"></link>
        <link rel="stylesheet" href="/backend/vendor/font-awesome/css/font-awesome.min.css" />
        <link rel="stylesheet" href="/backend/css/fontastic.css" />
        <link rel="stylesheet" href="/backend/css/grasp_mobile_progress_circle-1.0.0.min.css" />
        <link rel="stylesheet" href="/backend/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css" />
        <link rel="stylesheet" href="/css/croppie.css" />
        <link rel="stylesheet" href="/backend/backend/css/theme.css" />
        <link rel="stylesheet" href="/backend/css/style.default.css" />
        <link rel="apple-touch-icon" href={props.favicon} />
        <link rel="icon" href={props.favicon} />
        <style type="text/css">
        {`
            .switch {
                position: relative;
                display: inline-block;
                width: 49px;
                  height: 24px;
              }
              
              .switch input { 
                opacity: 0;
                width: 0;
                height: 0;
              }
              
              .sliderCheck {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: .4s;
                transition: .4s;
              }
              
              .sliderCheck:before {
                position: absolute;
                content: "";
                height: 15px;
                width: 15px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }
              
              input:checked + .sliderCheck {
                background-color: #2196F3;
              }
              
              input:focus + .sliderCheck {
                box-shadow: 0 0 1px #2196F3;
              }
              
              input:checked + .sliderCheck:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
              }
              
              /* Rounded sliderChecks */
              .sliderCheck.round {
                border-radius: 34px;
              }
              
              .sliderCheck.round:before {
                border-radius: 50%;
              }
              .logoutStyle{
                width: 100%;
                padding: 10px;
                cursor: pointer;
              }
              .logoutStyle:hover{
                background: rgb(53, 122, 188);
              }
        `}
        </style>
    </Helmet>
         <nav className="side-navbar" style={{backgroundColor: color}}>
        <div className="side-navbar-wrapper">
            {/* <!-- Sidebar Header    --> */}
            <div className="sidenav-header d-flex align-items-center justify-content-center">
            {/* <!-- User Info--> */}
            <div className="sidenav-header-inner text-center"><img src={profleImage} alt="person" className="img-fluid rounded-circle" />
                <h2 className="h5">{username}</h2>
                <Link to="/" style={{fontSize: '13px'}}>Visit Site</Link>
            </div>
            {/* <!-- Small Brand information, appears on minimized sidebar--> */}
            <div className="sidenav-header-logo"><a href="<?php echo base_url('Dashboard'); ?>" className="brand-small text-center"> <strong style={{color:'#fcca03 !important'}}>I</strong><strong className="text-primary" style={{color: '#1a90c6 !important'}}>D</strong></a></div>
            </div>
            {/* <!-- Sidebar Navigation Menus--> */}
            <div className="main-menu">
            <h5 className="sidenav-heading">Main</h5>
                {/* <?php if ($this->session->userdata('type') == "user") { ?>                   */}
                {JSON.parse(localStorage.getItem('role')) === 'user'?

                <>
                <ul id="side-main-menu" className="side-menu list-unstyled">
                <li>
                <Link to={'/dashboard'} id="user-home" onClick={activeClass}>
                    <i className="icon-home"></i>Home
                </Link>
                </li>
                <li style={{background:'red'}}>
                <Link to="/create-invoice">
                    <i className="fa fa-file-text" aria-hidden="true"></i>Create Invoice
                </Link>
                </li>
                <li>
                <Link to="/dashboard/invoice">
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>Invoices
                </Link>
                </li>
                <li>
                <Link to="/dashboard/quotation">
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>Quotations
                </Link>
                </li>
                <li>
                <Link to="/dashboard/estimate">
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>Estimate
                </Link>
                </li>
                <li>
                <a href="#developersDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-users" aria-hidden="true"></i>Clients 
                </a>
                <ul id="developersDropdown" className="collapse list-unstyled ">
                    <li><Link to="/dashboard/add-client"><i className="fa fa-plus" aria-hidden="true"></i>Add New Client</Link></li>
                    <li><Link to="/dashboard/client-list">All Clients</Link></li>
                </ul>
                </li>
                <li>
                <a href="#ItemsDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-sitemap" aria-hidden="true"></i>Items 
                </a>
                <ul id="ItemsDropdown" className="collapse list-unstyled ">
                    <li><Link to="/dashboard/add-item"><i className="fa fa-plus" aria-hidden="true"></i>Add New Item</Link></li>
                    <li><Link to="/dashboard/item-list">All Items</Link></li>
                </ul>
                </li>
                <li>
                <a href="#paymentDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-money" aria-hidden="true"></i>Payments 
                </a>
                <ul id="paymentDropdown" className="collapse list-unstyled ">
                    <li><Link to="/dashboard/bank-account"><i className="fa fa-university" aria-hidden="true"></i>Bank Account</Link></li>
                    <li><Link to="/dashboard/paypal-account"><i className="fa fa-paypal" aria-hidden="true"></i>Paypal Account</Link></li>
                    <li><Link to="/dashboard/payoneer-account"><i className="fa fa-yoast" aria-hidden="true"></i>Payoneer Account</Link></li>
                    <li><Link to="/dashboard/wise-account"><i className="fa fa-vk" aria-hidden="true"></i>Wise Account</Link></li>
                    <li><Link to="/dashboard/skrill-account"><i className="fa fa-scribd" aria-hidden="true"></i>Skrill Account</Link></li>
                </ul>
                </li>
                <li>
                <a href="#settingsDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-cog" aria-hidden="true"></i>Settings 
                </a>
                <ul id="settingsDropdown" className="collapse list-unstyled ">
                    <li><Link to="/dashboard/account-setting"><i className="fa fa-cog" aria-hidden="true"></i>Account Settings</Link></li>
                    <li><Link to="/dashboard/invoice-template"><i className="fa fa-object-group" aria-hidden="true"></i>Invoice Templates</Link></li>
                </ul>
                </li>
                <li>
                <a href="#requestDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>Request Custom Design 
                </a>
                <ul id="requestDropdown" className="collapse list-unstyled ">
                    <li>
                    <Link to="/dashboard/create-request"><i className="fa fa-file-text-o" aria-hidden="true"></i>Create Request</Link>
                    </li>
                    <li>
                    <Link to="/dashboard/all-request"><i className="fa fa-file-text-o" aria-hidden="true"></i>All Requests</Link>
                    </li>
                </ul>
                </li>
                <li>
                <span className='logoutStyle' onClick={handleLogout}> 
                    <i className="fa fa-sign-out"></i>Logout
                </span>
                </li>
                </ul>
                </>
                
                :
                <>
                <ul id="side-main-menu" className="side-menu list-unstyled">
                <li>
                    <Link to="dashboard" id="user-home">
                    <i className="icon-home"></i>Home
                    </Link>
                </li>
                <li>
                    <Link to="/dashboard/user-list">
                    <i className="fa fa-users"></i>Users
                    </Link>
                </li>
                <li>
                    <a href="#adminDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-user" aria-hidden="true"></i>Admins 
                    </a>
                    <ul id="adminDropdown" className="collapse list-unstyled ">
                    <li><Link to="dashboard/add-admin">Add Admin</Link></li>
                    <li><Link to="dashboard/admin-list">All Admin</Link></li>
                    </ul>
                </li>
                <li>
                    <a href="#blogDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-th-large"></i>Blogs 
                    </a>
                    <ul id="blogDropdown" className="collapse list-unstyled ">
                    <li><Link to="/dashboard/add-blog">Add Blog</Link></li>
                    <li><Link to="/dashboard/blog-list">All Blogs</Link></li>
                    </ul>
                </li>
                <li>
                    <a href="#templateblogDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-th-large"></i>Template Blogs 
                    </a>
                    <ul id="templateblogDropdown" className="collapse list-unstyled ">
                    <li><Link to="/dashboard/add-template-blog">Add Template Blog</Link></li>
                    <li><Link to="/dashboard/template-blog-list">All Template Blogs</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/dashboard/all-invoice">
                    <i className="fa fa-file-text"></i>Invoices
                    </Link>
                </li>
                <li>
                    <Link to="/dashboard/all-request-templates">
                    <i className="fa fa-file-text-o"></i>Templates Requests
                    </Link>
                </li>
                <li>
                    <a href="#uploadDropdown" aria-expanded="false" data-toggle="collapse">
                    <i className="fa fa-file-text-o"></i>Uploaded Templates 
                    </a>
                    <ul id="uploadDropdown" className="collapse list-unstyled ">
                    <li><a href="<?php echo base_url('AllUploadedRequests'); ?>">Uploaded Templates</a></li>
                    <li><a href="<?php echo base_url('createTemplate'); ?>">Upload New Template</a></li>
                    </ul>
                </li>
                <li>
                    <Link to="/dashboard/site-page-setting"> <i className="icon-screen"> </i>Site Pages Settings
                    </Link>
                </li>
                <li>
                    <Link to="/dashboard/web-master-tools"> <i className="fa fa-venus-mars"> </i>Web Master Tool 
                    </Link>
                </li>
                <li>
                    <Link to="/dashboard/contact-settings"> <i className="icon-screen"> </i>Contact Settings
                    </Link>
                </li>
                    <li>
                    <Link to="/dashboard/account-setting">
                        <i className="fa fa-cog" aria-hidden="true"></i>Settings
                    </Link>
                    </li>
                <li>
                <span className='logoutStyle' onClick={handleLogout}> 
                    <i className="fa fa-sign-out"></i>Logout
                </span>
                </li>
                </ul>
                </>
                }
            </div>
        </div>
        </nav>
    </>
  )
}
