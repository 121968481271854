import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import DataTable from 'react-data-table-component';
export default function AdminInvoices(props) {
  props.ptitle("All Invoices");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const [search, setSearch] = useState('');
  const [progress, setProgress] = useState(0);
  const [invoice, setInvoice] = useState([]);
  const [fiteredInvoice, setFiteredInvoice] = useState([]);
  // get all Data
  const getDataFunc = async (apiKey, bToken) => {
    setProgress(20);
    const token = bToken; 
    const apiResult = await axios({
      method: 'get',
      url: `${apiKey}fetch_invoices_all`,
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    setProgress(100);
    setFiteredInvoice(apiResult.data.source);
    setInvoice(apiResult.data.source);
  }

  const column = [
    {
      name: "Sr",
      width: '80px',
      cell: (row, index) => index + 1,
    },
    {
      name: "Invoice Number",
      width: '200px',
      selector: (row)=>row.invoice_number,
      sortable: true
    },
    {
      name: "Invoice Type",
      width: '200px',
      selector: (row)=>row.invoice_type,
      sortable: true
    },
    {
      name: "Invoice To",
      width: '400px',
      selector: (row)=>row.person_to_name,
      sortable: true
    },
    {
      name: "Date",
      selector: (row)=>row.invoice_current_date,
      width: '150px',
      sortable: true
    },
    {
      name: "Price",
      cell: row=> <><b>{row.invoice_currency+" "+row.product_total}</b></>
    }
  ]


  useEffect(()=>{
    getDataFunc(props.apiKey, props.bToken)
  }, [props.apiKey, props.bToken]);
  useEffect(()=>{
    const result = invoice.filter((invoice)=>{
      return invoice.invoice_number.toLowerCase().match(search.toLowerCase());
    });
    setFiteredInvoice(result);
  }, [search, invoice]);
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">All Invoices</li>
          </ul>
        </div>
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <DataTable 
                    columns={column} 
                    data={fiteredInvoice} 
                    pagination
                    fixedHeader
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                      <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                    }
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
