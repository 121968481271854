import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Dashboard(props) {
    props.ptitle("Dashboard");
    const [client, setClient] = useState('0');
    const [item, setItem] = useState('0');
    const [progress, setProgress] = useState(0);
    const [totalUser, setTotalUser] = useState('');
    const [activeUser, setActiveUser] = useState('');
    const [inactiveUser, setInactiveUser] = useState('');
    const [login, setLogin] = useState('201');
    const navigate = useNavigate();
    useEffect(()=>{
        if(login === '200'){
            toast.success("Login to Dashboard Successfully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 800,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
            localStorage.setItem('login_msg', '201');
        }
        else{
            setLogin(localStorage.getItem('login_msg'));
        }
    }, [login]);
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    // user_email
    const dashboard_stat = async (apiKey, bToken, email) =>{
        const token = bToken;
        setProgress(20); 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}dashboard_stats/${email}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult.data.success === true){
            setClient(apiResult.data.clients);
            setItem(apiResult.data.items);
        }
        setProgress(100);
    }
    useEffect(()=>{
        dashboard_stat(props.apiKey, props.bToken, JSON.parse(localStorage.getItem('user_email')));
    }, [props]);

    // fetch users
    const allUsers = async (apiKey, bToken) =>{
        const token = bToken;
        // setProgress(20); 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}admin_list`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult.data){
            let matches = []
            matches = apiResult.data.filter(user=>{
                const regex = new RegExp(`user`, "gi");
                return user.role.match(regex);
            });
            setTotalUser(matches);
            let inactive = [];
            inactive = matches.filter(user=>{
                const regex = new RegExp(`inactive`, "gi");
                return user.status.match(regex);
            });
            setInactiveUser(inactive);
            let active = [];
            active = matches.filter(user=>{
                const regex = new RegExp(`activeuser`, "gi");
                return user.status.match(regex);
            });
            setActiveUser(active);
            
        }
        // setProgress(100);
    }
    useEffect(()=>{
        allUsers(props.apiKey, props.bToken);
    }, [props]);

    const handleLogout = async() =>{
        const token = props.bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${props.apiKey}logout`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          if(apiResult.status === 200){
            localStorage.setItem('session_status', JSON.stringify(201));
            navigate("/");
          }
    }
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />

        {JSON.parse(localStorage.getItem('role')) === 'user'?
            <>
                {/* Simple User */}
                <section className="dashboard-counts section-padding">
                    <div className="container-fluid">
                        <div className="row">
                        {/* <!-- Count item widget--> */}
                        <div className="col-xl-6 col-md-6 col-6">
                            <div className="wrapper count-title d-flex">
                            <div className="icon"><i className="icon-padnote"></i></div>
                            <div className="name"><strong className="text-uppercase">Clients</strong>
                                <div className="count-number">{client}</div>
                            </div>
                            </div>
                        </div>
                        {/* <!-- Count item widget--> */}
                        <div className="col-xl-6 col-md-6 col-6">
                            <div className="wrapper count-title d-flex">
                            <div className="icon"><i className="icon-padnote"></i></div>
                            <div className="name"><strong className="text-uppercase">Items</strong>
                                <div className="count-number">{item}</div>
                            </div>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </section>

                <section className="statistics">
                    <div className="container-fluid">
                        <div className="row d-flex">
                        <div className="col-lg-6">
                            {/* <!-- Income--> */}
                            
                            <div className="card income text-center">
                            <div className="icon"><i className="fa fa-sign-out" aria-hidden="true"></i></div>
                            <span onClick={handleLogout} style={{cursor: 'pointer'}}><strong className="text-primary">Logout</strong></span>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            {/* <!-- Income--> */}
                            <div className="card income text-center">
                            <div className="icon"><i className="fa fa-cog" aria-hidden="true"></i></div>
                            <Link to="/dashboard/account-setting"><strong className="text-primary">Settings</strong></Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </>
            :
            <>
            <section className="statistics m-2">
                <div className="container-fluid">
                    <div className="row d-flex">
                    <div className="col-lg-4">
                        {/* <!-- Income--> */}
                        
                        <div className="card income text-center">
                        <div className="icon">{totalUser.length}</div>
                        <a href="/"><strong className="text-primary">Total Users</strong></a>
                        </div>
 
                    </div>
                    <div className="col-lg-4">
                        {/* <!-- Income--> */}
                        <div className="card income text-center">
                        <div className="icon">{activeUser.length}</div>
                        <a href="/"><strong className="text-primary">Active Users</strong></a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        {/* <!-- Income--> */}
                        <div className="card income text-center">
                        <div className="icon">{inactiveUser.length}</div>
                        <a href="/"><strong className="text-primary">Inactive Users</strong></a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="statistics">
                    <div className="container-fluid">
                        <div className="row d-flex">
                        <div className="col-lg-6">
                            {/* <!-- Income--> */}
                            
                            <div className="card income text-center">
                            <div className="icon"><i className="fa fa-sign-out" aria-hidden="true"></i></div>
                            <span onClick={handleLogout} style={{cursor: 'pointer'}}><strong className="text-primary">Logout</strong></span>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            {/* <!-- Income--> */}
                            <div className="card income text-center">
                            <div className="icon"><i className="fa fa-cog" aria-hidden="true"></i></div>
                            <Link to="/dashboard/account-setting"><strong className="text-primary">Settings</strong></Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </>
        }
        <ToastContainer position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover />
    </>
  )
}
