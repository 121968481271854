import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Response(props) {
    const [progress, setProgress] = useState(0);
    const [email, setEmail] = useState('');
    const [file, setFile] = useState('');
    const [type, setType] = useState('');
    const [src, setSrc] = useState('');
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');
    props.ptitle("Request Reponse");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const getDataFunc = async (apiKey, bToken) => {
    setProgress(20);
    let idLink = window.location.pathname.slice(25);
    const token = bToken; 
    const apiResult = await axios({
      method: 'get',
      url: `${apiKey}fetch_single_request/${idLink}`,
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    if(apiResult.data.success === true){
      setEmail(apiResult.data.source.email_fk);
      setFile(apiResult.data.source.file);
      setType(apiResult.data.source.file.slice(-3));
      setStatus(apiResult.data.source.status);
      setSrc(apiResult.data.src);
      setProgress(100);
    }
  }
  useEffect(()=>{
    getDataFunc(props.apiKey, props.bToken);
  }, [props.apiKey, props.bToken]);


  const saveChanges = async (e) =>{
    e.preventDefault();
    setProgress(20);
    let idLink = window.location.pathname.slice(25);
    const token = props.bToken; 
    const apiResult = await axios({
      method: 'post',
      url: `${props.apiKey}update_request_detail`,
      data: {
        id: idLink, status, reason
      },
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    if(apiResult.data.success === true){
      toast.success("Status Updated Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
      setProgress(100);
    }
    
  }
  return (
    <>
        <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item active">Request</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Request</h4>
                </div>
                <div className="card-body">
                  {/* <?php foreach ($post as $posts) {
                  $ext    = pathinfo($posts->file, PATHINFO_EXTENSION); ?> */}
                  <form>
                    <div className="form-group">
                      <label><strong>User Email</strong></label>
                      <input type="text" value={email} disabled className="form-control"  />
                    </div>
                    {type === "png" || type === "PNG" || type === "jpg" || type === "JPG" || type === "JPEG" || type === "jpeg"? <div className="form-group">
                        <label><strong>Image</strong></label>
                        <img src={src+file} className="ml-5" alt='Logo' style={{width: '300px', height: '400px', objectFit: 'contain'}} /><a href={src+file} download={file} target="_blank" id="download" className="ml-5"  rel="noreferrer"><i className="fa fa-download" aria-hidden="true"></i></a>
                      </div>:<>
                      <div className="form-group">
                        <label><strong>File</strong></label>
                        <object data={src+type} type="application/pdf" width="100px" height="100px">
                            <iframe title='one' src={'https://docs.google.com/viewer?url='+src+type+'&embedded=true'} width="100px" height="100px"></iframe>
                        </object>
                        <p>some text<a id="download" className="ml-5" href={src+file} download={file}><i className="fa fa-download" aria-hidden="true"></i></a></p>
                      </div>
                      </>}
                    <div className="form-group mb-4">
                      <label><strong>Request Status</strong></label>

                        <select className="form-control" id="designTemp" name="status" value={status} onChange={(e)=>setStatus(e.target.value)}>

                          <option value='pending'>-- Select --</option>

                          <option value="cancel">Cancel</option>

                          <option value="approved">Approved</option>

                        </select>

                    </div>
                    {(status === 'cancel' || status === 'approved') && 
                    <div>
                      <div className="form-group">
                        <label><strong>Reason</strong></label>
                        <textarea name="cancel_reason" value={reason} className="form-control" onChange={(e)=>setReason(e.target.value)}>{reason}</textarea>
                      </div>
                    </div>
                      }
                    <div className="form-group">       
                      <input type="submit" value="Update" className="btn btn-primary" onClick={saveChanges} />
                    </div>
                  </form>
                  {/* <?php } ?> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover />
    </>
  )
}
