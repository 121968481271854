import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Add(props) {
    props.ptitle("Add Admin");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [num, setNum] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const navigate = useNavigate();

    const saveAdmin = async (e) =>{
      e.preventDefault();
      const token = props.bToken;
      if(cPassword === password){
        const apiResult = await axios({
          method: 'post',
          url: `${props.apiKey}create_admin`,
          data: {
             name, email, password, mobile_number: num, address
          },
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`
          }
        });
        if(apiResult.data.status === 200){
          localStorage.setItem('admin_add_msg', JSON.stringify('200'));
          navigate('/dashboard/admin-list');
        }
        else{
          toast.error("Email Already Existed!", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
        });
        }
        
      }
    }
    const checkpass = (e) =>{
      setCPassword(e.target.value);
    }
  return (
    <>
    
    {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Add Admin</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Add Admin</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Full Name</strong></label>
                      <input type="text" placeholder="Full Name" name="username" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" required />
                    </div>
                    <div className="form-group">
                      <label><strong>Email</strong></label>
                      <input type="email" id="login-Email" placeholder="Email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" required />
                      <div id="email_response" ></div>
                    </div>
                    <div className="form-group">
                      <label><strong>Mobile Number</strong></label>
                      <input type="text" placeholder="Mobile Number" name="number" value={num} onChange={(e)=>setNum(e.target.value)} className="form-control" required />
                    </div>
                    <div className="form-group">
                      <label><strong>Address</strong></label>
                      <input type="address" placeholder="Address" name="address" className="form-control" value={address} onChange={(e)=>setAddress(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <label><strong>Password</strong></label>
                      <input type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} name="password" className="form-control checkPassword" required />
                    </div>
                    <div className="form-group">
                      <label><strong>Confirm Password</strong></label>
                      <input type="password" placeholder="Re-Enter Password" name="confirm_password" className="form-control confirmPassword" value={cPassword} onChange={(e)=>checkpass(e)} required />
                      <div id="password_response" ></div>
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary signUpButn" onClick={saveAdmin} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
