import React from 'react'

export default function Template5(props) {
  return (
    <>
        <section style={{pageBreakAfter: 'always', marginLeft: '0px', width: '580px', height: 'auto'}}>
            <div style={{width: '93%', marginLeft: '18px'}} >
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="row">
                            <div className="col-6 col-lg-6 col-md-6">
                                <div className="triangle-down ml-5" style={{borderTop: '150px solid'+props.invoiceData.color }}>
                                    
                                </div>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6">
                                <div className="pt-4 text-right mr-5">
                                    {/* <img src={props.invoiceData.logo} className="logoImgInvoice2 ml-5" alt="logo" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 col-lg-4 col-md-6">
                                <div className="pt-2">
                                    <h1 className="textHeading5 ml-5" style={{color: props.invoiceData.color, fontSize: '25px' }}>{props.invoiceData.invoiceType}</h1>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>{props.invoiceData.invoiceType} No: {props.invoiceData.invoiceNumber}</h6>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>Date: {props.invoiceData.cDate}</h6>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>Due Date: {props.invoiceData.dDate}</h6>
                                </div>
                            </div>
                            <div className="col-8 col-lg-8 col-md-6">
                                <div className="row pt-2">
                                    <div className="col-6 col-lg-6">
                                        <h5 className="infoInvoice2 pt-0 ml-5" style={{color: props.invoiceData.color, fontSize: '25px', }}>{props.invoiceData.invoiceType} To:</h5>
                                        <h5 className="invoiceToHeading3 ml-5" style={{fontSize: '12px'}}>{props.invoiceData.cName}</h5>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>{props.invoiceData.cAddress}</h6>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>Phone: {props.invoiceData.cPhone}</h6>
                                    </div>
                                    <div className="col-6 col-lg-6">
                                        <h5 className="infoInvoice2 pt-0 ml-5" style={{color: props.invoiceData.color, fontSize: '25px' }}>From:</h5>
                                        <h5 className="invoiceToHeading3 ml-5" style={{fontSize: '12px'}}>{props.invoiceData.fName}</h5>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>{props.invoiceData.fAddress}</h6>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>Phone: {props.invoiceData.fPhone}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <div className="pt-2">
                            <table className="table table-responsive tableRes6 pt-5">
                                <thead className="tableHeadingBg" style={{background: props.invoiceData.color, fontSize: '12px', padding: '5px'}}>
                                    <tr>
                                        <th style={{textAlign: 'left', fontSize: '12px', padding: '5px'}}>Item</th>
                                        <th style={{textAlign: 'left', fontSize: '12px', padding: '5px'}}>Item Description</th>
                                        <th style={{fontSize: '12px', padding: '5px'}}>Qty</th>
                                        <th style={{textAlign: 'right', fontSize: '12px', padding: '5px'}}>Price</th>
                                        <th style={{textAlign: 'right', fontSize: '12px', padding: '5px'}}>Total</th>
                                    </tr>
                                </thead>
                                <tbody className="tbodyMarg5" style={{fontSize: '12px', padding: '5px'}}>
                                    {/* <?php foreach ($invoiceL as $inv) { ?> */}
                                    
                                    {/* <?php } ?> */}
                                    {props.invoiceData.item.map((element)=>{
                                        return <tr>
                                                <td style={{textAlign: 'left', fontWeight: '600', fontSize: '12px', padding: '5px'}}>{element.pName}</td>
                                                <td style={{textAlign: 'left', fontSize: '12px', padding: '5px'}}>{element.pDescription}</td>
                                                <td style={{fontSize: '12px', padding: '5px'}}>{element.pQty}</td>
                                                <td style={{textAlign: 'right', fontSize: '12px', padding: '5px'}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                <td style={{textAlign: 'right', fontSize: '12px', padding: '5px'}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-lg-6 col-md-5">
                        <div className="ml-5 mt-2">
                            {props.invoiceData.aDetail && <div><h5 className="terms4Invoice" style={{fontSize: '14px'}}>Additional Details</h5>
                            <p className="terms4Invoicepara" style={{width: '80%', fontSize: '14px'}}>{props.invoiceData.aDetail}</p></div>}
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-7">
                        <div id='effect3d' style={{background: props.invoiceData.color}}>
                            
                        </div>
                        <div className="totals">
                            <div className="row pt-4">
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>SubTotal:</p>
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
                                </div>
                            </div>
                            {props.invoiceData.discount && <div className="row">
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>Discount:</p>
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                    
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>
                                    {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                    </p>
                                    
                                </div>
                            </div>}
                            
                            {props.invoiceData.tax && <div className="row">
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>Tax:</p>
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                    
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>
                                    {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                    </p>
                                    
                                </div>
                            </div>}
                            {props.invoiceData.shipment && <div className="row">
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>Shipping:</p>
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                
                                    <p className="text-right priceSub6" style={{fontSize: '14px'}}>{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                    
                                </div>
                            </div>}
                            <div className="invoiceFooter3 pt-2">
                                <div className="row">
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceBalance6 mb-2" style={{fontSize: '14px'}}><b>BalanceDue :</b></p>
                                    </div>
                                    <div className="col-6 col-lg-6 col-md-6">
                                        <p className="text-right priceBalance6 mb-2" style={{fontSize: '14px'}}><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-lg-12" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <div className="bgTermsInvoice6 mt-2" style={{background: props.invoiceData.color}}>
                            <div className="px-5 py-3">
                                <div className="row">
                                    {props.invoiceData.terms && <div className="col-6 col-lg-6">
                                        <h5 className="termsInvoice6" style={{fontSize: '14px'}}>Terms & Conditions</h5>
                                        <p className="termsInvoicePara6" style={{fontSize: '14px'}}>{props.invoiceData.terms}</p>
                                    </div>}
                                    <div className="col-6 col-lg-6">
                                        
                                    </div>
                                </div>						
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 col-lg-4 col-md-4">
                    </div>
                    <div className="col-4 col-lg-4 col-md-4">
                        <p className="infoInvoice2 iconsSocial2 pt-3 text-center">
                            {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook"></i>
                            </a>}
                            {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter-square ml-2"></i>
                            </a>}
                            {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                            </a>}
                        </p>
                    </div>
                    <div className="col-4 col-lg-4 col-md-4">
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
