import React from 'react'

export default function Template3(props) {
  return (
    <>
        <section>
            {/* <?php if (!empty($posts->cover_letter)) { ?> */}
            {/* <div className="container resContainer mb-5" style={{pageBreakAfter: 'always', width: '100%', height: '100%'}}>
                <div className="row">
                    <div className="col-lg-2 col-md-2" style={{paddingLeft: '0px'}}>
                        <div className="invoiceleft1HalfBg4" style={{height: '60%', paddingTop: '0px'}}>
                            <div className="invoiceHeading4">
                                <h1 className="text-uppercase" style={{left: '-133px', top: '139px', position: 'relative'}}>letter</h1>
                            </div>
                        </div>
                        <div className="bgInvoice3Left" style={{backgroundColor: 'red', height: '40%'}}>
                            <h5 style={{top: '112px'}}>Thank You Very Much</h5>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="pt-5">
                                    <h5 className="invoice4Heading5 pt-4">From Name</h5>
                                    <h6 className="dateIssue addressInvoice4">From Address</h6>
                                    <h6 className="dateIssue addressInvoice4">Phone: From Phone</h6>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <?php if (!empty($posts->logo_image)) { ?>
                                <div className="pt-5 text-right">
                                    <img src="<?php echo base_url('uploads/'.$posts->logo_image); ?>" className="logoImgInvoice2" alt="logo" />
                                </div>
                                 <?php } ?> 
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 pt-3">
                                        <h5 className="invoice4DateHeading4">Date</h5>
                                    </div>
                                    <div className="col-lg-6 col-md-6 pt-3">
                                        <h5 className="text-right invoice4DateHeading5">Current Date</h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-lg-12">
                                <div>
                                    Cover Letter
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1" style={{paddingRight: '0px'}}>
                        <div className="bgInvoice4Right1" style={{backgroundColor: 'red'}}></div>
                        <div className="bgInvoice4Right2"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-1 col-md-1" style={{paddingLeft: '0px'}}>
                        
                    </div>
                    <div className="col-lg-10 col-md-10">
                        
                    </div>
                    <div className="col-lg-1 col-md-1" style={{paddingRight: '0px'}}>
                        <div className="bgInvoice4Right">
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <?php } ?> */}
            <div className="container resContainer">
                <div className="row">
                    <div className="col-lg-2 col-md-2" style={{paddingLeft: '0px'}}>
                        <div className="invoiceleft1HalfBg4">
                            <div className="invoiceHeading4">
                                <h1 className="text-uppercase">{props.invoiceData.invoiceType}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <div className="row pt-5">
                                    <div className="col-lg-6">
                                        <h4 className="pt-4 invoice4Heading4">{props.invoiceData.invoiceType} to:</h4>
                                        <h5 className="invoice4Heading5">{props.invoiceData.cName}</h5>
                                        <h6 className="dateIssue addressInvoice4">{props.invoiceData.cAddress}</h6>
                                        <h6 className="dateIssue addressInvoice4">{props.invoiceData.cPhone}</h6>
                                    </div>
                                    <div className="col-lg-6">
                                        <h4 className="pt-4 invoice4Heading4">From:</h4>
                                        <h5 className="invoice4Heading5">{props.invoiceData.fName}</h5>
                                        <h6 className="dateIssue addressInvoice4">{props.invoiceData.fAddress}</h6>
                                        <h6 className="dateIssue addressInvoice4">{props.invoiceData.fPhone}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="pt-5 text-right">
                                    <img src={props.invoiceData.logo} className="logoImgInvoice2" alt="" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 pt-3">
                                        <h5 className="pt-4 invoice4DateHeading4">{props.invoiceData.invoiceType}#</h5>
                                        <h5 className="invoice4DateHeading4">Date</h5>
                                        <h5 className="invoice4DateHeading4">Due Date</h5>
                                    </div>
                                    <div className="col-lg-6 col-md-6 pt-3">
                                        <h5 className="pt-4 text-right invoice4DateHeading5">{props.invoiceData.invoiceNumber}</h5>
                                        <h5 className="text-right invoice4DateHeading5">{props.invoiceData.cDate}</h5>
                                        <h5 className="text-right invoice4DateHeading5">{props.invoiceData.dDate}</h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-lg-12">
                                <table className="table table-responsive tableRes4">
                                    <thead className="tableHeadingBg">
                                        <tr>
                                            <th style={{textAlign: 'left' }}>Item</th>
                                            <th style={{textAlign: 'left' }}>Item Description</th>
                                            <th>Qty</th>
                                            <th style={{textAlign: 'right' }}>Price</th>
                                            <th style={{textAlign: 'right' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                       
                                        {props.invoiceData.item.map((element)=>{
                                            return <tr>
                                                    <td style={{textAlign: 'left' }}>{element.pName}</td>
                                                    <td style={{textAlign: 'left' }}>{element.pDescription}</td>
                                                    <td>Product QTY</td>
                                                    <td style={{textAlign: 'right' }}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                    <td style={{textAlign: 'right' }}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                                </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1" style={{paddingRight: '0px'}}>
                        <div className="bgInvoice4Right1" style={{backgroundColor: props.invoiceData.color}}></div>
                        <div className="bgInvoice4Right2"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-1 col-md-1" style={{paddingLeft: '0px'}}>
                        <div className="bgInvoice3Left" style={{backgroundColor: props.invoiceData.color, height: '370px'}}>
                            <h5>Thank You Very Much</h5>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-10">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="row pt-4">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-right priceSub3">SubTotal:</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-right priceSub3">{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
                                    </div>
                                </div>
                                {props.invoiceData.discount && <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-right priceSub3">Discount:</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        
                                        <p className="text-right priceSub3">
                                            {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                        </p>
                                    </div>
                                </div>}
                                
                                {props.invoiceData.tax && <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-right priceSub3">Tax:</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                    
                                        <p className="text-right priceSub3">
                                            {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                        </p>
                                    </div>
                                </div>}
                                
                                {props.invoiceData.shipment && <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-right priceSub3">Shipping:</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        
                                        <p className="text-right priceSub3">{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                        
                                        
                                    </div>
                                </div>}
                                
                                <div className="invoiceFooter3 pt-2" style={{width: '70%', marginLeft: '30%'}}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <p className="text-left priceBalance4 mb-2"><b>Balance Due:</b></p>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <p className="text-right priceBalance4 mb-2"><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pt-3 ml-5" style={{borderTop: '2px solid black'}}>
                                    {props.invoiceData.aDetail && <div><h5 className="terms4Invoice">Additional Details:</h5>
                                    <p className="terms4Invoicepara">{props.invoiceData.aDetail}</p></div>}
                                    <p className="infoInvoice2 iconsSocial2 pt-3">
                                        {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-facebook"></i>
                                        </a>}
                                        {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter-square ml-2"></i>
                                        </a>}
                                        {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                        </a>}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="pt-3 ml-5" style={{borderTop: '2px solid black'}}>
                                    {props.invoiceData.terms && <div>
                                        <h5 className="terms4Invoice">Terms & Conditions</h5>
                                    <small className="terms4Invoicepara">{props.invoiceData.terms}</small>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1" style={{paddingRight: '0px'}}>
                        <div className="bgInvoice4Right">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
