import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

export default function SitePageSetting(props) {
    props.ptitle("Site Page Settings");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [siteName, setSiteName] = useState('');
    const [siteTagline, setSiteTagline] = useState('');
    const [siteDescription, setSiteDescription] = useState('');
    const [logoType, setLogoType] = useState('');
    const [logoPrefer, setLogoPrefer] = useState('');
  return (
    <>
        

      <div className="breadcrumb-holder">

        <div className="container-fluid">

        <ul className="breadcrumb">

            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>

            <li className="breadcrumb-item active">Site Settings</li>

        </ul>

        </div>

        </div>

        <section className="forms">

        <div className="container-fluid">

        {/* <!-- Page Header--> */}

        <div className="row">

            <div className="col-lg-12">

            <div className="card mt-3">

                <div className="card-header d-flex align-items-center">

                <h4>Site Settings</h4>

                </div>

                {/* <?php foreach ($post as $posts) {?> */}

                <div className="card-body">

                <form>

                    <div className="form-group">

                    <label><strong>Current Favicon</strong></label>

                    <img src="<?php echo base_url('uploads/'.$posts->favicon); ?>" className="w-2 ml-5" style={{width: '25%'}} alt="logo" />

                    </div>

                    <div className="form-group">

                    <label><strong>Change Favicon</strong></label>

                    <input type="file" name="article_image" className="form-control" />

                    </div>

                    <div className="form-group">

                    <label><strong>Site Name</strong></label>

                    <input type="text" value={siteName} onChange={(e)=>setSiteName(e.target.value)} name="siteTitle" className="form-control" />

                    </div>
                    <div className="form-group">

                    <label><strong>Site Tagline</strong></label>

                    <input type="text" value={siteTagline} name="siteTagline" className="form-control" onChange={(e)=>setSiteTagline(e.target.value)} />

                    </div>
                    <div className="form-group">

                    <label><strong>Site Description</strong></label>
                    <textarea className="form-control" onChange={(e)=>setSiteDescription(e.target.value)} name="siteDescription">
                        {siteDescription}
                    </textarea>
                    </div>

                    <div className="form-group mb-4">

                    <label><strong>Logo Type</strong></label>

                        <select className="form-control" id="colorselector" value={logoType} onChange={(e)=>setLogoType(e.target.value)}>

                        <option>-- Select --</option>

                        <option value="text">Text</option>

                        <option value="image">Image</option>

                        </select>

                    </div>

                    <div id="text" className="colors" style={{display: 'none'}}>

                    <div className="form-group">

                        <label><strong>Logo Text</strong></label>

                        <input type="text" className="form-control" name="logoText" value="<?php echo $posts->site_logo; ?>" />

                    </div>

                    <div className="form-group">

                        <label><strong>Logo Color</strong></label>

                        <input type="color" placeholder="Click To Change" className="form-control" id="favcolor" name="logoColor" value="<?php echo $posts->logo_color; ?>" />

                    </div>

                    </div>

                    <div className="colors" id="image" style={{display: 'none'}}>

                    <div className="form-group">

                        <label><strong>Logo Image</strong></label>

                        <img src="<?php echo base_url('uploads/'.$posts->logo_image); ?>" width="200" height="140" style={{objectFit: 'cover', objectPosition: 'top'}} alt="Logo" />

                        <input type="hidden" name="prevLogoImage" value="<?php echo $posts->logo_image; ?>" />

                    </div>

                    <div className="form-group">

                        <label><strong>Change Logo Image</strong></label>

                        <input type="file" name="logo_image" className="form-control" />

                        <small style={{fontStyle: 'italic'}}>Please Upload Image In Dimension 184*54</small>

                    </div>

                    </div>
                    <div className="form-group mb-4">

                    <label><strong>Logo Prefer</strong></label>

                        <select className="form-control" name="prefer" value={logoPrefer} onChange={(e)=>setLogoPrefer(e.target.value)} >
                            <option value="text">Text</option>
                            <option value="image">Image</option>    
                        </select>

                    </div>

                    <div className="form-group">       

                    <input type="submit" value="Save" className="btn btn-primary" />

                    </div>

                </form>

                </div>

                {/* // <?php } ?> */}

            </div>

            </div>

        </div>

        </div>

        </section>


    </>
  )
}
