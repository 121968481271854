import React from 'react'

export default function Template5(props) {
  return (
    <>
        <section>
            {/* <?php if (!empty($posts->cover_letter)) { ?> */}
            {/* <div className="container resContainer setOverflow pb-5" style={{pageBreakAfter: 'always', width: '100%', height: '100%'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="triangle-down ml-5" style={{borderTop: '150px solid red' }}>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <?php if (!empty($posts->logo_image)) { ?>
                                <div className="pt-4 text-right mr-5">
                                    <img src="<?php echo base_url('uploads/'.$posts->logo_image); ?>" className="logoImgInvoice2 ml-5" alt="" />
                                </div>
                                <?php } ?>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="pt-5">
                                    <h1 className="textHeading5 text-center" style={{color: 'red' }}>Date</h1>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 text-center dateIssue addressInvoice3">Current Date</h6>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="pt-3 ml-5">
                                    <h5 className="infoInvoice2 pt-5 ml-5" style={{color: 'red' }}>From:</h5>
                                    <h5 className="invoiceToHeading3 ml-5">From Name</h5>
                                    <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3">From Address</h6>
                                    <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3">Phone: From Num</h6>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mt-5" style={{width: '90%', margin: 'auto'}}>
                                    <?php echo $posts->cover_letter; ?>
                                    Cover Letter
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <?php } ?> */}
            <div className="container resContainer setOverflow" style={{overflow: 'hidden'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="triangle-down ml-5" style={{borderTop: '150px solid'+props.invoiceData.color }}>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="pt-4 text-right mr-5">
                                    <img src={props.invoiceData.logo} className="logoImgInvoice2 ml-5" alt="logo" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="pt-2">
                                    <h1 className="textHeading5 ml-5" style={{color: props.invoiceData.color }}>{props.invoiceData.invoiceType}</h1>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 dateIssue addressInvoice3">{props.invoiceData.invoiceType} No: {props.invoiceData.invoiceNumber}</h6>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 dateIssue addressInvoice3" style={{fontSize: '15px'}}>Date: {props.invoiceData.cDate}</h6>
                                    <h6 className="invoiceToHeading3 res5InvoiceHead ml-5 dateIssue addressInvoice3" style={{fontSize: '12px'}}>Due Date: {props.invoiceData.dDate}</h6>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6">
                                <div className="row pt-2">
                                    <div className="col-lg-6">
                                        <h5 className="infoInvoice2 pt-0 ml-5" style={{color: props.invoiceData.color }}>{props.invoiceData.invoiceType} To:</h5>
                                        <h5 className="invoiceToHeading3 ml-5">{props.invoiceData.cName}</h5>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3">{props.invoiceData.cAddress}</h6>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3">Phone: {props.invoiceData.cPhone}</h6>
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="infoInvoice2 pt-0 ml-5" style={{color: props.invoiceData.color }}>From:</h5>
                                        <h5 className="invoiceToHeading3 ml-5">{props.invoiceData.fName}</h5>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3">{props.invoiceData.fAddress}</h6>
                                        <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3">Phone: {props.invoiceData.fPhone}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <div className="pt-2">
                            <table className="table table-responsive tableRes6 pt-5">
                                <thead className="tableHeadingBg" style={{background: props.invoiceData.color}}>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>Item</th>
                                        <th style={{textAlign: 'left'}}>Item Description</th>
                                        <th>Qty</th>
                                        <th style={{textAlign: 'right'}}>Price</th>
                                        <th style={{textAlign: 'right'}}>Total</th>
                                    </tr>
                                </thead>
                                <tbody className="tbodyMarg5">
                                    {/* <?php foreach ($invoiceL as $inv) { ?> */}
                                    
                                    {/* <?php } ?> */}
                                    {props.invoiceData.item.map((element)=>{
                                        return <tr>
                                                <td style={{textAlign: 'left', fontWeight: '600'}}>{element.pName}</td>
                                                <td style={{textAlign: 'left'}}>{element.pDescription}</td>
                                                <td>{element.pQty}</td>
                                                <td style={{textAlign: 'right'}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                <td style={{textAlign: 'right'}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                        <div className="ml-5 mt-2">
                            {props.invoiceData.aDetail && <div><h5 className="terms4Invoice">Additional Details</h5>
                            <p className="terms4Invoicepara" style={{width: '80%'}}>{props.invoiceData.aDetail}</p></div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7">
                        <div id='effect3d' style={{background: props.invoiceData.color}}>
                            
                        </div>
                        <div className="totals">
                            <div className="row pt-4">
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-right priceSub6">SubTotal:</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-right priceSub6">{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
                                </div>
                            </div>
                            {props.invoiceData.discount && <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-right priceSub6">Discount:</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    
                                    <p className="text-right priceSub6">
                                    {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                    </p>
                                    
                                </div>
                            </div>}
                            
                            {props.invoiceData.tax && <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-right priceSub6">Tax:</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    
                                    <p className="text-right priceSub6">
                                    {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                    </p>
                                    
                                </div>
                            </div>}
                            {props.invoiceData.shipment && <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <p className="text-right priceSub6">Shipping:</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                
                                    <p className="text-right priceSub6">{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                    
                                </div>
                            </div>}
                            <div className="invoiceFooter3 pt-2" style={{width: '70%', marginLeft: '30%'}}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-left priceBalance6 mb-2"><b>Balance Due:</b></p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="text-right priceBalance6 mb-2"><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <div className="bgTermsInvoice6 mt-2" style={{background: props.invoiceData.color}}>
                            <div className="px-5 py-3">
                                <div className="row">
                                    {props.invoiceData.terms && <div className="col-lg-6">
                                        <h5 className="termsInvoice6">Terms & Conditions</h5>
                                        <p className="termsInvoicePara6">{props.invoiceData.terms}</p>
                                    </div>}
                                    <div className="col-lg-6">
                                        
                                    </div>
                                </div>						
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <p className="infoInvoice2 iconsSocial2 pt-3 text-center">
                            {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook"></i>
                            </a>}
                            {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter-square ml-2"></i>
                            </a>}
                            {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                            </a>}
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-4">
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
