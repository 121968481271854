import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom";
export default function Add(props) {
  const [item_name, setItem_Name] = useState('');
  const [item_price, setItem_Price] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  // Save Item
  const handleItemSave = async (event) => {
    event.preventDefault();
    const token = props.bToken;
    const apiResult = await axios({
      method: 'post',
        url: `${props.apiKey}create_item`,
        data:{
          item_name, item_price, email: JSON.parse(localStorage.getItem("user_email"))
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    setMsg(apiResult.data.status);
  }
  
  useEffect(()=>{
    if(msg === 200){
      localStorage.setItem("item_create_msg", JSON.stringify(msg));
      navigate("/dashboard/item-list");
    }
  }, [msg, navigate])

  props.ptitle("Add Item");
  useEffect(()=>{
    props.urlfun();
}, [props]);
  return (
    <>
    {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Add Item</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Add Item</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Item Name</strong></label>
                      <input type="text" placeholder="Item Name" name="name" className="form-control" onChange={(e)=>setItem_Name(e.target.value)} />
                    </div>
                    <div className="form-group">
                      <label><strong>Item Price</strong></label>
                      <input type="number" placeholder="Item Price" name="price" className="form-control" onChange={(e)=>setItem_Price(e.target.value)} />
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary" onClick={handleItemSave}/>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
