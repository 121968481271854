import React, { useEffect, useState } from 'react'
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function ContactSettings(props) {
    props.ptitle("Contact Settings");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const [progress, setProgress] = useState(0);
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [id, setId] = useState('')

     // Get Data  
     const getDataFunc = async (apiKey, bToken) => {
        setProgress(20);
        const token = bToken; 
        const apiResult = await axios({
          method: 'get',
          url: `${apiKey}get_contact_settings`,
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`
          }
        });
        if(apiResult.data.success === true){
            setAddress(apiResult.data.list[0].address);
            setEmail(apiResult.data.list[0].email);
            setNumber(apiResult.data.list[0].number);
            setPassword(apiResult.data.list[0].password);
            setId(apiResult.data.list[0].id)
        }
        setProgress(100);
      }
      useEffect(()=>{
        getDataFunc(props.apiKey, props.bToken);
      }, [props.apiKey, props.bToken]);

      const saveContactSettings = async (e)=>{
        e.preventDefault();
        setProgress(20);
        const token = props.bToken; 
        const apiResult = await axios({
          method: 'post',
          url: `${props.apiKey}update_contact_settings`,
          data: {
            id, number, address, email, password
          },
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`
          }
        });
        if(apiResult.data.status === 200){
            toast.success("Contact Updated Successfully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              });
            //   getDataFunc(props.apiKey, props.bToken);
        }
        setProgress(100);
      }
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
        
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Contact Settings</li>
          </ul>
        </div>
      </div>
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Contact Settings</h4>
                </div>
                {/* <?php foreach ($post as $posts) {?> */}
                <div className="card-body">
                  <form action="<?php echo base_url(); ?>updateContactDetails" method="post">
                    <div className="form-group">
                      <label><strong>Number</strong></label>
                      <input type="text" value={number} onChange={(e)=>setNumber(e.target.value)} name="number" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Email</strong></label>
                      <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} name="email" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Address</strong></label>
                      <input type="text" value={address} onChange={(e)=>setAddress(e.target.value)} name="address" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>App Generated Password</strong></label>
                      <input type="text" value={password} onChange={(e)=>setPassword(e.target.value)} name="password" className="form-control" />
                    </div>
                    
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary" onClick={saveContactSettings} />
                    </div>
                  </form>
                </div>
                {/* <?php } ?> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover />
    </>
  )
}
