import React, { useEffect } from 'react'
import img1 from '../../images/image-1-2.svg';
import img2 from '../../images/image-2-2.svg';
import img3 from '../../images/image-4-1.svg';
import {Link, useNavigate} from "react-router-dom";
export default function Home(props) {
    props.ptitle("Home");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const navigate = useNavigate();

    const handleBannerBtn = (e) =>{
        localStorage.getItem('session_status') === '200'?
        navigate('/dashboard')
        :
        navigate('/signup')
    }
    const handleCreateInvoice = (e) =>{
        localStorage.getItem('session_status') === '200'?
        navigate('/create-invoice')
        :
        navigate('/login')
    }
  return (
    <>
        {/* Main Header */}
        <section className="invoiceheader">
            <div className="container invoiceheadercontainer">
                <div className="row align-items-center invoiceheaderrow">
                    <div className="col-lg-6" style={{color: 'white'}}>
                        <h2 className="invoiceheaderhd">Smart And Simple</h2>
                        <p className="invoiceheaderp">A smart and simple online bookkeeping system. Manage and grow your small business with invoice maker. Create fast and professional invoice online. A business software you’ll need.</p><br />
                        <span onClick={handleBannerBtn} style={{cursor: 'pointer'}} className="btn1">Start Now</span>
                    </div>
                    <div className="col-lg-6 mobileViewMain">
                        <div className="formbg">
                            <h6 className="mb-4 formheading">Experience professional invoicing!</h6>
                            <form method="post" action="<?php echo base_url(); ?>createAccount">
                                {/* <!-- Email --> */}
                                <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="far fa-envelope formicon"></i></span>
                                </div>
                                <input type="email" name="email" required className="form-control mainform" placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                {/* <!-- Password --> */}
                                <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-shield-alt formicon"></i></span>
                                </div>
                                <input type="password" name="password" required className="form-control mainform" placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                {/* <!-- Re-Enter Password --> */}
                                <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-shield-alt formicon"></i></span>
                                </div>
                                <input type="password" name="confirm_password" required className="form-control mainform" placeholder="Re-Enter Password" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                <input type="submit" name="submit" value="Sign Up" className="formbtn" style={{cursor: 'pointer'}} />
                            </form>
                            <div className="text-center mt-4 formaccount">
                                <span>Already Have Account ?</span><span  style={{marginLeft: '5px'}}><a href="/">Login</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Services --> */}
        <section className="serviceicon" style={{backgroundColor: 'white'}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <h2 className="serviceiconhead">Trusted by businesses across many industries</h2>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            {/* <!-- Icon First Colummn --> */}
                            <div className="col-lg-4 p-0">
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-mug-hot"></i></span>
                                    <span className="ml-2 serviceiconhd">Freelancers</span>
                                </div>
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-plane"></i></span>
                                    <span className="ml-2 serviceiconhd">Travel & Tourism</span>
                                </div>
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-guitar"></i></span>
                                    <span className="ml-2 serviceiconhd">Musicians</span>
                                </div>
                            </div>
                            {/* <!-- Icon Second Column --> */}
                            <div className="col-lg-4 p-0">
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-camera"></i></span>
                                    <span className="ml-2 serviceiconhd">Photographers</span>
                                </div>
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-female"></i></span>
                                    <span className="ml-2 serviceiconhd">Fashion Designers</span>
                                </div>
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-user-tie"></i></span>
                                    <span className="ml-2 serviceiconhd">Self Employed</span>
                                </div>
                            </div>
                            {/* <!-- Icon Third Column --> */}
                            <div className="col-lg-4 p-0">
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-handshake"></i></span>
                                    <span className="ml-2 serviceiconhd">LLC Business</span>
                                </div>
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-calendar-alt"></i></span>
                                    <span className="ml-2 serviceiconhd">Non-Profit Organisations</span>
                                </div>
                                <div className="invoiceserviceicon">
                                    <span className="serviceicon"><i className="fas fa-taxi"></i></span>
                                    <span className="ml-2 serviceiconhd">Taxi Services</span>
                                </div>
                            </div>
                        </div>
                    </div>					
                </div>
            </div>
        </section>
        {/* <!-- Make Invoices, Get Paid --> */}
        <section style={{backgroundColor: 'white', padding: '80px 0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="invoiceheaderhd">Make Invoices, Get Paid</h2>
                        <p className="invoiceheaderp">In a matter of seconds, you can create and send professional-looking invoices. Getting paid is even easier with custom templates.

                        With only a few clicks, you can create invoices online and send them directly to your clients. All of your accounting records are managed digitally in one spot.</p><br />
                        <span onClick={handleCreateInvoice} className="btn2" style={{cursor: 'pointer'}}>Make an Invoice</span>
                    </div>
                    <div className="col-lg-6 mobileViewMain">
                        <div>
                            <img src={img1} className="sectionimg" alt="Make Invoices, Get Paid" />
                        </div>
                    </div>
                </div>				
            </div>
        </section>
        {/* <!-- Create And Keep Track --> */}
        <section style={{backgroundColor: 'white', padding: '80px 0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div>
                            <img src={img2} className="sectionimg" alt="Create And Keep Track" />
                        </div>
                    </div>
                    <div className="col-lg-6 mobileViewMain">
                        <h2 className="invoiceheaderhd">Create And Keep Track</h2>
                        <p className="invoiceheaderp">Our unique dashboard instantly tells you who owes you money at a glance, allowing you to focus on the things that really matter. Create and manage an infinite number of goods and clients, keep track of payment status, and discover who owes you money all in one place.</p><br />
                        <span onClick={handleCreateInvoice} style={{cursor: 'pointer'}} className="btn2">Create an Invoice</span>
                    </div>
                </div>				
            </div>
        </section>
        {/* <!-- Contact Banner --> */}
        <section style={{backgroundColor: '#002b67',  padding: '80px 0px' }}>
            <div className="container">
                <div className="row align-items-center contBannerImg">
                    <div className="col-lg-8 col-6">
                        <h2 className="contactusbannerhead">Feel Free and Contact us</h2>
                        <p className="contactusbannerparaa">We are here to help and answer any question you might have. We look forward to hearing from you</p>
                    </div>
                    <div className="col-lg-4 col-6">
                        <Link to="/contact-us" className="btn3">Contact Now</Link>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Easily Email, Download, Save And Send Invoices. --> */}
        <section style={{backgroundColor: 'white', padding: '80px 0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="invoiceheaderhd">Easily Email, Download, Save And Send Invoices.</h2>
                        <p className="invoiceheaderp">Create your account and get access to manual invoice building, expert advice and instant benefits like fastly send your invoice directly to clients or download and save them.

                        Create your account and get access to Professional looking invoices right now. You can edit, save, send or download invoices in a few clicks.</p><br />
                        <span onClick={handleCreateInvoice} style={{cursor: 'pointer'}} className="btn2">Create an Invoice</span>
                    </div>
                    <div className="col-lg-6 mobileViewMain">
                        <div>
                            <img src={img3} className="sectionimg" alt="Easily Email, Download, Save And Send Invoices." />
                        </div>
                    </div>
                </div>				
            </div>
        </section>
        
        <section style={{backgroundColor: '#002b67', padding: '30px 0px' }}>
            <div className="container">
                <div className="row align-items-center contBannerImg" >
                    <div className="col-lg-8">
                        <h2 className='contactusbannerhead'>NewsLetter</h2>
                        <p className='contactusbannerparaa'>We are here to help and answer any question you might have. We look forward to hearing from you</p>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-group mb-3">
                        <input type="text" className="form-control mainform" placeholder="Enter your E-mail" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <button className="btn4" type="button">Subscribe</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
