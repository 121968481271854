import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import axios from 'axios';

export default function Contact(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    props.ptitle('Contact');
    useEffect(()=>{
        props.urlfun();
    }, [props]);

    const sendEmail = async (e) =>{
        e.preventDefault();
        console.log("Working");
        if(!name || !email || !subject || !phone){
            toast.error("Please Fill the Credentials", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
        else{
            const token = props.bToken;
            const apiResult = await axios({
                method: 'post',
                url: `${props.apiKey}send-contact-mail`,
                data: {
                    name, email, subject, 'number': phone, message
                },
                headers:{
                    Accept: 'application/javascript',
                    Authorization: `Bearer ${token}`
                }
              });
              console.log(apiResult);
              if(apiResult.data.status === 200){
                toast.success("Mail Submitted Successfully", {
                    position: "bottom-right",
                    aposition: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });
              }
        }
    }
  return (
    <>
        <section className="p_bT">
            <div className="container-fluid">
                <div className="row" style={{padding: '1.25rem 0'}}>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        {/* <div className="form-group text-center alert alert-success fadeOutText">
                        <small></small>
                        </div> */}
                        <div className="formBg">
                            <form id="basic-form" onSubmit={sendEmail} method="post">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div>
                                            <h1 className="text-center contactHeading">Get In Touch</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 margResInputFields">
                                        <div className="inputFields">
                                            <input required='' value={name} onChange={(e)=>setName(e.target.value)} type='text' className="inputStyles" name="name" />
                                            <label alt='Full Name' placeholder='Full Name' className="textFields"></label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 margResInputFields">
                                        <div className="inputFields">
                                            <input required='' type='text' value={email} onChange={(e)=>setEmail(e.target.value)} className="inputStyles" name="email" />
                                            <label alt='Email' placeholder='Email' className="textFields"></label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 margResInputFields">
                                        <div className="inputFields">
                                            <input required='' type='text' value={subject} onChange={(e)=>setSubject(e.target.value)} className="inputStyles" name="subject" />
                                            <label alt='Subject' placeholder='Subject' className="textFields"></label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 margResInputFields">
                                        <div className="inputFields">
                                            <input required='' type='text' value={phone} onChange={(e)=>setPhone(e.target.value)} className="inputStyles" name="number" />
                                            <label alt='Mobile Number' placeholder='Mobile Number' className="textFields"></label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 margResInputFields">
                                        <div className="inputFields">
                                            <textarea className="textareaStyles" value={message} onChange={(e)=>setMessage(e.target.value)} required='' rows="5" placeholder="Message" name="message" style={{padding: '10px'}}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4">
                                        <button className="buttonSend" tabIndex="0" type="submit" style={{cursor: 'pointer'}}>
                                            <span className="">
                                                Send
                                            </span>
                                            <span className="ml-2"><i className="icofont-paper-plane"></i></span> 
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
        <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
