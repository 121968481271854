import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from './components/frontend/Header';
import Home from './components/frontend/Home';
import Footer from './components/frontend/Footer';
import Contact from './components/frontend/Contact';
import Login from './components/frontend/Login';
import Signup from './components/frontend/Signup';
import Blogs from './components/frontend/Blog/Blogs';
import SingleBlog from './components/frontend/Blog/SingleBlog';
import BackHeader from './components/backend/Header';
import BackFooter from './components/backend/Footer';
import Dashboard from './components/backend/Dashboard';
import SideNav from './components/backend/SideNav';
import AddClient from './components/backend/Client/Add';
import ClientList from './components/backend/Client/List';
import AddItem from './components/backend/Item/Add';
import ItemList from './components/backend/Item/List';
import Account from './components/backend/Account/Account';
import EditClient from './components/backend/Client/Edit';
import EditItem from './components/backend/Item/Edit';
import CreateInvoice from './components/frontend/Invoice/Create';
import Quote from './components/backend/Quotation/Quote';
import Invoices from './components/backend/Invoice/Invoice';
import Estimate from './components/backend/Estimate/Estimate';
import BankAccount from './components/backend/BankAccount/BankAccount';
import PaypalAccount from './components/backend/PaypalAccount/PaypalAccount';
import PayoneerAccount from './components/backend/PayoneerAccount/PayoneerAccount';
import SkrillAccount from './components/backend/SkrillAccount/SkrillAccount';
import WiseAccount from './components/backend/WiseAccount/WiseAccount';
import InvoiceTemplate from './components/backend/InvoiceTemplate/InvoiceTemplate';
import Preview from './components/frontend/Preview/Preview';
import GetLink from './components/frontend/GetLink/GetLink';
import Download from './components/frontend/Download/Download';
import Support from './components/frontend/Support';
import AddAdmin from './components/backend/Admin/Add';
import AdminList from './components/backend/Admin/List';
import UserList from './components/backend/User/List';
import AddBlog from './components/backend/Blog/Add';
import ListBlog from './components/backend/Blog/List';
import EditBlog from './components/backend/Blog/Edit';
import Tblogs from './components/frontend/TemplateBlog/Tblogs';
import Singletblog from './components/frontend/TemplateBlog/Singletblog';
import AddTempalteBlog from './components/backend/TemplateBlog/Add';
import ListTemplateBlog from './components/backend/TemplateBlog/List';
import EditTemplateBlog from './components/backend/TemplateBlog/Edit';
import AdminInvoices from './components/backend/AdminInvoices/AdminInvoices';
import ListTemplateRequest from './components/backend/AllRequestTemplates/List';
import WebMasterTool from './components/backend/WebMasterTool/WebMasterTool';
import ContactSettings from './components/backend/Contact Settings/ContactSettings';
import SitePageSetting from './components/backend/SitePageSetting/SitePageSetting';
import CreateRequest from './components/backend/TemplateRequest/CreateRequest';
import AllRequest from './components/backend/TemplateRequest/AllRequest';
import RequestResponse from './components/backend/AllRequestTemplates/Response';

function App() {
  const [tagLine, setTagLine] = useState('Invoice Maker');
  const [favicon, setFavicon] = useState('');
  const [backend, setBackend] = useState('');
  const [checkSession, setCheckSession] = useState('201');
  useEffect(()=>{
    setInterval(() => {
      setCheckSession(localStorage.getItem('session_status'));
    }, 100);
  }, [checkSession]);

  // Local Storage Session Check
  // Global APi Setup
  const apiKey = 'https://invoicebackend.invoicies.com/api/';
  const bToken = '1|xt53brUbwSOMA1HDLcw3P2MIjzqYHlvTl3w2IEid';

  const backendFunc = () =>{
    setBackend(window.location.pathname.slice(0, 10));
  }
  // Header API Setting
  const set_Header = async () =>{
      const token = bToken;
      const apiResult = await axios({
          method: 'get',
          url: `${apiKey}get_settings`,
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`
          }
        });
        setFavicon(apiResult.data.src + apiResult.data.list[0].favicon);
        setTagLine(apiResult.data.list[0].site_tagline);
  }
  useEffect(()=>{
      set_Header();
  }, [])

  // site TItle
  const setTitleFun = (title) =>{
    document.title = title +' - '+ tagLine;
  }
  return (
    <>
      <Router>
        { backend === "/dashboard"? <SideNav  apiKey = {apiKey} bToken = {bToken} favicon={favicon}/> : ''}
        <div className={backend === "/dashboard"?'page':''}>
        { backend === "/dashboard"? <BackHeader apiKey = {apiKey} bToken = {bToken} favicon={favicon} /> : <>
           <Header apiKey = {apiKey} bToken = {bToken} favicon={favicon} /> 
        </> }
        <Routes>
          <Route path="/" element={ <Home ptitle={setTitleFun} urlfun={backendFunc} /> } />
          <Route path="/contact-us" element={ <Contact ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/login" element={ <Login ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} back={backendFunc} /> } />
          <Route path="/signup" element={ <Signup ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />
          <Route path="/blogs" element={ <Blogs ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />
          <Route path="/template-blogs" element={ <Tblogs ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />
          <Route path="/support" element={ <Support ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />
          <Route path={"/blog/:blogslug"} element={<SingleBlog ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} />} />
          <Route path={"/template-blog/:Tblogslug"} element={<Singletblog ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} />} />


          {/* Invoice Templates */}
          <Route path={`/Preview/:invoice_number`} element={ <Preview ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />

          {/* GetLink */}
          <Route path={`/PreviewInvoice/:invoice_number`} element={ <GetLink ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />

          {/* Download */}
          <Route path={`/Download/:invoice_number`} element={ <Download ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />

          {/* // Create Invoice */}
          <Route path="/Send-Invoice/:id" element={ <CreateInvoice ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />
          <Route path="/create-invoice" element={ <CreateInvoice ptitle={setTitleFun} apiKey = {apiKey} bToken = {bToken} urlfun={backendFunc} /> } />
          {checkSession !== '201' && <>
          {/* Amdin Panel User role Routing */}
          <Route path="/dashboard" element={ <Dashboard ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/add-client" element = { <AddClient ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/edit-client" element = { <EditClient ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/client-list" element = { <ClientList ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/add-item" element = { <AddItem ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/item-list" element = { <ItemList ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/edit-item" element = { <EditItem ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/account-setting" element = { <Account ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/quotation" element = { <Quote ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/invoice" element = { <Invoices ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/estimate" element = { <Estimate ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/bank-account" element = { <BankAccount ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/paypal-account" element = { <PaypalAccount ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/payoneer-account" element = { <PayoneerAccount ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/skrill-account" element = { <SkrillAccount ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/wise-account" element = { <WiseAccount ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/invoice-template" element = { <InvoiceTemplate ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/create-request" element = { <CreateRequest ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/all-request" element = { <AllRequest ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />

          {/* Admin Role Routes */}
          <Route path="/dashboard/add-admin" element = { <AddAdmin ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/admin-list" element = { <AdminList ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/user-list" element = { <UserList ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/add-blog" element = { <AddBlog ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/blog-list" element = { <ListBlog ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/edit-blog/:id" element = { <EditBlog ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/add-template-blog" element = { <AddTempalteBlog ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/template-blog-list" element = { <ListTemplateBlog ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/edit-template-blog/:id" element = { <EditTemplateBlog ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/all-invoice" element = { <AdminInvoices ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/all-request-templates" element = { <ListTemplateRequest ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/web-master-tools" element = { <WebMasterTool ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/contact-settings" element = { <ContactSettings ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/site-page-setting" element = { <SitePageSetting ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />
          <Route path="/dashboard/view-response/:id" element = { <RequestResponse ptitle={setTitleFun} urlfun={backendFunc} apiKey = {apiKey} bToken = {bToken} /> } />

          </>
          }
          


        </Routes>
        { backend === "/dashboard"? <BackFooter /> :  <Footer /> }
        </div>
      </Router>
    </>
  );
}

export default App;
