import React, { useEffect, useState } from 'react'
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function PaypalAccount(props) {
    const [paypalId, setPaypalId] = useState([]);
    const [emailId, setEmailId] = useState('');
    const [progress, setProgress] = useState(0);
    const [save, setSave] = useState('save');
    props.ptitle("Paypal Account");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const getData = async (apiKey, bToken) => {
        setProgress(40);
        const token = bToken; 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}paypal_details/${JSON.parse(localStorage.getItem("user_email"))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult.data.paypal_details === null){
          setProgress(100);
          setSave('save');
        }else{
          let data = apiResult.data.paypal_details;
          setPaypalId(data.id);
          setEmailId(data.email_id);
          setProgress(100);
          setSave('update');
        }
        
    }
    useEffect(()=>{
      getData(props.apiKey, props.bToken);
    }, [props.apiKey, props.bToken]);

  // Save
  const handleSavePaypalAccountDetail = async (event) =>{
    event.preventDefault();
    setProgress(40);
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}create_paypal_details`,
        data: {
            emailId, email: JSON.parse(localStorage.getItem('user_email'))
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.status === 200){
      toast.success("Paypal Account Details Created Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      setSave('update');
      setProgress(100);
      getData(props.apiKey, props.bToken);
    }
  };
  // Update
  const handleUpdatePaypalAccountDetail = async (event) =>{
    event.preventDefault();
    setProgress(40);
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}update_paypal_details`,
        data: {
            emailId, paypalId
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.status === 200){
      toast.success("Paypal Account Details Updated Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      setProgress(100);
      getData(props.apiKey, props.bToken);
    }
  }
  return (
    <>
      <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">Paypal Account Details</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Paypal Account Details</h4>
                </div>
                <div className="card-body">
                    <form>
                      {save === 'update'? <>
                        <input type="hidden" value={paypalId} onChange={(e)=>setPaypalId(e.target.value)} name="paypalId" className="form-control" />
                        </>:
                        <></>
                      }
                      <div className="form-group">
                        <label><strong>Paypal Email</strong></label>
                        <input type="text" placeholder="Paypal Email" value={emailId} onChange={(e)=>setEmailId(e.target.value)} name="emailId" className="form-control" required />
                      </div>
                      <div className="form-group">       
                        {save === 'save'? <>
                        <input type="submit" value="Save" onClick={handleSavePaypalAccountDetail} className="btn btn-primary" />
                        </>:
                        <>
                        <input type="submit" value="Update" onClick={handleUpdatePaypalAccountDetail} className="btn btn-primary" />
                        </>
                        }
                        
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
