import React from 'react'
import {Link} from "react-router-dom";
export default function Footer() {
  return (
    <>
        <footer className="s_cD">
            <div className="m_bz m_bC">
                <div className="s_cS">
                    <nav className="H_dW">
                        <ul className="H_d6">
                            <li className="H_d7">
                                <span>Invoice Maker</span>
                                <ul className="H_d8">
                                    <li className="H_d9">
                                        <Link rel="" target="_self" className="H_fb" to="/template-blogs">Free Invoice Templates</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="H_d7">
                                <span>Help</span>
                                <ul className="H_d8">
                                    <li className="H_d9">
                                        <Link to="/support" rel="noopener" className="H_fb">Support</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="H_d7">
                                <span>Resources</span>
                                <ul className="H_d8">
                                    <li className="H_d9">
                                        <Link to="/" rel="" target="_self" className="H_fb">Tutorials</Link>
                                    </li>
                                    
                                </ul>
                            </li>
                            <li className="H_d7">
                                <span>Contact Us</span>
                                <ul className="H_d8">
                                    
                                    <li className="H_d9">
                                        <Link to="/contact-us" className="H_fb">Contact Us</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <div className="s_cT">
                        <small>© 2020 - Invoice Maker, LLC - All Rights Reserved</small>
                    </div>
                </div>
            </div>
        </footer>



        <script src="https://code.jquery.com/jquery-3.1.1.min.js" />
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossOrigin="anonymous" />
        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js" />
        <script type="text/javascript" src="/js/bootstrap.min.js" />
        <script type="text/javascript" src="/js/fontawesome.min.js" />
        <script type="text/javascript" src="/js/slick.min.js" />
        <script src="https://cdn.ckeditor.com/4.15.1/standard/ckeditor.js" />
        <script src="https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos.js" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.0/jquery.validate.min.js" />
        <script src="/js/main.js" />
        <script src="https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js" />
        <script src="/js/custom.js" />

    </>
  )
}
