import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Template0 from '../InvoiceTemplete/Template0';
import Template1 from '../InvoiceTemplete/Template1';
import Template2 from '../InvoiceTemplete/Template2';
import Template3 from '../InvoiceTemplete/Template3';
import Template4 from '../InvoiceTemplete/Template4';
import Template5 from '../InvoiceTemplete/Template5';
export default function GetLink(props) {
    props.ptitle("Invoice Templates");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const [invoiceDesign, setInvoiceDesign] = useState('');
    // Master Color
    const [color, setColor] = useState('');
    // InvoiceType
    const [invoiceType, setInvoiceType] = useState('');
    // Logo
    const [logo, setLogo] = useState('');
    // Logo src
    // const [src, setSrc] = useState('');
    // Client Data
    const [cName, setCName] = useState('');
    const [cPhone, setCPhone] = useState('');
    const [cAddress, setCAddress] = useState('');
    // user Data
    const [fName, setFName] = useState('');
    const [fPhone, setFPhone] = useState('');
    const [fAddress, setFAddress] = useState('');
    // date
    const [cDate, setCDate] = useState('');
    const [dDate, setDDate] = useState('');
    // Item 
    const [item, setItem] = useState([]);

    // invoiceNumber
    const [invoiceNumber, setInvoiceNumber] = useState('');

    // aditional Details
    const [aDetail, setADetail] = useState(''); 

    // terms
    const [terms, setTerms] = useState(null);
    // invoice Currency
    const [currencey, setCurrencey] = useState('');

    // discount
    const [discount, setDiscount] = useState('');
    const [discountType, setDiscountType] = useState('');

    // tax
    const [tax, setTax] = useState('');
    const [taxType, setTaxType] = useState('');

    // total
    const [subTotal, setSubTotal] =useState('');
    const [grandTotal, setGrandTotal] = useState('');
    const [shipment, setShipment] = useState('');

    // Social Accounts
    const[fb, setFb] = useState('');
    const[insta, setInsta] = useState('');
    const[twitter, setTwitter] = useState('');

    const getInvoiceType = async (apiKey, bToken, iType)=>{
        let linkhref = window.location.pathname.slice(16);
        const token = bToken
        const apiResult = await axios({
            method: 'post',
            url: `${apiKey}get_details_invoice`,
            data: {
                invoice_type: iType, invoice_number: linkhref 
            }, 
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        console.log(apiResult);
        if(apiResult.data.invoice !== null){
            let data = apiResult.data.invoice
            setInvoiceDesign(data.inv_design);
            setLogo(data.dataUrl);
            // setSrc(apiResult.data.src);
            setCName(data.person_to_name);
            setCPhone(data.person_to_num);
            setCAddress(data.person_to_address);
            setColor(apiResult.data.color);
            setFName(data.person_from_name);
            setFPhone(data.person_from_num);
            setFAddress(data.person_from_address);
            setInvoiceType(data.invoice_type);
            setCDate(data.invoice_current_date);
            setDDate(data.invoice_due_date);
            setInvoiceNumber(data.invoice_number);
            setItem(JSON.parse(data.product_name));
            setADetail(data.extra_note);
            setTerms(data.terms);
            setSubTotal(data.product_sub_total);
            setDiscount(data.product_discount);
            setDiscountType(data.discount_type);
            setCurrencey(data.invoice_currency);
            setTax(data.product_tax);
            setTaxType(data.tax_type);
            setGrandTotal(data.product_total);
            setShipment(data.product_shipment);
        }
        const apiResult2 = await axios({
            method: 'get',
            url: `${apiKey}get_user_detail/${JSON.parse(localStorage.getItem("user_id"))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult2.data.success === true)
        {
            let data = apiResult2.data.user;
            setFb(data.fb_link);
            setInsta(data.insta_link);
            setTwitter(data.twitter_link);
        }
    }
    useEffect(()=>{
        getInvoiceType(props.apiKey, props.bToken, '3');
    }, [props.apiKey, props.bToken]);
  return (
    <>  
        {(invoiceDesign === '0' || invoiceDesign === "default") && <Template0 invoiceData = {{ color: color, logo:logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> }


        {invoiceDesign === '1' && <Template1 invoiceData = {{ color: color, logo:logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> }


        {invoiceDesign === '2' && <Template2 invoiceData = {{ color: color, logo:logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> }

        {invoiceDesign === '3' && <Template3 invoiceData = {{ color: color, logo:logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> }


        {invoiceDesign === '4' && <Template4 invoiceData = {{ color: color, logo:logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> }


        {invoiceDesign === '5' && <Template5 invoiceData = {{ color: color, logo:logo, cName: cName, cPhone: cPhone, cAddress: cAddress, fName: fName, fPhone: fPhone, fAddress: fAddress, invoiceType: invoiceType, cDate: cDate, dDate: dDate, invoiceNumber: invoiceNumber, item: item, aDetail: aDetail, terms: terms, subTotal: subTotal, discount: discount, discountType: discountType, currencey: currencey, tax: tax, taxType: taxType, shipment: shipment, grandTotal: grandTotal, fb: fb, insta: insta, twitter: twitter }} /> }

    </>
  )
}
