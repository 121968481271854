import React from 'react'

export default function Footer() {
  return (
    <>
        <footer className="main-footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                    <p>@Copyright 2021</p>
                    </div>
                    <div className="col-sm-6 text-right">
                    <p>Design by <a href="https://kyaassolutions.com/" className="external">Kyaas Solutions</a></p>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}
