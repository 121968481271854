import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
export default function Login(props) {
    props.ptitle('Login');
    const [newuser, setNewUser] = useState(201);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [session, setsession] = useState(201);
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState('');
    const [dbemail, setDbemail] = useState(' ');
    const navigate = useNavigate();
    // Create Account Message
    useEffect(()=>{
        if(newuser === 200){
            toast.success("Account Created Successfully", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
            localStorage.setItem('user_create_status', JSON.stringify(201));
        }
        else{
            setNewUser(JSON.parse(localStorage.getItem("user_create_status")));
        }
    }, [newuser]);

    // Login Setup
    const handleLogin = async (event) =>{
        event.preventDefault();
        if(!email || !password){
            toast.error('Please fill Credentials!', {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
        else{
            if(email.search('@') === -1){
                toast.error('Please fill valid email!', {
                    position: "bottom-right",
                    aposition: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });
            }else{
                const token = props.bToken; 
                const apiResult = await axios({
                    method: 'post',
                    url: `${props.apiKey}login`,
                    data: {
                        email, password
                    },
                    headers:{
                        Accept: 'application/javascript',
                        Authorization: `Bearer ${token}`
                    }
                });
                if(apiResult.data.errors){
                    toast.error(apiResult.data.errors, {
                        position: "bottom-right",
                        aposition: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark'
                    });
                }
                else{
                    setsession(apiResult.data.login_status);
                    setRole(apiResult.data.role);
                    setUserId(apiResult.data.id);
                    setDbemail(apiResult.data.email);
                }
            }
        }
    }

    useEffect(()=>{
        if(session === 200){
            localStorage.setItem('session_status', session);
            localStorage.setItem('role',JSON.stringify(role));
            localStorage.setItem('login_msg', JSON.stringify(session));
            localStorage.setItem('user_id', JSON.stringify(userId));
            localStorage.setItem('user_email', JSON.stringify(dbemail));
        }
    }, [session, role, userId, dbemail]);
    useEffect(()=>{
        if(session === 200){
            navigate('/dashboard');
            props.back();
        }
    }, [session, navigate, props])
  return (
    <>
        <div className="page login-page">
        <div className="container resInvoiceCont">
            <div className="form-outer text-center d-flex align-items-center">
            <div className="form-inner">
                <div className="logo text-uppercase"><span style={{color: '#357abc !important'}}>Login User </span><strong className="text-primary" style={{color: '#b1b1b1 !important'}}>Dashboard</strong></div>
                <form className="text-left form-validate">
                <div className="form-group-material">
                    <input id="login-username" onChange={(e)=>setEmail(e.target.value)} type="email" name="email" required data-msg="Please enter your email" className="input-material" placeholder="Email" value={email} />
                </div>
                <div className="form-group-material">
                    <input id="login-password" onChange={(e)=>setPassword(e.target.value)} type="password" name="password" required data-msg="Please enter your password" className="input-material" placeholder="Password" value={password} />
                </div>
                <div className="form-group text-center">
                    <input type="submit" name="login" style={{background:'#1a90c6 !important', borderColor: '#1a90c6 !important', borderRadius: '0'}} value="Login" className="btn btn-primary lg-bt res-login-btn" onClick={handleLogin} />
                </div>
                <div className="form-group text-center mb-0">
                    <small>Didn't Have Account ?</small><Link id="login" to="/signup" className="btn btn-primary fgtbtn"><small style={{color: '#357abc', fontSize: '15px'}}>Signup</small></Link>
                </div>
                {/* <div className="form-group text-center">
                    <small>Didn't Remember Password ?</small><a id="login" href='/' className="btn btn-primary fgtbtn"><small style={{color: '#357abc', fontSize: '13px'}}>Forget Password</small></a>
                </div> */}
                </form>
            </div>
            </div>
        </div>
        </div>
        <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
