import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
export default function Header(props) {
    const [role, setRole] = useState('user');
    const [color, setColor] = useState('');
    const navigate = useNavigate();
    useEffect(()=>{
        if(role === 'user'){
            setRole(JSON.parse(localStorage.getItem('role')));
            setColor('#5096DA');
        }
    }, [role]);
    const handleLogout = async() =>{
      const token = props.bToken;
      const apiResult = await axios({
          method: 'get',
          url: `${props.apiKey}logout`,
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`
          }
        });
        console.log(apiResult);
        if(apiResult.status === 200){
          localStorage.setItem('session_status', JSON.stringify(201));
          navigate("/");
        }
  }
  return (
    <>
        <header className="header">
        <nav className="navbar" style={{backgroundColor: color}}>
          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              <div className="navbar-header"><a id="toggle-btn" href="/" className="menu-btn" style={{backgroundColor: '#357abc' }}><i className="icon-bars"> </i></a><a href="<?php echo base_url('Dashboard'); ?>" className="navbar-brand">
                  <div className="brand-text d-none d-md-inline-block"><span>Invoice {role}  </span> <strong className="text-primary" style={{color: '#357abc !important' }}>Dashboard</strong></div></a></div>
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                <li className="nav-item"><span className="nav-link logout" onClick={handleLogout} style={{cursor: "pointer", color: "white"}}> <span className="d-none d-sm-inline-block mr-2">Logout</span><i className="fa fa-sign-out"></i></span></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}