import React, { useEffect } from 'react'
import { useState } from 'react';
import { CKEditor } from 'ckeditor4-react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";

export default function Add(props) {
    props.ptitle("Add Blog");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [short, setShort] = useState('');
    const [description, setDescription] = useState('');
    const [img, setImg] = useState('');

    const handleCoverChange = async (event) =>{
      await setDescription(event.editor.getData());
    }

    const savBlog = async (e)=>{
      e.preventDefault();
      const token = props.bToken;
      const apiResult = await axios({
          method: 'post',
          url: `${props.apiKey}create_template_blog`,
          data: {
            blog_title: title, blog_short_description: short, blog_description: description, blog_image: img
          },
          headers:{
              Accept: 'application/javascript',
              Authorization: `Bearer ${token}`,
              "Content-type": "multipart/form-data",
          }
        });
        if(apiResult.data.success === true){
          localStorage.setItem('blog_add', JSON.stringify('200'));
          navigate('/dashboard/template-blog-list');
        }
    }

  return (
    <>
    {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Add Template Blog</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Add Template Blog</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Title</strong></label>
                      <input type="text" placeholder="Title" name="name" className="form-control" value={title} onChange={(e)=>setTitle(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <label><strong>Short Description</strong></label>
                      <textarea placeholder="Description" name="short_description" className="form-control" value={short} onChange={(e)=>setShort(e.target.value)} required>{short}</textarea>
                    </div>
                    <div className="form-group">
                      <label><strong>Description</strong></label>
                      {/* <textarea placeholder="Description" id="txtEditor" name="editor1" className="form-control" required></textarea> */}
                      <CKEditor 
                          activeclassName="p10"
                          content={description}
                          onChange = {handleCoverChange}                        
                        />
                    </div>
                    <div className="form-group">
                      <label><strong>Image</strong></label>
                      <input type="file" name="upload_image" className="form-control" id="upload_image" onChange={(e)=>setImg(e.target.files[0])} required />
                      <div id="uploaded_image"></div>
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary" onClick={savBlog} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
