import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useState, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import "jquery-ui-dist/jquery-ui";
import axios from 'axios';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import InvoiceAction from './InvoiceAction';
export default function Create(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
// Cover States
  const [coverData, setCoverData] = useState('');
  const [coverFile, setCoverFile] = useState('');
  const [coverSrc, setCoverSrc] = useState('');
  const [coverName, setCoverName] = useState('');
//   Defualt Logo Check
  const [logo, setLogo] = useState('');

// Invoice Type
const [invoiceType, setInvoiceType] = useState('Invoice');

// Invoice Currency
const [currency, setCurrency] = useState('$');

//   Add Invoice Temp logo
  const [logoImg, setLogoImg] = useState(null);
  const [nwidth, setNwidth] = useState(null);
  const [nheight, setNheight] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [crop, setCrop] = useState({aspect: '1:1'});
  const [logoResult, setLogoResult] = useState('');
  const [logoName, setLogoName] = useState('');
  const [logoDataurl, setLogoDataUrl] = useState('');

// Invoice From DaTa
  const [fName, setFName] = useState('');
  const [fPhone, setFPhone] = useState('');
  const [fAddress, setFAddress] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [numCheck, setNumCheck] = useState('0');

// Date Setting
const [dateType, setDateType] = useState('Due');
const [date, setDate] = useState();

// Client Data Setting
const [cName, setCName] = useState('');
const [cPhone, setCPhone] = useState('');
const [cAddress, setCAddress] = useState('');
const [clientData, setClientData] = useState('');
const [clientSuggestion, setClientSuggestion] = useState([]);

// Items Listing in Setup with Clone
const [itemBox, setItemBox] = useState([{pName:'',pPrice:'', pQty: '', pAmount: '', pDescription: ''}]);
const [itemData, setItemData] = useState();
const [itemSuggestion, setItemSuggestion] = useState([]);

// Total Balance Setup
const [subTotal, setSubTotal] = useState('0');
const [total, setTotal] = useState('');

// Tax
const [tax, setTax] = useState('');
const [taxPer, setTaxPer] = useState('amount');

// Discount
const [discount, setDiscount] = useState('');
const [discountPer, setDiscountPer] = useState('amount');

// Shipment
const [shipment, setShipment] = useState('');
const [totalwithdt, setTotalWithdt] = useState('');

// Additonal Detals
const [aDetail, setADetial] = useState('');

// Add client
const [clientStatus, setClientStatus] = useState('found');

const userProfile = async (apiKey, bToken, id) =>{
    const token = bToken;
    const apiResult = await axios({
        method: 'get',
        url: `${apiKey}get_user_detail/${id}`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.success === true){
        setFName(apiResult.data.user.name);
        setFPhone(apiResult.data.user.mobile_number);
        setFAddress(apiResult.data.user.address);
        setLogo(apiResult.data.user.logo);
        setLogoResult(apiResult.data.user.logo);
        setLogoDataUrl(apiResult.data.user.logo);
    }
}
useEffect(()=>{
    userProfile(props.apiKey, props.bToken, JSON.parse(localStorage.getItem('user_id')));
}, [props]);
useEffect(()=>{
    if(dateType === 'Due'){
        var days = 0;
        var dates = new Date();
        var res = dates.setTime(dates.getTime() + (days * 24 * 60 * 60 * 1000));
        var newType = new Date(res);
        const formattedDate = newType.toLocaleDateString('en-CA', {
            day: 'numeric', month: 'numeric', year: 'numeric'}).replace(/ /g, '/');
        setDate(formattedDate);
    }
}, [dateType])
useEffect(()=>{
 if(cName === ''){
    setCPhone('');
    setCAddress('');
 }
}, [cName, cPhone, cAddress]);

  props.ptitle('Create Invoice');
  useEffect(()=>{
      props.urlfun();
  }, [props]);

  // Fetch Logo
  useEffect(()=>{
    if(logo === null){
      setLogo(JSON.parse(localStorage.getItem('user_logo')));
    }
    else{
      
    }
  }, [logo])
  const handleCoverLetter = (e) => {
    e.target.style.display = 'none';
    document.getElementById('CoverOption').style.display = "flex"; 
  }
  // get Cover Letter CK Data
  const handleCoverChange = async (event) =>{
    await setCoverData(event.editor.getData());
  }
  // Save Cover Data 
  const handleSaveCoverData = ()=>{
    if(coverData.length === 0){
      toast.error("Add some Text in Cover Letter", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
    }
    else{
      toast.success("Cover Letter Save Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
      window.$("#modalPoll-1").modal("hide");
      window.$("#CoverOption").css("display", "none");
      window.$('#CoverAdded').css("display", "flex");
    }
  }

  // View Cover Letter Modal
  const coverModalView = () =>{
    window.$('#modalPoll-2').modal("show");
  }

//   Remove Cover Letter
  const coverLetterRemove = async()=>{
    await setCoverData('Removed');
    toast.success("Cover Letter Removed Successfully", {
      position: "bottom-right",
      aposition: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark'
  });
    window.$('#CoverAdded').css('display', 'none');
    window.$("#addCoverBTN").css('display','block');
  }

//   Save Cover image 
  const handleCoverUpload = async()=>{
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}file_save`,
        data: {
            image: coverFile
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
        }
      });
      if(apiResult.data.status === 200){
        toast.success("Cover Letter File Uploaded Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      window.$('#CoverAttach').css('display', 'flex');
      window.$('#modalPoll-3').modal('hide');
      window.$("#CoverOption").css("display", "none");
      setCoverSrc(apiResult.data.src);
      setCoverName(apiResult.data.image);

      }
  }

//   Logo Image Modal
useEffect(()=>{
    if(logoImg !== null){
        window.$('#cropImageModal').modal('show');
    }
    else{
        window.$('#cropImageModal').modal('hide');
    }
}, [logoImg]);
const handleLogoImg = (e) =>{
    setLogoImg(URL.createObjectURL(e.target.files[0]));
}
const handleSaveLogoImg = async() =>{
    const canvas = document.createElement('canvas');
    const scaleX = nwidth / width;
    const scaleY = nheight / height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    const img = document.getElementById('logImg');
    ctx.drawImage(
        img, 
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );
    const base64Image = canvas.toDataURL('image');
    setLogoDataUrl(base64Image);
    if(base64Image){
        window.$('#cropImageModal').modal('hide');
        const token = props.bToken;
        const apiResult = await axios({
            method: 'post',
            url: `${props.apiKey}file_object_save`,
            data: {
                image: base64Image
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`,
                "Content-type": "multipart/form-data",
            }
        });
        setLogoName(apiResult.data.image);
        toast.success("Logo Uploaded Successfully", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
        });
    }
    setLogoResult(base64Image);
}
function onLoadImage(e){
    setNheight(e.currentTarget.naturalHeight);
    setNwidth(e.currentTarget.naturalWidth);
    setWidth(e.currentTarget.width);
    setHeight(e.currentTarget.height);
}
useEffect(()=>{
    if(invoiceNumber === null){
        let iNumber = "0000"+Math.round(Math.random()* 99999);
        setInvoiceNumber(iNumber);
        localStorage.setItem("invoice_number", JSON.stringify(iNumber));
    }
}, [invoiceNumber]);

// Date Setting with DropDow
const handleInvoiceDate = (e) =>{
    setDateType(e.target.value);
    var days = e.target.value;
    var dates = new Date();
    var res = dates.setTime(dates.getTime() + (days * 24 * 60 * 60 * 1000));
    var newType = new Date(res);
    const formattedDate = newType.toLocaleDateString('en-CA', {
        day: 'numeric', month: 'numeric', year: 'numeric'}).replace(/ /g, '/');
    setDate(formattedDate);
}

// Date Setting with Field
const handleIDate = (e)=>{
    if(dateType === 'Custom'){
        setDate(e.target.value);
    }
}


// Fetch single Client get_client
const fetchSingleClient = async (apiKey, bToken) =>{
    if(window.location.pathname.slice(1, 13) === 'Send-Invoice'){
        let locLink = window.location.pathname.slice(14);
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_client/${locLink}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers": "content-type",
                "Access-Control-Allow-Methods":"PUT, POST, GET, DELETE, PATCH, OPTIONS, TRACE, CONNECT"
            }
        });
        if(apiResult.data.success === true){
            setCName(apiResult.data.client.name);
            setCPhone(apiResult.data.client.mobile_number);
            setCAddress(apiResult.data.client.address);
        }
    }
}
useEffect(()=>{
    fetchSingleClient(props.apiKey, props.bToken);
}, [props.apiKey, props.bToken]);



// Client Setting
const fetchClient = async (apiKey, bToken) =>{
    const token = bToken;
    let email = JSON.parse(localStorage.getItem('user_email'));
    const apiResult = await axios({
        method: 'post',
        url: `${apiKey}get_like_clients/`,
        data: {email},
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers": "content-type",
            "Access-Control-Allow-Methods":"PUT, POST, GET, DELETE, PATCH, OPTIONS, TRACE, CONNECT"
        }
    });
    if(apiResult.data.status === 200){
        setClientData(apiResult.data.client);
    }
}
useEffect(()=>{
    fetchClient(props.apiKey, props.bToken);
}, [props.apiKey, props.bToken]);


const filterClient = async (e) =>{
    let text = e.target.value;
    setCName(text);
    let matches = [];
    if(text.length > 0 ){
        matches = clientData.filter(client=>{
            const regex = new RegExp(`${text}`, "gi");
            return client.name.match(regex);
        })
    }
    if(matches.length !== 0){
        setClientStatus('found');
    }
    else{
        if(!text){
            setClientStatus('found');
        }
        else{
            setClientStatus('notFound');
        } 
    }
    setClientSuggestion(matches);
    // setCName(text);
}

const handleSingleSuggest = (e, client_id) =>{
    let newMatch = [];
    if(clientSuggestion.length > 0){
       newMatch = clientSuggestion.filter(client=>{
        return client.id.toString().match(client_id.toString());
       });
       for(let i = 0; i<newMatch.length; i++){
        setCName(newMatch[i].name);
        setCPhone(newMatch[i].mobile_number);
        setCAddress(newMatch[i].address);
       }
    }
    setClientSuggestion([]);
}

// Item Clone and Filter Setup
const fetchItem = async (apiKey, bToken) =>{
    const token = bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${apiKey}get_like_items/`,
        data: {email: JSON.parse(localStorage.getItem('user_email')) },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    setItemData(apiResult.data.item);
}
useEffect(()=>{
    fetchItem(props.apiKey, props.bToken);
}, [props.apiKey, props.bToken]);

const handleCloneItem = () =>{
    setItemBox([...itemBox, {pName:'',pPrice:'', pQty: '', pAmount: '', pDescription: ''}]);
}
const handleRemoveClone = (index)=>{
    const list = [...itemBox];
    list.splice(index, 1);
    setItemBox(list);
}
const handleItemBoxContent = (e, index) =>{
    const {name, value} = e.target;
    const list = [...itemBox];
    list[index][name] = value;
    setItemBox(list);
    if(name === 'pName'){
        let text = e.target.value;
        let matches = [];
        if(text.length > 0 ){
            matches = itemData.filter(client=>{
                const regex = new RegExp(`${text}`, "gi");
                return client.item_name.match(regex);
            })
        }
        setItemSuggestion(matches);
    }
    if(name === 'pQty'){
        list[index]['pAmount'] = list[index][name] * list[index]['pPrice'];
        setItemBox(list);
    }
    if(name === 'pPrice'){
        list[index]['pAmount'] = list[index][name] * list[index]['pQty'];
        setItemBox(list);
    }
}
const handleItemSingleSuggest = (e, suggest_id, index) =>{
    let newMatch = [];
    if(itemSuggestion.length > 0){
       newMatch = itemSuggestion.filter(item=>{
        return item.id.toString().match(suggest_id.toString());
       });
       for(let i = 0; i<newMatch.length; i++){
        const list = [...itemBox];
        list[index]['pName'] = newMatch[i].item_name;
        list[index]['pPrice'] = newMatch[i].item_price;
        setItemBox(list);
       }
    }
    setItemSuggestion([]);
}
// remove PAMout errer
const handleAmount = () =>{

}
// SubTotal Function
const handleSubTotal = (item) =>{
    let SubT = 0;
    window.$(item).each(function( index ) {
        SubT = SubT + this.pAmount;
      });
      setSubTotal(SubT);
}
useEffect(()=>{
    handleSubTotal(itemBox)
}, [itemBox]);
// Total Function
const handleTotal = (item) =>{
    let Total = 0;
    window.$(item).each(function( index ) {
        Total = Total + this.pAmount;
      });
      setTotal(Total);
}
useEffect(()=>{
    handleTotal(itemBox)
}, [itemBox]);

const handleTax = (e)=>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0){
        if(taxPer === 'amount'){
            if(!e.target.value){
                setTax('0')
                e.target.select();
                if(discount){
                    if(discountPer === 'amount'){
                        let result = subTotal - parseInt(discount);
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                    else{
                        let result = subTotal - ((parseInt(discount) / 100) * subTotal );
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                }
                else{
                    setTotal(subTotal);
                    setTotalWithdt(subTotal);
                }
            }
            else{
                setTax(e.target.value);
                if(!discount){
                    let result = parseInt(e.target.value) + subTotal;
                    setTotal(result);
                    setTotalWithdt(result);
                }
                else{
                    if(discountPer === 'amount'){
                        let result = parseInt(e.target.value) + subTotal - parseInt(discount);
                        setTotal(result);
                        setTotalWithdt(result)
                    }
                    else{
                        let result = subTotal + parseInt(e.target.value) - ((parseInt(discount) / 100) * subTotal );
                        setTotal(result);
                        setTotalWithdt(result);
                        setDiscountPer('per');
                    }
                }
            }
        }
        else{
            if(!e.target.value){
                setTax('0')
                e.target.select();
                if(discountPer === 'amount'){
                    let result = subTotal - parseInt(discount);
                    setTotal(result);
                    setTotalWithdt(result);
                }
                else{
                    let result = subTotal - ((parseInt(discount) / 100) * subTotal );
                    setTotal(result);
                    setTotalWithdt(result);
                }
            }
            else{
                setTax(e.target.value);
                if(!discount){
                    let result =  ((parseInt(e.target.value) / 100) * subTotal ) + subTotal;
                    setTotal(result);
                    setTotalWithdt(result);
                }
                else{
                    if(discountPer === 'amount'){
                        let result = ((parseInt(e.target.value) / 100) * subTotal ) + subTotal - parseInt(discount);
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                    else{
                        let subResult = ((parseInt(e.target.value) / 100) * subTotal ) + subTotal;
                        let result = subResult - ((parseInt(discount) / 100) * subResult);
                        setTotal(result);
                        setTotalWithdt(result);
                        setDiscountPer('per');
                    }
                }
            }
        }
    }
}
const handleTaxWithAmount = (e) =>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0){
        if(tax){
            if(!discount){
                let result = parseInt(tax) + subTotal
                setTotal(result);
                setTotalWithdt(result);
                setTaxPer('amount');
            }
            else{
                if(discountPer === 'amount'){
                    let result = parseInt(tax) + subTotal - parseInt(discount);
                    setTotal(result);
                    setTotalWithdt(result);
                    setTaxPer('amount');
                }
                else{
                    let result = ((parseInt(tax) / 100) * subTotal ) + subTotal - parseInt(discount);
                    setTotal(result);
                    setTotalWithdt(result);
                    setDiscountPer('per');
                }
            }
        }
    }
}
const handleTaxWithPer = () =>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0){
        if(tax){
            if(!discount){
                let result =  (( parseInt(tax) / 100) * subTotal ) + subTotal;
                setTotal(result);
                setTaxPer('per');
                setTotalWithdt(result);
            }
            else{
                if(discountPer === 'amount'){
                    let result = ((parseInt(tax) / 100) * subTotal ) + subTotal - parseInt(discount);
                    setTotal(result);
                    setTotalWithdt(result);
                    setTaxPer('per');
                }
                else{
                    let subResult = ((parseInt(tax) / 100) * subTotal ) + subTotal;
                    let result = subResult - ((parseInt(discount) / 100) * subResult);
                    setTotal(result);
                    setTotalWithdt(result);
                    setTaxPer('per');
                }
            }
        }
    }
}

// Discount Setup
const handleDiscount = (e)=>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0)
    {
        if(discountPer === 'amount'){
            if(!e.target.value){
                setDiscount('0')
                e.target.select();
                if(tax){
                    if(taxPer === 'amount'){
                        let result = parseInt(tax) + subTotal
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                    else{
                        let result = ((parseInt(tax) / 100) * subTotal ) + subTotal;
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                }
                else{
                    setTotal(subTotal);
                    setTotalWithdt(subTotal);
                }
            }
            else{
                setDiscount(e.target.value);
                if(!tax){
                    let result = subTotal - parseInt(e.target.value);
                    setTotal(result);
                    setTotalWithdt(result);
                }
                else{
                    if(taxPer === 'amount'){
                        let result = subTotal + parseInt(tax) - parseInt(e.target.value);
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                    else{
                        let result = ((parseInt(tax) / 100) * subTotal ) + subTotal - parseInt(e.target.value);
                        setTotal(result);
                        setTotalWithdt(result);
                        setDiscountPer('per');
                    }
                }
            }
        }
        else{
            if(!e.target.value){
                setDiscount('0')
                e.target.select();
                if(tax){
                    if(taxPer === 'amount'){
                        let result = parseInt(tax) + subTotal
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                    else{
                        let result = ((parseInt(tax) / 100) * subTotal ) + subTotal;
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                }
                else{
                    setTotal(subTotal);
                    setTotalWithdt(subTotal);
                }
            }
            else{
                setDiscount(e.target.value);
                if(!tax){
                    let result =  subTotal - ((parseInt(e.target.value) / 100) * subTotal );
                    setTotal(result);
                    setTotalWithdt(result);
                }
                else{
                    if(taxPer === 'amount'){
                        let result = subTotal + parseInt(tax) - ((parseInt(e.target.value) / 100) * subTotal );
                        setTotal(result);
                        setTotalWithdt(result);
                    }
                    else{
                        let subResult = subTotal - ((parseInt(e.target.value) / 100) * subTotal );
                        let result = ((parseInt(tax) / 100) * subResult) + subResult
                        setTotal(result);
                        setTotalWithdt(result);
                        setDiscountPer('per');
                    }
                }
            }
        }
    }
}

// handleAmountwithDiscount
const handleAmountwithDiscount = (e)=>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0){
        if(discount){
            if(!tax){
                let result = subTotal - parseInt(discount);
                setTotal(result);
                setDiscountPer('amount');
                setTotalWithdt(result);
            }
            else{
                if(taxPer === 'amount'){
                    let result = subTotal + parseInt(tax) - parseInt(discount);
                    setTotal(result);
                    setTotalWithdt(result);
                    setDiscountPer('amount');
                }
                else{
                    let result = ((parseInt(tax) / 100) * subTotal ) + subTotal - parseInt(discount);
                    setTotal(result);
                    setTotalWithdt(result);
                    setDiscountPer('per');
                }
            }
        }
    }

}
const handlePerWithDiscount = (e)=>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0){
        if(tax){
            if(!tax){
                let result =  subTotal - (( parseInt(discount) / 100) * subTotal );
                setTotal(result);
                setDiscountPer('per');
                setTotalWithdt(result);
            }
            else{
                if(taxPer === 'amount'){
                    let result = subTotal + parseInt(tax) - ((parseInt(discount) / 100) * subTotal );
                    setTotal(result);
                    setTotalWithdt(result);
                    setDiscountPer('per');
                }
                else{
                    let subResult = subTotal - ((parseInt(discount) / 100) * subTotal ) ;
                    let result = ((parseInt(tax) / 100) * subResult) + subResult
                    setTotal(result);
                    setTotalWithdt(result);
                    setDiscountPer('per');
                }
            }
        }
    }
}

// Handle Shipment
const handleShipment = (e) =>{
    if(subTotal !== '0' && subTotal !== '' && subTotal !== 0){
        if(!e.target.value){
            setShipment(0);
            setTotal(totalwithdt);
        }
        else{
            setShipment(e.target.value)
            let  result = parseInt(tax) + parseInt(e.target.value) + subTotal - parseInt(discount);
            setTotal(result);
        }
    }
}

// Add New Client
const handleAddNewClient = async (event) =>{
    event.preventDefault();
    const token = props.bToken;
    const apiResult = await axios({
      method: 'post',
      url: `${props.apiKey}create_clients`,
      data: {
          name: cName, 
          mobile_number: cPhone, 
          address: cAddress,
          cit: "",
          street: "",
          country: "", 
          email: JSON.parse(localStorage.getItem('user_email'))
      },
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    if(apiResult.data.status === 200){
        setClientStatus("found");
        toast.success("Client Add Success Fully", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
        });
        fetchClient(props.apiKey, props.bToken);
    }
}
const handleInvoiceNumber = async (e) =>{
    setInvoiceNumber(e.target.value);
    const token = props.bToken;
    const apiResult = await axios({
      method: 'post',
      url: `${props.apiKey}check_invoice_number`,
      data: {
        invoiceNum: e.target.value
      },
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    if(apiResult.data.status === 201){
        toast.error("This Invoice Number Already Exists", {
            position: "bottom-right",
            aposition: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
        });
        setNumCheck('0');
    }
    else{
        setNumCheck('1');
    }
}
const form = useRef();

const handleRemoveLogo = (e) =>{
    setLogoResult('');
    setLogo('');
}
  return (
    <>
      <section className="p_bT">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-10" style={{padding: '1.25rem 0'}}>
                    <div className="formBg">
                        <form id="basic-form" ref={form}  >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <button className="buttonCover" onClick={handleCoverLetter} tabIndex="0" type="button" id="addCoverBTN" style={{cursor: 'pointer'}}>
                                            <span className="">
                                                Add Cover Letter
                                            </span>
                                            <span className="ml-2"><i className="icofont-notepad"></i></span> 
                                        </button>
                                    </div>
                                    {/* Write Cover Letter  */}
                                    <div className="row" id="CoverOption" style={{display: 'none'}}>
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-4">
                                            <div className="d-flex ml-5">
                                                <button className="alert alert-success" type="button" data-toggle="modal" data-target="#modalPoll-1" style={{cursor: 'pointer'}}>Write</button>
                                                <button className="alert alert-success ml-3" type="button" data-toggle="modal" data-target="#modalPoll-3" style={{cursor: 'pointer'}}>Upload</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-4"></div>
                                    </div>
                                    {/* Upload Cover letter  */}
                                    <div className="row" id="CoverAdded" style={{display: 'none'}}>
                                        <div className="col-lg-6">
                                            {/* <p className="text-center alert alert-success">Cover Letter Attached</p> */}
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="d-flex">
                                                <button className="alert alert-success" type="button" onClick={coverModalView} style={{cursor: 'pointer'}}>View</button>
                                                <button className="alert alert-danger ml-3" type="button" onClick={coverLetterRemove} style={{cursor: 'pointer'}}>Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" id="CoverAttach" style={{display: 'none'}}>
                                        <div className="col-lg-6">
                                            {/* <p className="text-center alert alert-success">Cover Letter Uploaded</p> */}
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="d-flex">
                                                <a className="alert alert-success" id="book_now" rel="noreferrer"  target="_blank" href={coverSrc+coverName}>View</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 margResInput">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="inputFields">
                                                <select name="invoice_type" className="inputStyles invoiceTypeSelect" id="typeInv" value={invoiceType} style={{cursor: 'pointer'}} onChange={(e)=>setInvoiceType(e.target.value)} >
                                                    <option value="Invoice">Invoice</option>
                                                    <option value="Estimate">Estimate</option>
                                                    <option value="Quotation">Quotation</option>
                                                </select>
                                                <label alt='Type' placeholder='Type' className="textFields"></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="inputFields resCountType">
                                                <select name="currency" className="inputStyles" id="currency" onChange={(e)=>setCurrency(e.target.value)} style={{cursor: 'pointer'}}>
                                                    <option value="$">America &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="L">Albania &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; L</option>
                                                    <option value="$">Argentina &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="Դ">Armenia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Դ</option>
                                                    <option value="$">Australia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="ман">Azerbaijan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ман</option>
                                                    <option value="Br">Belarus &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Br</option>
                                                    <option value="Bs.">Bolivia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Bs.</option>
                                                    <option value="P">Botswana &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; P</option>
                                                    <option value="Лв.">Bulgaria &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Лв.</option>
                                                    <option value="$">Brazil &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="៛">Cambodia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ៛</option>
                                                    <option value="$">Canada &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="¥">China &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ¥</option>
                                                    <option value="$">Colombia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;$</option>
                                                    <option value="₡">Costa Rica &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;₡</option>
                                                    <option value="kn">Croatia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; kn</option>
                                                    <option value="$">Cuba &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="Kč">Czech Republic &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Kč</option>
                                                    <option value="kr">Denmark &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; kr</option>
                                                    <option value="$">Dominican Republic &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="£">Egypt &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; £</option>
                                                    <option value="$">El Salvador &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="€">Euro Member Countries &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; €</option>
                                                    <option value="$">Fiji &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="¢">Ghana &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ¢</option>
                                                    <option value="Q">Guatemala &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Q</option>
                                                    <option value="₣">Guinea &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₣</option>
                                                    <option value="$">Guyana &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="$">Hong Kong &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="L">Honduras &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; L</option>
                                                    <option value="Ft">Hungary &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Ft</option>
                                                    <option value="Rp">Indonesia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rp</option>
                                                    <option value="₹">India &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₹</option>
                                                    <option value="kr">Iceland &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; kr</option>
                                                    <option value="﷼">Iran &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ﷼</option>
                                                    <option value="₪">Israel &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₪</option>
                                                    <option value="¥">Japan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ¥</option>
                                                    <option value="лв">Kazakhstan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; лв</option>
                                                    <option value="ksh">Kenya &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ksh</option>
                                                    <option value="د.ك">Kuwait &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; د.ك</option>
                                                    <option value="MK">Malawi &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MK</option>
                                                    <option value="RM">Malaysia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; RM</option>
                                                    <option value="$">Mexico &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="DH">Morocco &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;DH</option>
                                                    <option value="$">Namibia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="Rs">Nepal &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rs</option>
                                                    <option value="$">New Zealand &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;$</option>
                                                    <option value="kr">Norway &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; kr</option>
                                                    <option value="ر.ع.">Oman &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ر.ع.</option>
                                                    <option value="Rs">Pakistan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rs</option>
                                                    <option value="S/.">Peru &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;S/.</option>
                                                    <option value="₱">Philippines &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;₱</option>
                                                    <option value="zł">Poland &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; zł</option>
                                                    <option value="QR">Qatar &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; QR</option>
                                                    <option value="L">Romania &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; L</option>
                                                    <option value="p.">Russia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; p.</option>
                                                    <option value="ر.س">Saudi Arabia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ر.س</option>
                                                    <option value="Дин.">Serbia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Дин.</option>
                                                    <option value="₨">Seychelles &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₨</option>
                                                    <option value="$">Singapore &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;$</option>
                                                    <option value="$">Solomon Islands &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="S">Somalia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; S</option>
                                                    <option value="R">South Africa &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; R</option>
                                                    <option value="Rs">Sri Lanka &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rs</option>
                                                    <option value="kr">Sweden &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; kr</option>
                                                    <option value="CHF">Switzerland &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; CHF</option>
                                                    <option value="£">Syria &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; £</option>
                                                    <option value="NT$">Taiwan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; NT$</option>
                                                    <option value="฿">Thailand &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ฿</option>
                                                    <option value="TT$">Trinidad and Tobago &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	&nbsp; &nbsp; &nbsp;  TT$</option>
                                                    <option value="₺">Turkey &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₺</option>
                                                    <option value="₴">Ukraine &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ₴</option>
                                                    <option value="£">United Kingdom &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;£</option>
                                                    <option value="$">United States &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;$</option>
                                                    <option value="$">Uruguay &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; $</option>
                                                    <option value="UZS">Uzbekistan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  UZS</option>
                                                    <option value="Bs F">Venezuela &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  Bs F</option>
                                                    <option value="₫">Vietnam &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  ₫</option>
                                                    <option value="﷼">Yemen &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  ﷼</option>
                                                    <option value="$">Zimbabwe &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;	&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  $</option>
                                                </select>
                                                <label alt='Currency' placeholder='Currency' className="textFields"></label>
                                            </div>
                                        </div>
                                    </div>								
                                </div>
                                {/* Current Logo */}
                                {!logo ? 
                                <>
                                  <div className="col-lg-6 margResInput">
                                    <div className="panel panel-default">
                                        {logoResult === ''?<>
                                            <div className="panel-body filebutton" align="center" id="srCrop">
                                                <label htmlFor="upload" className="labelUpload"><span><i className="icofont-image mr-2" style={{fontSize: '35px'}}></i> Add Logo</span>
                                                </label>
                                                <input type="file" name="upload_image" id="upload_image" onChange={handleLogoImg} />
                                                <br />
                                            </div>
                                        </>
                                        :
                                        <>  
                                            <div className="btn btn-primary" onClick={handleRemoveLogo}>Remove</div>
                                            <div> <img src={logoResult} alt="logoUploadImage" style={{width: '500px', height: '250px', objectFit: 'contain' }} className="border" /> </div>
                                        </>
                                        }
                                        
                                     </div>
                                    </div>
                                    <div className="modal fade right" id="cropImageModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                                        aria-hidden="true" data-backdrop="false" style={{background: 'rgba(0, 0, 0, 0.6)'}}>
                                            <div className="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document" style={{maxWidth: '765px'}}>
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <p className="heading lead"><b>Upload Logo</b></p>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true" className="white-text">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {/* Croppie */}
                                                        <div className="text-center">
                                                        <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                                                            <img src={logoImg} onLoad={onLoadImage} alt="logo" id="logImg" className='border' />
                                                        </ReactCrop>
                                                        </div>
                                                    </div> 
                                                    <div className="modal-footer justify-content-center">
                                                        <button type="button" className="btn btn-primary waves-effect waves-light addCover" id="coverLetterUpload" onClick={handleSaveLogoImg} style={{color: 'white'}}>Upload
                                                            <i className="fa fa-paper-plane ml-1"></i>
                                                        </button>
                                                        <button type="button" className="btn btn-outline-primary waves-effect" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </>:
                                <>
                                  <div className="form-group">
                                        <label><strong className="LabelsFor">Current Logo</strong></label><br />
                                        <div className="btn btn-primary" onClick={handleRemoveLogo}>Remove</div><br />
                                        <input type="hidden" name="cropedImage" id="newImgVal" value="<?php echo $posts->logo ?>" />
                                        <img src={logo} className="ml-4 invLogoCurrent" alt="logo" />
                                    </div>
                                </>
                                }
                                    
                            </div>
                            <div className="row">
                                <div className="col-lg-6 margResInput">
                                    {/* User Information */}
                                    <input type="hidden" value="<?php echo $posts->ntn_number; ?>" id="ntnNum" />
                                    <div className="row">
                                        <div className="col-lg-12 mt-3 margResInput">
                                            <h5 className="LabelsFor">From:</h5>
                                        </div>
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <input required='' type='text' name="fName" id="fromName" className="inputStyles" onChange={(e)=>setFName(e.target.value)} value={fName} />
                                                <label alt='Name' placeholder='Name' className="textFields"></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <input required='' type='text' name="fNum" id="fromNumber" className="inputStyles" onChange={(e)=>setFPhone(e.target.value)} value={fPhone} />
                                                <label alt='Phone Number' placeholder='Phone Number' className="textFields"></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <input required='' type='text' id="fromAddress" className="inputStyles" onChange={(e)=>setFAddress(e.target.value)} name="fAddress" value={fAddress} />
                                                <label alt='Address' placeholder='Address' className="textFields"></label>
                                            </div>
                                            {   
                                                
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-5 margResInput">
                                    <div className="row">
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <input required='' name="invoice_number" type='text' value={invoiceNumber} id="invoiceNumber" onChange={(e)=>handleInvoiceNumber(e)} className="inputStyles" />
                                                <label alt='Invoice Number' placeholder='Invoice Number' className="textFields"></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <select className="inputStyles" name="cDate"onChange={handleInvoiceDate} style={{cursor: 'pointer'}} defaultValue='none' >
                                                    <option value="0">Today</option>
                                                    <option value="Custom">Custom</option>
                                                    <option value="1">Next Day</option>
                                                    <option value="2">2 Days</option>
                                                    <option value="3">3 Days</option>
                                                    <option value="4">4 Days</option>
                                                    <option value="5">5 Days</option>
                                                    <option value="6">6 Days</option>
                                                    <option value="7">7 Days</option>
                                                    <option value="10">10 Days</option>
                                                    <option value="14">14 Days</option>
                                                    <option value="21">21 Days</option>
                                                    <option value="30">30 Days</option>
                                                    <option value="45">45 Days</option>
                                                    <option value="60">60 Days</option>
                                                    <option value="90">90 Days</option>
                                                    <option value="120">120 Days</option>
                                                    <option value="180">180 Days</option>
                                                    <option value="360">360 Days</option>
                                                </select>
                                                <label alt='Due Date' placeholder='Due Date' id="dueDateVal" className="textFields"></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <input required='' type='date' name='dDate' className="inputStyleDate" value={date} onChange={handleIDate} />
                                                <label alt='Date' placeholder='Date' className="textFieldDate"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12 mt-3 margResInput">
                                            <h5 className="LabelsFor">To:</h5>
                                        </div>
                                        <div className="col-lg-12">
                                        </div>
                                        <div className="col-lg-6 margResInput">
                                            <div className="inputFields">
                                                <input required='' name="tPerson" type='text' className="inputStyles" value={cName} onChange={filterClient} onBlur={()=>{
                                                    setTimeout(()=>{
                                                        setClientSuggestion([]);
                                                    }, 100);
                                                }} />
                                                <label alt='Name' placeholder='Name' className="textFields"></label>
                                            </div>
                                            {clientSuggestion.length !== 0? 
                                            <>
                                                <div style={{boxShadow: '-1px 0px 17px -1px rgba(0,0,0,0.18)', marginTop: '-15px', marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll'}}>
                                                    {clientSuggestion.map((suggest)=>{
                                                        return <div className='suggestStyle' key={suggest.id} onClick={(e)=>handleSingleSuggest(e, suggest.id)}>{suggest.name}</div>
                                                    })}
                                                </div>
                                            </>
                                            : ""}
                                            <span id="nameError" style={{color: 'red'}}></span>
                                        </div>
                                        <div className="col-lg-6 margResInput">
                                            <div className="inputFields">
                                                <input required='' name="tNum" type='number' className="inputStyles mobileAdd"  value={cPhone} onChange={(e)=>setCPhone(e.target.value)} />
                                                <label alt='Phone Number' placeholder='Phone Number' className="textFields"></label>
                                            </div>
                                            <span id="mobileError" style={{color: 'red'}}></span>
                                        </div>
                                        <div className="col-lg-12 margResInput">
                                            <div className="inputFields">
                                                <input required='' name="tAddress" type='text' className="inputStyles" value={cAddress} onChange={(e)=>setCAddress(e.target.value)} />
                                                <label alt='Address' placeholder='Address' className="textFields"></label>
                                            </div>
                                            <span id="addressError" style={{color: 'red'}}></span>
                                        </div>
                                        {/* <?php if (!empty($post)) { ?> */}
                                        {clientStatus === "notFound"?<>
                                            <div className="col-lg-12 margResInput userAdd">
                                            <button className="buttonCreate" tabIndex="0" type="button" onClick={handleAddNewClient} style={{cursor: 'pointer'}}>
                                                <span className="">
                                                    Add To Client List
                                                </span>
                                            </button>
                                        </div>
                                        </>:""}
                                        {/* <?php } ?> */}
                                    </div>
                                </div>
                            </div>
                            <hr className="r_cz" />
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table table-responsive">
                                        <table width="100%" border="0">
                                            <thead>
                                                <tr className="Q_br" style={{borderRadius: '5px'}}>
                                                    <th className="showDesc">Description</th>
                                                    <th>Price</th>
                                                    <th style={{width: '100px'}}>Qty</th>
                                                    <th>Amount</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                
                                                    {itemBox.map((singleItem, index) =>{
                                                        return <>
                                                        <tr className="tr_clone">
                                                            <td key={index}>
                                                                <div className="inputFields">
                                                                    <input required='' type='text' id="searchItems" name="pName" className={"inputStyles productName name"+index}  value={itemBox[index].pName} onChange={(e)=>handleItemBoxContent(e, index)} onBlur={()=>{
                                                                        setTimeout(()=>{
                                                                            setItemSuggestion([]);
                                                                        }, 100);
                                                                    }} />
                                                                    <label alt='Product Name' placeholder='Enter Product Name' className="textFields"></label>
                                                                </div>
                                                                <span id="productError" style={{color: 'red'}}></span>
                                                                {itemSuggestion.length !== 0? 
                                                                    <>  
                                                                        <div style={document.getElementsByClassName('name'+index)[0] === document.activeElement? {boxShadow: '-1px 0px 17px -1px rgba(0,0,0,0.18)', marginTop: '-15px', marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll'}:{display: 'none', boxShadow: '-1px 0px 17px -1px rgba(0,0,0,0.18)', marginTop: '-15px', marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll'} }  >
                                                                            {itemSuggestion.map((suggest)=>{
                                                                                return <div className='suggestStyle' key={suggest.id} onClick={(e)=>handleItemSingleSuggest(e, suggest.id, index)}>{suggest.item_name}</div>
                                                                            })}
                                                                        </div>
                                                                    </>
                                                                    : ""}
                                                                <div className="inputFields">
                                                                    <input required='' type='text' name="pDescription" className="inputStyles additonalnotes" value={itemBox[index].pDescription} onChange={(e)=>handleItemBoxContent(e, index)} />
                                                                    <label alt='Enter Decsription' placeholder='Enter Description' className="textFields"></label>
                                                                    
                                                                </div>
                                                            </td>                                            
                                                            <td>
                                                                <div className="inputFields">
                                                                    <input required='' type='text' id="itemPrice" name="pPrice" className="inputStyles price" value={itemBox[index].pPrice} onChange={(e)=>handleItemBoxContent(e, index)} />
                                                                    <label alt='Price' placeholder='Price' className="textFields"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="inputFields">
                                                                    <input required='' type='text' name="pQty" className="inputStyles quantity" value={itemBox[index].pQty} onChange={(e)=>handleItemBoxContent(e, index)} />
                                                                    <label alt='Qty' placeholder='Qty' className="textFields"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="inputFields">
                                                                    <input required='' type='text' name="pAmount" className="inputStyles subtotal" value={itemBox[index].pAmount} onChange={()=>handleAmount} />
                                                                    <label alt='Amount' placeholder='Amount' className="textFields"></label>
                                                                </div>
                                                            </td>
                                                            {/* <!-- <td><p> <span id="showCur"></span><input type="text" size="10" maxlength="10" name="subtotal" className="subtotal text ui-widget-content ui-corner-all" style="border:none;width: 50px;" value=" 0.00"></p></td> --> */}
                                                            <td>
                                                                {itemBox.length - 1 === index && <input type="button" name="add" value="+ New Item" className="buttonNewItem" onClick={handleCloneItem} />}
                                                            </td>
                                                            <td>
                                                                {itemBox.length > 1 && <><button className="buttonRemoveItem" onClick={(e)=>handleRemoveClone(index)} tabIndex="0" type="button" style={{cursor: 'pointer'}}>
                                                                    <span><i className="fas fa-times"></i></span> 
                                                                </button></> }
                                                                
                                                            </td>
                                                        </tr>
                                                        </>
                                                    } )}
                                                
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>				
                            <div className="row mt-3">
                                <div className="col-lg-6 margResInput">
                                    <div className="inputFields">
                                        {/* <!-- <input required='' type='text' id="note" className="inputStyles"> --> */}
                                        <label><b>Additional Details:</b></label>
                                        <textarea id="note" className="form-control" style={{border: '1px solid #f5f5fa', background: '#f5f5fa'}} value={aDetail} onChange={(e)=>setADetial(e.target.value)} ></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    
                                </div>
                                <div className="col-lg-4">
                                    <p className="r_cK"><span className="r_cL">Subtotal:</span><span className="r_cM" id="lbl3"><span id="showCur1">{currency}</span> <input type="text" placeholder="0.00" id="total" value={subTotal} readOnly /></span></p>
                                    <div className="row margResInput">
                                        <div className="col-lg-4 col-md-4" style={{paddingRight: '0px'}}>
                                            <div className="B_dr pt-3">
                                                <div className="B_ds amountPS">
                                                    <label htmlFor="invoicePercentTax-percent">
                                                        <span className="B_dv">Percent</span>
                                                        <input className="B_dt" id="invoicePercentTax-percent" type="radio" onClick={(e)=>handleTaxWithPer(e)} />
                                                        <div aria-hidden="true" className="B_dw signPerc">%</div>
                                                    </label>
                                                </div>
                                                <div className="B_ds amountVS">
                                                    <label htmlFor="invoicePercentTax-amount">
                                                        <span className="B_dv">Amount</span>
                                                        <input className="B_dt" id="invoicePercentTax-amount" type="radio" onClick={(e)=>handleTaxWithAmount(e)} />
                                                        <div aria-hidden="true" className="B_dw signDol" style={{backgroundColor: '#357abc'}}>{currency}</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4" style={{paddingLeft: '0px'}}>
                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated pt-3" htmlFor="invoicePercentTax" style={{marginLeft: '10px', marginRight: '10px', fontSize: '16px', fontWeight: '600', color: 'rgb(44, 44, 51)', width: '100px'}}>Tax</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4" style={{paddingRight: '0px'}}>
                                            <div className="inputFields">
                                                <input type="number" name="" id="taxes" className="inputStyleTaxes" value={tax} onChange={(e)=>handleTax(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row margResInput">
                                        <div className="col-lg-4 col-md-4" style={{paddingRight: '0px'}}>
                                            <div className="B_dr pt-3">
                                                <div className="B_ds amountPS1">
                                                    <label htmlFor="invoicePercentTax-percent1">
                                                        <span className="B_dv">Percent</span>
                                                        <input className="B_dt" id="invoicePercentTax-percent1" type="radio" onClick={(e)=>handlePerWithDiscount(e)} />
                                                        <div aria-hidden="true" className="B_dw signPerc1">%</div>
                                                    </label>
                                                </div>
                                                <div className="B_ds amountVS1">
                                                    <label htmlFor="invoicePercentTax-amount1">
                                                        <span className="B_dv">Amount</span>
                                                        <input className="B_dt" id="invoicePercentTax-amount1" type="radio" onClick={(e)=>handleAmountwithDiscount(e)} />
                                                        <div aria-hidden="true" className="B_dw signDol1" style={{backgroundColor: '#357abc'}}>{currency}</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4" style={{paddingLeft: '0px'}}>
                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated pt-3" htmlFor="invoicePercentTax" style={{marginLeft: '10px', marginRight: '10px', fontSize: '16px', fontWeight: '600', color: 'rgb(44, 44, 51)', width: '100px'}}>Discount</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4" style={{paddingRight: '0px'}}>
                                            <div className="inputFields">
                                                <input type="number" name="" id="discount" className="inputStyleTaxes" value={discount} onChange={(e)=>handleDiscount(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4" style={{paddingRight: '0px'}}>
                                            
                                        </div>
                                        <div className="col-lg-3 col-md-4" style={{paddingLeft: '0px'}}>
                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated pt-3" htmlFor="invoicePercentTax" style={{marginLeft: '10px', marginRight: '10px', fontSize: '16px', fontWeight: '600', color: 'rgb(44, 44, 51)', width: '100px'}}>Shipment</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4" style={{paddingRight: '0px'}}>
                                            <div className="inputFields">
                                                <input type="number" name="" id="shipment" className="inputStyleTaxes" value={shipment} onChange={(e)=>handleShipment(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="r_cK mt-3"><span className="r_cL">Balance Due</span><span className="r_cM" style={{fontSize: '1.3rem'}}><span id="showCur2">{currency}</span> <input type="text" placeholder="0.00" id="balance" value={total} readOnly /></span></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-2"> 
                    <InvoiceAction logodataurl= {logoDataurl} form = {form} apiKey = {props.apiKey} bToken = {props.bToken} invoiceType = {invoiceType} currency = {currency} logoResult = {logoName} fName = {fName} fPhone ={fPhone} fAddress = {fAddress} cName = {cName} cPhone = {cPhone} cAddress = {cAddress} invoiceNumber = {invoiceNumber} date = {date} itemBox = {itemBox} subTotal = {subTotal} tax = {tax} taxPer = {taxPer} discount = {discount} discountPer = {discountPer} total = {total} shipment = {shipment} aDetail = {aDetail} coverData = {coverData} coverSrc = {coverName} numCheck = {numCheck} />
                </div>
            </div>
        </div>
    </section>
    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><svg width="21" height="20" fill="none" viewBox="0 0 21 20"><path fill="#5096DA" d="M20.24.8a.42.42 0 00-.43-.06l-19.4 8a.42.42 0 00.01.8l5.34 2.06a.42.42 0 00.4-.04l7.08-5.04a.42.42 0 01.54.65l-5.9 5.69a.42.42 0 00-.13.3v5.68a.42.42 0 00.78.2l2.67-4.57a.21.21 0 01.29-.07l4.91 2.7a.42.42 0 00.62-.29l3.37-15.6a.43.43 0 00-.15-.4z"></path></svg> <span className="ml-3">Send Invoice</span></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <div>
                <h5 className="ml-3">To</h5>
            </div>
            <div className="col-lg-12 margResInput">
                <div className="inputFields">
                    <input required='' type='email' id="ToEmailAddress" className="inputStyles" />
                    <label alt='Email Address' placeholder='Email Address' className="textFields"></label>
                </div>
                <span id="toEmailError" style={{color: 'red'}}></span>
            </div>
            <div className="ml-3">
                <input type="checkbox" id="copy" name="copy" value="copy" />
                <label htmlFor="copy"> Send A Copy To Me</label><br />
            </div>
            {/* <?php if (!empty($paypal) || !empty($payoneer) || !empty($wise) || !empty($bank) || !empty($skrill)) { ?> */}
            <div>
                <h5 className="ml-3">Payment Method</h5>
            </div>
            {/* <?php } ?> */}
            <div className="col-lg-12 margResInput">
                {/* <?php if (!empty($paypal)) { ?> */}
                <div id="paypal1">
                    <input type="checkbox" id="paypal" name="paypal" value="paypal" />
                    <label htmlFor="Paypal"> Paypal</label><br />
                </div>
                {/* <?php } ?> */}
                {/* <?php if (!empty($payoneer)) { ?> */}
                <div id="payoneer1">
                    <input type="checkbox" id="payoneer" name="payoneer" value="payoneer" />
                    <label htmlFor="payoneer"> Payoneer</label><br />
                </div>
                {/* <?php } ?> */}
                {/* <?php if (!empty($wise)) { ?> */}
                <div id="wise1">
                    <input type="checkbox" id="wise" name="wise" value="wise" />
                    <label htmlFor="wise"> Wise</label><br />
                </div>
                {/* <?php } ?>
                <?php if (!empty($bank)) { ?> */}
                <div id="bank1">
                    <input type="checkbox" id="bank" name="bank" value="bank" />
                    <label htmlFor="bank"> Bank Account</label><br />
                </div>
                {/* <?php } ?>
                <?php if (!empty($skrill)) { ?> */}
                <div id="skrill1">
                    <input type="checkbox" id="skrill" name="skrill" value="skrill" />
                    <label htmlFor="skrill"> Skrill Account</label><br />
                </div>
                {/* <?php } ?> */}
            </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-primary sendInvoice" style={{width: '100%'}}>Send Invoice</button>
            <div className="waitingMesg" style={{display:'none', width: '100%'}}>Wait Your Invoice Is Sending <img src="<?php echo base_url(); ?>assets/ui/images/sending.gif" style={{width:'20%'}} alt="default" /></div>
        </div>
        </div>
    </div>
    </div>
    <div className="modal fade" id="errorModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">!OOPS</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <h5 className="text-center" style={{color: 'red', fontStyle: 'italic'}}>You Left Some Fields Which Are Requireds</h5>
            <img src="<?php echo base_url(); ?>assets/ui/images/error.gif" style={{width: '100%'}} alt="errorgif" />
        </div>
        </div>
    </div>
    </div>
    <div className="modal fade" id="successModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header" style={{borderBottom: '1px solid transparent !important'}}>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <h5 style={{fontStyle: 'italic', textAlign: 'center', color: 'green'}}>Your Invoice Has Been Sent</h5>
            <img src="<?php echo base_url(); ?>assets/ui/images/sent.gif" style={{width:'100%'}} alt="sent" />
        </div>
        </div>
    </div>
    </div>
    <div className="modal fade right" id="modalPoll-1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="false" style={{background: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document" style={{maxWidth: '765px'}}>
            <div className="modal-content">
                <div className="modal-header">
                    <p className="heading lead"><b>Cover Letter</b></p>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="white-text">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="md-form">
                        <CKEditor 
                          activeClass="p10"
                          content={coverData}
                          onChange = {handleCoverChange}                        
                        />
                    </div>
                </div>
                <div className="modal-footer justify-content-center">
                    <span type="button" className="btn btn-primary waves-effect waves-light addCover" id="coverLetterId" style={{color: 'white'}} onClick={handleSaveCoverData} >Add
                        <i className="fa fa-paper-plane ml-1"></i>
                    </span>
                    <button type="button" className="btn btn-outline-primary waves-effect" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade right" id="modalPoll-2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="false" style={{background: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document" style={{maxWidth: '765px'}}>
            <div className="modal-content">
                <div className="modal-header">
                    <p className="heading lead"><b>Cover Letter</b></p>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="white-text">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="md-form" dangerouslySetInnerHTML={{__html: coverData}}>
                        
                    </div>
                </div> 
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-outline-primary waves-effect" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade right" id="modalPoll-3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="false" style={{background: 'rgba(0, 0, 0, 0.6)'}}>
        <div className="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document" style={{maxWidth: '765px'}}>
            <div className="modal-content">
                <div className="modal-header">
                    <p className="heading lead"><b>Upload Letter</b></p>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="white-text">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="md-form">
                        <input type="file" id="sortpicture" name="file" onChange={(e)=>setCoverFile(e.target.files[0])} />
                    </div>
                </div> 
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-primary waves-effect waves-light addCover" id="coverLetterUpload" onClick={handleCoverUpload} style={{color: 'white'}}>Upload
                        <i className="fa fa-paper-plane ml-1"></i>
                    </button>
                    <button type="button" className="btn btn-outline-primary waves-effect" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    
    <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
draggable
 />
    </>
  )
}
