import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import {useNavigate} from "react-router-dom";
export default function Edit(props) {
    const [item_name, setItem_Name] = useState('');
    const [item_price, setItem_Price] = useState('');
    const [progress, setProgress] = useState(0);
    const [msg, setMsg] = useState(201);
    const navigate = useNavigate();
    // get item data
    const getData = async (apiKey, bToken) => {
        setProgress(40);
        const token = bToken; 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}edit_item/${localStorage.getItem("edit_item_id")}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        let data = apiResult.data.item;
        setItem_Name(data.item_name);
        setItem_Price(data.item_price);
        setProgress(100);
    }
    useEffect(()=>{
        getData(props.apiKey, props.bToken);
    }, [props.apiKey, props.bToken]);


    const handleItemUpdate = async (event) =>{
        event.preventDefault();
        const token = props.bToken;
        const apiResult = await axios({
            method: 'post',
            url: `${props.apiKey}update_item `,
            data: {
                item_name, item_price , id: JSON.parse(localStorage.getItem('edit_item_id'))
            },
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        setMsg(apiResult.data.status);
  }
  useEffect(()=>{
    if(msg === 200){
      localStorage.setItem('item_update_message', JSON.stringify(msg));
      navigate('/dashboard/item-list');
    }
  }, [msg, navigate]);
  props.ptitle("Edit Item");
  useEffect(()=>{
    props.urlfun();
}, [props]);
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
        {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Add Item</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Add Item</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Item Name</strong></label>
                      <input type="text" placeholder="Item Name" name="name" className="form-control" onChange={(e)=>setItem_Name(e.target.value)} value={item_name} />
                    </div>
                    <div className="form-group">
                      <label><strong>Item Price</strong></label>
                      <input type="number" placeholder="Item Price" name="price" className="form-control" onChange={(e)=>setItem_Price(e.target.value)} value={item_price} />
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Update" className="btn btn-primary" onClick={handleItemUpdate}/>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
