import React from 'react';
import bgImg from '../../../images/bg.jpg'
export default function Template2(props) {
  return (
    <>
        <section className="sectionInvoice3" style={{backgroundImage: `url('${bgImg}')`, pageBreakAfter: 'always', marginLeft: '0px', width: '580px', height: 'auto'}}>
            <div className="container resContainer" id="content">
                <div className="row">
                    <div className="col-4 col-lg-4 col-md-5">
                        <div className="invoiceLeftHalBg3 pt-5">
                            <div className="pb-5" style={{width: '150px', height: '80px'}}>
                                {/* <img src={props.invoiceData.logo} className="logoImgInvoice2 ml-5" alt="logo" /> */}
                            </div>
                            <div>
                                <h5 className="infoInvoice2 pt-5 ml-5" style={{color: props.invoiceData.color, fontSize: '15px'}}>From:</h5>
                                <h5 className="invoiceHeading3 ml-5" style={{fontSize: '14px'}}>{props.invoiceData.fName}</h5>
                                <h6 className="invoiceHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize: '14px'}}>{props.invoiceData.fAddress}</h6>
                                <h6 className="invoiceHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize: '14px'}}><span className="resPhoneHide">Phone: </span>{props.invoiceData.fPhone}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 col-lg-8 col-md-7">
                        <div className="pt-5">
                            <h1 className="textHeading3 text-right" style={{color: props.invoiceData.color, fontSize: '25px'}}>{props.invoiceData.invoiceType}</h1>
                        </div>
                        <div className="row">
                            <div className="col-6 col-lg-6">
                                
                            </div>
                            <div className="col-6 col-lg-6">
                                <div className="d-flex">
                                    <div style={{width: '50%'}}>
                                        <h6 className="invNumber3" style={{fontSize: '12px'}}>{props.invoiceData.invoiceType} Number:</h6>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <h6 className="invNumber3 text-right" style={{fontSize: '12px'}}>#{props.invoiceData.invoiceNumber}</h6>
                                    </div>
                                </div>
                                <div className="d-flex pb-2">
                                    <div style={{width: '50%'}}>
                                        <h6 className="invNumber3" style={{fontSize: '12px'}}>Due Date:</h6>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <h6 className="invNumber3 text-right" style={{fontSize: '12px'}}>{props.invoiceData.dDate}</h6>
                                    </div>
                                </div>
                                {/* <?php } ?> */}
                                <div className="d-flex pb-2" style={{borderBottom: '4px solid'+props.invoiceData.color}}>
                                    <div style={{width: '50%'}}>
                                        <h6 className="invNumber3" style={{fontSize: '12px'}}>{props.invoiceData.invoiceType} Date:</h6>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <h6 className="invNumber3 text-right" style={{fontSize: '12px'}}>{props.invoiceData.cDate}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-lg-8">
                                <h5 className="infoInvoice2 ml-5" style={{color: props.invoiceData.color, fontSize: '15px' }}>{props.invoiceData.invoiceType} To:</h5>
                                <h5 className="invoiceToHeading3 ml-5" style={{fontSize:'14px'}}>{props.invoiceData.cName}</h5>
                                <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize:'14px'}}>{props.invoiceData.cAddress}</h6>
                                <h6 className="invoiceToHeading3 ml-5 dateIssue addressInvoice3" style={{fontSize:'14px'}}>{props.invoiceData.cPhone}</h6>
                            </div>
                            <div className="col-4 col-lg-4">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 col-lg-12">
                        <div>
                            <table className="table table-responsive tableRes3">
                                <thead className="tableHeadingBg" style={{background: props.invoiceData.color, fontSize: '12px', padding: '5px'}}>
                                    <tr>
                                        <th style={{padding: '5px'}}>Item</th>
                                        <th style={{padding: '5px'}}>Item Description</th>
                                        <th style={{padding: '5px'}}>Item Qty</th>
                                        <th style={{padding: '5px'}}>Item Unit Price</th>
                                        <th style={{padding: '5px'}}>Total</th>
                                    </tr>
                                </thead>
                                <tbody className="tbodyMarg" style={{fontSize: '12px', padding: '5px'}}>
                                    {/* <?php foreach ($invoiceL as $inv) { ?> */}
                                    {props.invoiceData.item.map((element)=>{
                                        return <tr key={element.id}>
                                                <td style={{padding: '5px'}}>{element.pName}</td>
                                                <td style={{padding: '5px'}}>{element.pDescription}</td>
                                                <td style={{padding: '5px'}}>{element.pQty}</td>
                                                <td style={{padding: '5px'}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                <td style={{padding: '5px'}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                            </tr>
                                    })}
                                    {/* <?php } ?> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-5 col-lg-5 col-md-5 paymentTypes">
                        
                        <div>
                            <h5 className="infoInvoice2 pt-5 pb-2 ml-5 text-capitalize" style={{color: props.invoiceData.color, fontSize: '14px'}}>Thank you very much</h5>
                            <p className="infoInvoice2 iconsSocial2 pt-3 text-left ml-5">
                                {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>}
                                {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                    <i className="fab fa-twitter-square ml-2"></i>
                                </a>}
                                {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                </a>}
                            </p>
                        </div>
                    </div>
                    <div className="col-7 col-lg-7 col-md-7">
                        <div className="row pt-4">
                            <div className="col-6 col-lg-6 col-md-6">
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>SubTotal:</p>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6">
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>{props.invoiceData.currencey} {props.invoiceData.subTotal}</p>
                            </div>
                        </div>
                        {props.invoiceData.discount && <div className="row">
                            <div className="col-6 col-lg-6 col-md-6">
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>Discount:</p>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6">
                                
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>
                                    {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                </p>
                            
                                
                            </div>
                        </div>}
                        
                        {props.invoiceData.tax && <div className="row">
                            <div className="col-6 col-lg-6 col-md-6">
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>Tax:</p>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6">
                                
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>
                                    {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                </p>
                                
                                
                            </div>
                        </div>}
                        
                        {props.invoiceData.shipment && <div className="row">
                            <div className="col-6 col-lg-6 col-md-6">
                                <p className="text-right priceSub3" style={{fontSize: '14px'}}>Shipping:</p>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6" style={{fontSize: '14px'}}>
                                
                                <p className="text-right priceSub3">{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                
                                
                            </div>
                        </div>}
                        
                        <div className="invoiceFooter3 pt-2" style={{backgroundColor: props.invoiceData.color, width: '70%', marginLeft: '30%' }}>
                            <div className="row">
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-left priceBalance3 mb-2 pl-2" style={{fontSize: '14px'}}><b>Balance Due:</b></p>
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                    <p className="text-right pr-2 priceBalance3 mb-2" style={{fontSize: '14px'}}><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-5">
                            <div className="row">
                                <div className="col-6 col-lg-6 col-md-6">
                                {props.invoiceData.aDetail? <>
                                <h5 className="terms3Invoice" style={{fontSize: '14px'}}>Additional Details:</h5>
                                    <p className="terms3InvoicePara" style={{fontSize: '14px'}}>{props.invoiceData.aDetail}</p>
                                    </>:""}
                                </div>
                                <div className="col-6 col-lg-6 col-md-6">
                                    {props.invoiceData.terms?
                                    <>
                                        <h5 className="terms3Invoice" style={{fontSize: '14px'}}>Terms & Conditions</h5>
                                        <small className="terms3InvoicePara" style={{fontSize: '14px'}}>{typeof props.invoiceData.terms}</small>
                                    </>:""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
