import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {useNavigate} from "react-router-dom";
export default function Add(props) {
  const [countryList, setCountry] = useState([]);
  const [name, setName] = useState('');
  const [mobile_number, setMobileNumber] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const[country, setCountryId] = useState('170');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  // const [email, setEmail] = useState('');
  props.ptitle("Add Client");
  useEffect(()=>{
    props.urlfun();
  }, [props]);

// Fetch Countries
const countries = async (apiKey, bToken) =>{
    const token = bToken; 
    const apiResult = await axios({
        method: 'get',
        url: `${apiKey}fetch_countries`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    setCountry(apiResult.data.countries);
  };
useEffect(()=>{
  countries(props.apiKey, props.bToken);
}, [props.apiKey, props.bToken]);

// Save Client
const handleSaveClient = async (event) =>{
  event.preventDefault();
  const token = props.bToken;
  const apiResult = await axios({
    method: 'post',
    url: `${props.apiKey}create_clients`,
    data: {
        name, mobile_number, country, address, city, street, email: JSON.parse(localStorage.getItem('user_email'))
    },
    headers:{
        Accept: 'application/javascript',
        Authorization: `Bearer ${token}`
    }
  });
  setMsg(apiResult.status);
}
useEffect(()=>{
  if(msg === 200){
    localStorage.setItem('client_add_message', JSON.stringify(msg));
    navigate('/dashboard/client-list');
  }
}, [msg, navigate]);
  return (
    <>
        
      {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">Add Client</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Add Client</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Name</strong></label>
                      <input type="text" placeholder="Name" onChange={(e)=>setName(e.target.value)} name="name" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Mobile Number</strong></label>
                      <input type="text" placeholder="Mobile Number" onChange={(e)=>setMobileNumber(e.target.value)} name="mobile_number" className="form-control" />
                    </div>
                    
                    <div className="form-group">
                      <label><strong>City</strong></label>
                      <input type="text" placeholder="City" onChange={(e)=>setCity(e.target.value)} name="city" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Street Address</strong></label>
                      <input type="text" placeholder="Street Address" onChange={(e)=>setStreet(e.target.value)} name="street" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label><strong>Country</strong></label>
                      <select className="form-control" style={{cursor: 'pointer'}} name="country" value={country} onChange={(e)=>setCountryId(e.target.value)}>
                        { countryList.map((country)=>{
                          return <option key={country.id} value={country.id} >{country.country_name}</option>
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label><strong>Full Address</strong></label>
                      <input type="text" placeholder="Address" onChange={(e)=>setAddress(e.target.value)} name="address" className="form-control" />
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Save" className="btn btn-primary" onClick={handleSaveClient} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
