import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import Spinner from '../Spinner';
export default function Tblogs(props) {
    props.ptitle('Blogs');
    const [blog, setBlog] = useState([]);
    const [imgUrl, setImgUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        props.urlfun();
    }, [props])
    const getBlogs = async (apiKey, bToken) =>{
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}fetch_template_blogs`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        setBlog(apiResult.data.source);
        setImgUrl(apiResult.data.src);
        setLoading(false);
    }
    useEffect(()=>{
        getBlogs(props.apiKey, props.bToken);
    }, [props]);
    var countVar = 0;
  return (
    <>
    <section className="mainSectonTwo">
            <div className="container">
                {loading === true ? <Spinner /> : ''}
                <div className="py-5">
                    {blog.map((blog)=>{
                        countVar++;
                        return <div key={blog.id}>
                            {

                                countVar%2===0 ? 
                                <>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <img alt="" className="lazy w_c4" src={imgUrl+blog.blog_image} title="pricing page invoice" />
                                        </div>
                                        <div className="col-lg-1">
                                            
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="w_c pt-5 ml-3">
                                                <h1 className="w_d">
                                                    <span>{blog.blog_title}</span>
                                                </h1>
                                                <p className="w_c3">{blog.blog_short_description}</p>
                                                <div className="w_l">
                                                <Link to={"/template-blog/"+blog.slug} rel="" className="n_bbb">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>                        
                                : <>
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <div className="w_c pt-5 ml-3">
                                                <h1 className="w_d">
                                                    <span>{blog.blog_title}</span>
                                                </h1>
                                                <p className="w_c3">{blog.blog_short_description}</p>
                                                <div className="w_l">
                                                <Link to={"/template-blog/"+blog.slug} rel="" className="n_bbb">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1">
                                            
                                        </div>
                                        <div className="col-lg-6">
                                            <img alt="" className="lazy w_c4" src={imgUrl+blog.blog_image} title="pricing page invoice" />
                                        </div>
                                    </div>
                                </>

                            }


                        </div>
                    })}      
                </div>
            </div>
        </section>

    </>
  )
}
