import React from 'react'

export default function Template4(props) {
  return (
    <>
        <section id="invoice">
            {/* <?php if (!empty($posts->cover_letter)) { ?> */}
            {/* <div class="container">
                <div class="invoice overflow-auto">
                    <div style={{minWidth: '600px'}}>
                        <header style={{borderBottom: '1px solid red' }}>
                            <div class="row">
                                <div class="col">
                                    <?php if (!empty($posts->logo_image)) { ?>
                                    <a target="_blank" href="/">
                                        <img src="<?php echo base_url('uploads/'.$posts->logo_image); ?>" data-holder-rendered="true" style={{width: '150px', height: '145px', objectFit: 'contain'}} alt="logo" />
                                        </a>
                                    <?php } ?>
                                </div>
                                <div class="col company-details">
                                    <h2 class="name">
                                        <a target="_blank" href="/" style={{color: 'red'}}>
                                        From Name
                                        </a>
                                    </h2>
                                    <div>From Address</div>
                                    <div>From Num</div>
                                    <p class="infoInvoice2 iconsSocial2 pt-3">
                                        <?php if(!empty($fb)){ ?>
                                        <a href="<?php echo $fb; ?>" class="iconsSocial2" style={{color: 'red'}} target="_blank">
                                            <i class="fab fa-facebook"></i>
                                        </a>
                                         <?php } ?>
                                        <?php if(!empty($twitter)){ ?>
                                        <a href="<?php echo $twitter; ?>" class="iconsSocial2" style={{color: 'red' }} target="_blank">
                                            <i class="fab fa-twitter-square ml-2"></i>
                                        </a>
                                         <?php } ?>
                                        <?php if(!empty($insta)){ ?>
                                        <a href="<?php echo $insta; ?>" class="iconsSocial2" style={{color: 'red'}} target="_blank"><i class="fab fa-instagram-square ml-2"></i>
                                        </a>
                                         <?php } ?>
                                    </p>
                                </div>
                            </div>
                        </header>
                        <main>
                            <div style={{width: '95%', margin: 'auto'}}>
                                Cover Letter
                            </div>
                        </main>
                        <footer>
                            <?php if (!empty($terms)) {?>
                            Terms and Condition
                             <?php } ?>
                        </footer>
                    </div>
                    <div></div>
                </div>
            </div> */}
            {/* <?php } ?> */}
            <div class="container">
                <div class="invoice overflow-auto">
                    <div style={{minWidth: '600px'}}>
                        <header style={{borderBottom: '1px solid'+props.invoiceData.color}}>
                            <div class="row">
                                <div class="col">
                                    <a target="_blank" href="/">
                                        <img src={props.invoiceData.logo} data-holder-rendered="true" style={{width: '150px', height: '145px', objectFit: 'contain'}} alt="logo" />
                                        </a>
                                </div>
                                <div class="col company-details">
                                    <h2 class="name">
                                        <a target="_blank" href="/" style={{color: props.invoiceData.color}}>
                                            {props.invoiceData.fName}
                                        </a>
                                    </h2>
                                    <div>{props.invoiceData.fAddress}</div>
                                    <div>{props.invoiceData.fPhone}</div>
                                    <p class="infoInvoice2 iconsSocial2 pt-3">
                                        {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-facebook"></i>
                                        </a>}
                                        {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter-square ml-2"></i>
                                        </a>}
                                        {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                        </a>}
                                    </p>
                                </div>
                            </div>
                        </header>
                        <main>
                            <div class="row contacts">
                                <div class="col invoice-to">
                                    <div class="text-gray-light"><span style={{textTransform: 'uppercase'}}>{props.invoiceData.invoiceType}</span> TO:</div>
                                    <h2 class="to">{props.invoiceData.cName}</h2>
                                    <div class="address">{props.invoiceData.cAddress}</div>
                                    <div class="email"><a href="/">{props.invoiceData.cPhone}</a></div>
                                </div>
                                <div class="col invoice-details">
                                    <h3 class="invoice-id" style={{color: props.invoiceData.color}}><span style={{textTransform: 'uppercase'}}>{props.invoiceData.invoiceType}</span>-{props.invoiceData.invoiceNumber}</h3>
                                    <div class="date">Date: {props.invoiceData.cDate}</div>
                                    {/* <?php if ($posts->invoice_type == "Invoice") { ?> */}
                                    <div class="date">Due Date: {props.invoiceData.dDate}</div>
                                    {/* <?php } ?> */}
                                </div>
                            </div>
                            <table border="0" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th class="text-left">ITEM DESCRIPTION</th>
                                        <th class="text-right">PRICE</th>
                                        <th class="text-right">QTY</th>
                                        <th class="text-right">TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.invoiceData.item.map((element, index)=>{
                                        return <tr>
                                                <td class="no" style={{background: props.invoiceData.color }}>{index+1}</td>
                                                <td class="text-left"><h3 style={{color: props.invoiceData.color}}>{element.pName}</h3>{element.pDescription}
                                                </td>
                                                <td class="unit">{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                <td class="qty">{element.pQty}</td>
                                                <td class="total" style={{background: props.invoiceData.color}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                            </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">SUBTOTAL</td>
                                        <td>{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</td>
                                    </tr>
                                    {props.invoiceData.discount && <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">DISCOUNT</td>
                                        <td>
                                        {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                        </td>
                                    </tr>}
                                    {props.invoiceData.tax && <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">TAX</td>
                                        <td>
                                            {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                        </td>
                                    </tr>}
                                    {props.invoiceData.shipment && <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">SHIPMENT</td>
                                        <td>{props.invoiceData.currencey} {props.invoiceData.shipment}</td>
                                    </tr>}
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2" style={{borderTop: '1px solid'+props.invoiceData.color, color: props.invoiceData.color }}>GRAND TOTAL</td>
                                        <td style={{borderTop: '1px solid'+props.invoiceData.color, color: props.invoiceData.color}}>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            
                            <div class="thanks">Thank you!</div>
                            {props.invoiceData.aDetail && <div class="notices" style={{borderLeft: '6px solid'+props.invoiceData.color}}>
                                <div>ADDITIONAL DETAILS:</div>
                                <div class="notice">{props.invoiceData.aDetail}</div>
                            </div>}
                            
                            
                        </main>
                        <footer>
                            {props.invoiceData.terms && <div>{props.invoiceData.terms}</div>}
                                
                        </footer>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    </>
  )
}
