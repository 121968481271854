import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {useNavigate} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
export default function List(props) {
  props.ptitle("All Clients");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const [newClient, setNewClient] = useState(201);
  const [updateClient, setUpdateClient] = useState(201);
  const [search, setSearch] = useState('');
  const [client, setClient] = useState([]);
  const [fiteredClient, setFiteredClient] = useState([]);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  useEffect(()=>{
    if(newClient === 200){
        toast.success("Client Resgisterd Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      localStorage.setItem('client_add_message', JSON.stringify(201));
    }
    else{
      setNewClient(JSON.parse(localStorage.getItem('client_add_message')));
    }
  }, [newClient]);
  useEffect(()=>{
    if(updateClient === 200){
      toast.success("Client Updated Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
      localStorage.setItem("client_update_message", JSON.stringify(201));
    }
    else{
      setUpdateClient(JSON.parse(localStorage.getItem("client_update_message")));
    }
  }, [updateClient]);
  // get all Data
  const getDataFunc = async (apiKey, bToken) => {
    setProgress(20);
    const token = bToken; 
    const apiResult = await axios({
      method: 'get',
      url: `${apiKey}fetch_clients/${localStorage.getItem('user_id')}`,
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    setFiteredClient(apiResult.data.source);
    setClient(apiResult.data.source);
    setProgress(100);
  }

  const column = [
    {
      name: "Sr",
      cell: (row, index) => index + 1,
    },
    {
      name: "Client Name",
      selector: (row)=>row.name,
      sortable: true
    },
    {
      name: "Client Number",
      selector: (row)=>row.mobile_number,
      sortable: true
    },
    {
      name: "Client Address",
      selector: (row)=>row.address,
      sortable: true
    },
    {
      name: "Client City",
      selector: (row)=>row.city,
      sortable: true
    },
    {
      name: "Aciton",
      cell: row=> <>
      <Link to={'../Send-Invoice/'+row.id} className="btn btn-primary mr-2">Send Invoice</Link>
      <span className="btn btn-primary mr-2" onClick={()=> handleEditClient(row.id)}>Edit</span>
      <span className="btn btn-danger" onClick={()=> handleDeleteClient(row.id)}>Delete</span></>
    }
  ]

  useEffect(()=>{
    getDataFunc(props.apiKey, props.bToken);
  }, [props.apiKey, props.bToken]);

  useEffect(()=>{
    const result = client.filter((client)=>{
      return client.name.toLowerCase().match(search.toLowerCase());
    });
    setFiteredClient(result);
  }, [search, client]);

  const handleEditClient = (client_id) =>{
    localStorage.setItem("edit_client_id", JSON.stringify(client_id));
    navigate("/dashboard/edit-client");
  }
  const handleDeleteClient = async (client_id) => {
    const token = props.bToken; 
    const apiResult = await axios({
        method: 'get',
        url: `${props.apiKey}delete_client/${client_id}`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.status === 200){
      getDataFunc(props.apiKey, props.bToken);
      toast.success("Client Deleted Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }
  }
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">All Clients</li>
          </ul>
        </div>
      </div>
      <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4>All Clients</h4>
                  </div>
                  <div>
                    <Link to="/dashboard/add-client" type="button" className="btn btn-primary">Add Client</Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable 
                  columns={column} 
                  data={fiteredClient} 
                  pagination
                  fixedHeader
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                  }
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
