import React from 'react'

export default function Template0(props) {
  return (
    <>
    {/* Cover Letter */}
        <div className="invoicebg py-3">
	{/* <?php if (!empty($posts->cover_letter)) { ?> */}
	{/* <div className="container" style={{pageBreakAfter: 'always', width: '100%', height: '100%'}}>
		<div className="row mb-5 py-3" style={{backgroundColor: '#f5f5fa', borderTop: '20px solid black'}}>
			<div className="col-lg-6" >
				<div className="d-flex"> 
					<?php if (!empty($posts->logo_image)) { ?>
					<div>
						<img src="<?php echo base_url('uploads/'.$posts->logo_image); ?>" className="logoImgInvoice" alt='Logo' />
					</div>
					<?php } ?>
					<div className="ml-3">
						<h5 className="invoiceFromHeading1">From Name</h5>
						<h6 className="invoiceFromAddHeading1">From Address</h6>
						<h6 className="invoiceFromAddHeading1">From Number</h6>
					</div>
				</div>
			</div>
			<div className="col-lg-6">
				<div className="pt-4">
					<h6 className="text-right invoiceFromAddHeading1">Date: <span className="ml-3 dateIssue" >Date</span></h6>
					 <?php if ($posts->invoice_type == "Invoice") { ?>
						<h6 className="text-right invoiceFromAddHeading1">Due Date: <span className="ml-3 dateIssue" >Due Date</span></h6>
						 <?php } ?> 
				</div>
			</div>
		</div>
		<div className="row pt-4">
			
			<div className="col-lg-12">
				<div>
					Cover Letter
				</div>
			</div>
		</div>
	</div> */}

    {/* Invoice Template */}
	{/* <?php } ?> */}
	<div className="container in" style={{pageBreakAfter: 'always', width: '100%', height: '100%'}}>
		<div className="row mb-5 py-3" style={{backgroundColor: '#f5f5fa', borderTop: '20px solid'+props.invoiceData.color}}>
			<div className="col-lg-6" >
				<div className="d-flex"> 
					<div>
						<img src={props.invoiceData.logo} className="logoImgInvoice" alt="logo" />
					</div>
					{/* <?php } ?> */}
					<div className="ml-3">
						<h5 className="invoiceFromHeading1">From {props.invoiceData.fName}</h5>
						<h6 className="invoiceFromAddHeading1">{props.invoiceData.fAddress}</h6>
						<h6 className="invoiceFromAddHeading1">{props.invoiceData.fPhone}</h6>
					</div>
				</div>
			</div>
			<div className="col-lg-6">
				<div className="pt-4">
					<h1 className="text-right mb-0 invoiceType1Res">{props.invoiceData.invoiceType}</h1>
					<p className="text-right invoiceType1Res1 mb-0">#{props.invoiceData.invoiceNumber}</p>
					<p className="infoInvoice2 iconsSocial2 pt-3 text-right">
						{props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
							<i className="fab fa-facebook"></i>
						</a>}
						{props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
							<i className="fab fa-twitter-square ml-2"></i>
						</a>}
						{props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
						</a>}
					</p>
				</div>
			</div>
		</div>
		<div className="row pt-3">
			<div className="col-lg-6">
				<div>
					<h6 className="invoiceFromHeading1">Bill to:</h6>
					<p className="mb-0 invoiceFromAddHeading1">{props.invoiceData.cName}</p>
					<p className="mb-0 invoiceFromAddHeading1">{props.invoiceData.cAddress}</p>
					<p className="invoiceFromAddHeading1">{props.invoiceData.cPhone}</p>
				</div>
			</div>
			<div className="col-lg-6">
				<div className="pt-4">
					<h6 className="text-right invoiceFromAddHeading1">Date: <span className="ml-3 dateIssue" >{props.invoiceData.cDate}</span></h6>
					{/* <?php if ($posts->invoice_type == "Invoice") { ?> */}
						<h6 className="text-right invoiceFromAddHeading1">Due Date: <span className="ml-3 dateIssue" >{props.invoiceData.dDate}</span></h6>
						{/* <?php } ?> */}
				</div>
			</div>
		</div>
		<div className="row pt-5">
			<div className="col-lg-12">
				<div>
					<table className="table table-responsive tableRes table-striped table-bordered">
						<thead className="tableHeadingBg" style={{background: props.invoiceData.color}}>
							<tr>
								<th style={{textAlign: 'left'}}>Item</th>
								<th style={{textAlign: 'left'}}>Item Description</th>
								<th>Qty</th>
								<th style={{textAlign: 'right'}}>Price</th>
								<th style={{textAlign: 'right'}}>Total</th>
							</tr>
						</thead>
						<tbody>
							{props.invoiceData.item.map((element, index)=>{
								return <tr key={index}>
										<td style={{textAlign: 'left', fontWeight: '600'}}>{element.pName}									
										</td>
										<td style={{textAlign: 'left'}}>{element.pDescription}</td>
										<td>{element.pQty}</td>
										<td style={{textAlign: 'right'}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
										<td style={{textAlign: 'right'}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
									</tr>
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div className="row pt-4 pb-3" style={{borderBottom: '20px solid'+props.invoiceData.color}}>
			<div className="col-lg-6">

				{props.invoiceData.aDetail && <div className="ml-5">
					<h6>Additional Details:</h6>
					<p className="mb-0 text-justify">{props.invoiceData.aDetail}</p>
				</div>}
				
				{props.invoiceData.terms && <div className="ml-5 mt-5">
					<h6>Terms And Conditions</h6>
					<small className="mb-0 text-justify">{props.invoiceData.terms}</small>
				</div>}
			</div>
			<div className="col-lg-6">
				<div className="row pt-4">
					<div className="col-lg-6 col-md-6">
						<p className="text-right priceSub">SubTotal</p>
					</div>
					<div className="col-lg-6 col-md-6">
						<p className="text-center priceSub">{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
					</div>
				</div>
				{props.invoiceData.discount && <div className="row">
					<div className="col-lg-6 col-md-6">
						<p className="text-right priceSub">Discount</p>
					</div>
					<div className="col-lg-6 col-md-6">
						
						<p className="text-center priceSub">
							{props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                        </p>
						
					</div>
				</div>}
				
				{props.invoiceData.tax && <div className="row">
					<div className="col-lg-6 col-md-6">
						<p className="text-right priceSub">Tax</p>
					</div>
					<div className="col-lg-6 col-md-6">
						
						<p className="text-center priceSub">
							{props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                        </p>
						
					</div>
				</div>
				}
				
				{props.invoiceData.shipment && <div className="row">
					<div className="col-lg-6 col-md-6">

						<p className="text-right priceSub">Shipping</p>
					</div>
					<div className="col-lg-6 col-md-6">
						
						<p className="text-center priceSub">{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
						
					</div>
				</div>}
				<div className="row">
					<div className="col-lg-6 col-md-6">
						<p className="text-right priceBalance"><b>Balance Due</b></p>
					</div>
					<div className="col-lg-6 col-md-6">
						<p className="text-center priceBalance"><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
    </>
  )
}
