import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import DataTable from 'react-data-table-component';
export default function List(props) {
  props.ptitle("All Request Templates");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [progress, setProgress] = useState(0);
  const [invoice, setInvoice] = useState([]);
  const [fiteredInvoice, setFiteredInvoice] = useState([]);
  // get all Data
  const getDataFunc = async (apiKey, bToken) => {
    setProgress(20);
    const token = bToken; 
    const apiResult = await axios({
      method: 'get',
      url: `${apiKey}fetch_template_requests/${localStorage.getItem('user_id')}`,
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    setProgress(100);
    setFiteredInvoice(apiResult.data.source);
    setInvoice(apiResult.data.source);
  }

  const column = [
    {
      name: "Sr",
      width: '80px',
      cell: (row, index) => index + 1,
    },
    {
      name: "File",
      width: '200px',
      selector: (row)=>row.file,
      sortable: true
    },
    {
      name: "From Email",
      width: '280px',
      selector: (row)=>row.email_fk,
      sortable: true
    },
    {
      name: "Status",
      width: '200px',
      selector: (row)=>row.status,
      sortable: true
    },
    {
      name: "Reason",
      selector: (row)=>row.reason,
      width: '350px',
      sortable: true
    },
    {
      name: "Action",
      cell: row=> <>
        <button className="btn btn-primary" onClick={(e)=>viewDocument(e, row.id)}>
            View
        </button>
      </>
    }
  ]

  const viewDocument = (e, id) =>{
    navigate(`/dashboard/view-response/${id}`);
  }

  useEffect(()=>{
    getDataFunc(props.apiKey, props.bToken)
  }, [props.apiKey, props.bToken]);
  useEffect(()=>{
    const result = invoice.filter((invoice)=>{
      return invoice.file.toLowerCase().match(search.toLowerCase());
    });
    setFiteredInvoice(result);
  }, [search, invoice]);
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">All Request Templates</li>
          </ul>
        </div>
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <DataTable 
                    columns={column} 
                    data={fiteredInvoice} 
                    pagination
                    fixedHeader
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                      <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                    }
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
