import React from 'react'

export default function Template0(props) {
  return (
    <>
         <div>
            <div style={{pageBreakAfter: 'always', marginLeft: '0px', width: '580px', height: '100%'}}>
                <div className="row mb-5 py-3 pl-2" style={{backgroundColor: '#f5f5fa', borderTop: '20px solid'+props.invoiceData.color}}>
                    <div className="col-lg-6 col-6" >
                        <div className="d-flex"> 
                            <div style={{width: '150px', height: '100px'}}>
                                {/* <img id="logoimg" src={props.invoiceData.logo} style={{width: '150px', height: '100px', objectFit: 'contain',display: 'none' }} alt="logo" /> */}
                            </div>
                            {/* <?php } ?> */}
                            <div className="ml-3">
                                <h5 className="invoiceFromHeading1" style={{fontSize: '17px'}}>From {props.invoiceData.fName}</h5>
                                <h6 className="invoiceFromAddHeading1" style={{fontSize: '14px'}}>{props.invoiceData.fAddress}</h6>
                                <h6 className="invoiceFromAddHeading1" style={{fontSize: '14px'}}>{props.invoiceData.fPhone}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-6">
                        <div className="pt-4">
                            <h1 className="text-right mb-0 invoiceType1Res" style={{fontSize: '15px'}}>{props.invoiceData.invoiceType}</h1>
                            <p className="text-right invoiceType1Res1 mb-0" style={{fontSize: '14px'}}>#{props.invoiceData.invoiceNumber}</p>
                            <p className="infoInvoice2 iconsSocial2 pt-3 text-right">
                                {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>}
                                {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                    <i className="fab fa-twitter-square ml-2"></i>
                                </a>}
                                {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                </a>}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 pl-2">
                    <div className="col-lg-6 col-6">
                        <div>
                            <h6 className="invoiceFromHeading1" style={{fontSize: '15px'}}>Bill to:</h6>
                            <p className="mb-0 invoiceFromAddHeading1"style={{fontSize: '12px'}}>{props.invoiceData.cName}</p>
                            <p className="mb-0 invoiceFromAddHeading1"style={{fontSize: '12px'}}>{props.invoiceData.cAddress}</p>
                            <p className="invoiceFromAddHeading1"style={{fontSize: '12px'}}>{props.invoiceData.cPhone}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-6">
                        <div className="pt-4">
                            <h6 className="text-right invoiceFromAddHeading1" style={{fontSize: '13px'}}>Date: <span className="ml-3 dateIssue" style={{fontSize: '12px'}}>{props.invoiceData.cDate}</span></h6>
                            {/* <?php if ($posts->invoice_type == "Invoice") { ?> */}
                                <h6 className="text-right invoiceFromAddHeading1" style={{fontSize: '13px'}}>Due Date: <span className="ml-3 dateIssue" style={{fontSize: '12px'}}>{props.invoiceData.dDate}</span></h6>
                                {/* <?php } ?> */}
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-lg-12">
                        <div>
                            <table className="table table-responsive tableRes table-bordered">
                                <thead className="" style={{background: props.invoiceData.color, padding: '5px', borderBottom: '1px solid #dee2e6',}}>
                                    <tr>
                                        <th style={{textAlign: 'left', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>Item</th>
                                        <th style={{textAlign: 'left', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>Item Description</th>
                                        <th style={{fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>Qty</th>
                                        <th style={{textAlign: 'right', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>Price</th>
                                        <th style={{textAlign: 'right', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>Total</th>
                                    </tr>
                                </thead>
                                <tbody style={{padding: '5px', borderBottom: '1px solid #dee2e6',}}>
                                    {props.invoiceData.item.map((element, index)=>{
                                        return <tr key={index}>
                                                <td style={{textAlign: 'left', fontWeight: '600', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>{element.pName}									
                                                </td>
                                                <td style={{textAlign: 'left', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>{element.pDescription}</td>
                                                <td style={{fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>{element.pQty}</td>
                                                <td style={{textAlign: 'right', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                <td style={{textAlign: 'right', fontSize: '12px', padding: '5px', borderBottom: '1px solid #dee2e6',}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row pt-4 pb-3" style={{ borderBottom: '20px solid'+props.invoiceData.color}}>
                    <div className="col-lg-6 col-6">

                        {props.invoiceData.aDetail && <div className="ml-5">
                            <h6>Additional Details:</h6>
                            <p className="mb-0 text-justify">{props.invoiceData.aDetail}</p>
                        </div>}
                        
                        {props.invoiceData.terms && <div className="ml-5 mt-5">
                            <h6>Terms And Conditions</h6>
                            <small className="mb-0 text-justify">{props.invoiceData.terms}</small>
                        </div>}
                    </div>
                    <div className="col-lg-6 col-6">
                        <div className="row pt-4" style={{marginBottom: '-20px'}}>
                            <div className="col-lg-6 col-6 col-md-6">
                                <p className="text-left priceSub">SubTotal</p>
                            </div>
                            <div className="col-lg-6 col-6 col-md-6">
                                <p className="text-left priceSub">{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</p>
                            </div>
                        </div>
                        {props.invoiceData.discount && <div className="row" style={{marginBottom: '-20px'}}>
                            <div className="col-lg-6 col-6 col-md-6">
                                <p className="text-left priceSub">Discount</p>
                            </div>
                            <div className="col-lg-6 col-6 col-md-6">
                                
                                <p className="text-left priceSub">
                                    {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                </p>
                                
                            </div>
                        </div>}
                        
                        {props.invoiceData.tax && <div className="row" style={{marginBottom: '-20px'}}>
                            <div className="col-lg-6 col-6 col-md-6">
                                <p className="text-left priceSub">Tax</p>
                            </div>
                            <div className="col-lg-6 col-6 col-md-6">
                                
                                <p className="text-left priceSub">
                                    {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                </p>
                                
                            </div>
                        </div>
                        }
                        
                        {props.invoiceData.shipment && <div className="row" style={{marginBottom: '-20px'}}>
                            <div className="col-lg-6 col-6 col-md-6">

                                <p className="text-left priceSub">Shipping</p>
                            </div>
                            <div className="col-lg-6 col-6 col-md-6">
                                
                                <p className="text-left priceSub">{props.invoiceData.currencey} {props.invoiceData.shipment}</p>
                                
                            </div>
                        </div>}
                        <div className="row" style={{marginBottom: '-20px'}}>
                            <div className="col-lg-6 col-6 col-md-6">
                                <p className="text-left priceBalance"><b>BalanceDue</b></p>
                            </div>
                            <div className="col-lg-6 col-6 col-md-6">
                                <p className="text-left priceBalance"><b>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
