
import React, { useEffect } from 'react'
import Color from './Color';
import Template from './Template';

export default function InvoiceTemplate(props) {
    props.ptitle("Invoice Templates");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
  return (
    <>
      {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>l">Home</a></li>
            <li className="breadcrumb-item active">Invoice Designs</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Invoice Designs</h4>
                </div>
                <div className="card-body">
                  <form>
                    {/* <?php foreach ($post as $posts) { ?> */}
                    <Color apiKey = {props.apiKey} bToken = {props.bToken}/>
                    <Template apiKey = {props.apiKey} bToken = {props.bToken} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
