import React from 'react'

export default function Template4(props) {
  return (
    <>
        <section style={{pageBreakAfter: 'always', marginLeft: '0px', width: '580px', Height: '100vh'}}>
            <div>
                <div class="invoice overflow-auto">
                    <div>
                        <header style={{borderBottom: '1px solid'+props.invoiceData.color}}>
                            <div class="row">
                                <div class="col" style={{width: '150px', height: '80px'}}>
                                        {/* <img src={props.invoiceData.logo} data-holder-rendered="true" style={{width: '150px', height: '145px', objectFit: 'contain'}} alt="logo" /> */}
                                </div>
                                <div class="col company-details">
                                    <h2 class="name" style={{color: props.invoiceData.color, fontSize: '25px'}}>
                                            {props.invoiceData.fName}
                                    </h2>
                                    <div style={{fontSize: '14px'}}>{props.invoiceData.fAddress}</div>
                                    <div style={{fontSize: '14px'}}>{props.invoiceData.fPhone}</div>
                                    <p class="infoInvoice2 iconsSocial2 pt-3">
                                        {props.invoiceData.fb && <a href={props.invoiceData.fb} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-facebook"></i>
                                        </a>}
                                        {props.invoiceData.twitter && <a href={props.invoiceData.twitter} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter-square ml-2"></i>
                                        </a>}
                                        {props.invoiceData.insta && <a href={props.invoiceData.insta} className="iconsSocial2" style={{color: props.invoiceData.color}} target="_blank" rel="noreferrer"><i className="fab fa-instagram-square ml-2"></i>
                                        </a>}
                                    </p>
                                </div>
                            </div>
                        </header>
                        <main>
                            <div class="row contacts">
                                <div class="col invoice-to">
                                    <div class="text-gray-light" style={{fontSize: '15px'}}><span style={{textTransform: 'uppercase'}}>{props.invoiceData.invoiceType}</span> TO:</div>
                                    <h2 class="to" style={{fontSize: '16px'}}>{props.invoiceData.cName}</h2>
                                    <div class="address" style={{fontSize: '14px'}}>{props.invoiceData.cAddress}</div>
                                    <div class="email" style={{fontSize: '14px'}}>{props.invoiceData.cPhone}</div>
                                </div>
                                <div class="col invoice-details">
                                    <h3 class="invoice-id" style={{color: props.invoiceData.color, fontSize: '16px'}}><span style={{textTransform: 'uppercase'}}>{props.invoiceData.invoiceType}</span>-{props.invoiceData.invoiceNumber}</h3>
                                    <div class="date" style={{fontSize: '14px'}}>Date: {props.invoiceData.cDate}</div>
                                    {/* <?php if ($posts->invoice_type == "Invoice") { ?> */}
                                    <div class="date" style={{fontSize: '14px'}}>Due Date: {props.invoiceData.dDate}</div>
                                    {/* <?php } ?> */}
                                </div>
                            </div>
                            <table border="0" cellspacing="0" cellpadding="0">
                                <thead style={{padding: '5px'}}>
                                    <tr style={{padding: '5px'}}>
                                        <th style={{fontSize: '12px', padding: '5px'}}>#</th>
                                        <th style={{fontSize: '12px', padding: '5px'}} class="text-left">ITEM DESCRIPTION</th>
                                        <th style={{fontSize: '12px', padding: '5px'}} class="text-right">PRICE</th>
                                        <th style={{fontSize: '12px', padding: '5px'}} class="text-right">QTY</th>
                                        <th style={{fontSize: '12px', padding: '5px'}} class="text-right">TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody style={{padding: '5px'}}>
                                    {props.invoiceData.item.map((element, index)=>{
                                        return <tr style={{padding: '5px'}}>
                                                <td class="no" style={{background: props.invoiceData.color, fontSize: '12px', padding: '5px' }}>{index+1}</td>
                                                <td class="text-left" style={{fontSize: '12px', padding: '5px'}}><h3 style={{color: props.invoiceData.color, fontSize: '12px', padding: '5px'}}>{element.pName}</h3>{element.pDescription}
                                                </td>
                                                <td class="unit" style={{fontSize: '12px', padding: '5px'}}>{props.invoiceData.currencey +" "+element.pPrice}</td>
                                                <td class="qty" style={{fontSize: '12px', padding: '5px'}}>{element.pQty}</td>
                                                <td class="total" style={{background: props.invoiceData.color, fontSize: '12px', padding: '5px'}}>{props.invoiceData.currencey +" "+element.pAmount}</td>
                                            </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2" style={{fontSize: '14px'}}>SUBTOTAL</td>
                                        <td style={{fontSize: '14px'}}>{props.invoiceData.currencey+" "+props.invoiceData.subTotal}</td>
                                    </tr>
                                    {props.invoiceData.discount && <tr>
                                        <td colspan="2" style={{fontSize: '14px'}}></td>
                                        <td colspan="2" style={{fontSize: '14px'}}>DISCOUNT</td>
                                        <td style={{fontSize: '14px'}}>
                                        {props.invoiceData.discountType === 'amount'? props.invoiceData.currencey: '%'} {props.invoiceData.discount}
                                        </td>
                                    </tr>}
                                    {props.invoiceData.tax && <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2" style={{fontSize: '14px'}}>TAX</td>
                                        <td style={{fontSize: '14px'}}>
                                            {props.invoiceData.taxType === 'amount'? props.invoiceData.currencey: "%"} {props.invoiceData.tax}
                                        </td>
                                    </tr>}
                                    {props.invoiceData.shipment && <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2" style={{fontSize: '14px'}}>SHIPMENT</td>
                                        <td style={{fontSize: '14px'}}>{props.invoiceData.currencey} {props.invoiceData.shipment}</td>
                                    </tr>}
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2" style={{borderTop: '1px solid'+props.invoiceData.color, color: props.invoiceData.color, fontSize: '14px' }}>GRAND TOTAL</td>
                                        <td style={{borderTop: '1px solid'+props.invoiceData.color, color: props.invoiceData.color, fontSize: '14px'}}>{props.invoiceData.currencey} {props.invoiceData.grandTotal}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            
                            <div class="thanks" style={{fontSize: '14px'}}>Thank you!</div>
                            {props.invoiceData.aDetail && <div class="notices" style={{borderLeft: '6px solid'+props.invoiceData.color}}>
                                <div style={{fontSize: '14px'}}>ADDITIONAL DETAILS:</div>
                                <div class="notice" style={{fontSize: '14px'}}>{props.invoiceData.aDetail}</div>
                            </div>}
                            
                            
                        </main>
                        <footer>
                            {props.invoiceData.terms && <div>{props.invoiceData.terms}</div>}
                                
                        </footer>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    </>
  )
}
