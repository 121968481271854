import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Estimate(props) {
  props.ptitle("All Estimates");
  useEffect(()=>{
      props.urlfun();
  }, [props]);
  const [search, setSearch] = useState('');
  const [progress, setProgress] = useState(0);
  const [estimates, setEstimates] = useState([]);
  const [fiteredEstimates, setFiteredEstimates] = useState([]);
  // get all Data
  const getDataFunc = async (apiKey, bToken) => {
    setProgress(20);
    const token = bToken; 
    const apiResult = await axios({
      method: 'get',
      url: `${apiKey}fetch_estimates/${JSON.parse(localStorage.getItem('user_email'))}`,
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    setProgress(100);
    setFiteredEstimates(apiResult.data.source);
    setEstimates(apiResult.data.source);
  }

  const column = [
    {
      name: "Sr",
      cell: (row, index) => index + 1,
    },
    {
      name: "Estimate Number",
      selector: (row)=>row.invoice_number,
      sortable: true
    },
    {
      name: "Estimate To",
      selector: (row)=>row.person_to_name,
      sortable: true
    },
    {
      name: "Price",
      selector: (row)=>row.product_grand_total,
      sortable: true
    },
    {
      name: "Date",
      selector: (row)=>row.invoice_current_date,
      sortable: true
    },
    {
      name: "Status",
      cell: row=> <>
      {row.status === 'unpaid'?
      <span className="btn btn-primary text-white" onClick={(e)=>handleChangeStatus(e, row.invoice_number, 'paidinvoice')} >Unpaid</span>
      :
      <span className="btn btn-danger text-white" onClick={(e)=>handleChangeStatus(e, row.invoice_number, 'unpaid')} >Paid</span>
      }
      </>
    },
    {
      name: "Aciton",
      cell: row=> <>
      <span className="btn btn-primary mr-2" onClick={(e)=>handleLink(e, row.invoice_number, '/Preview/' )}><i className="fa fa-eye" aria-hidden="true"></i></span> 
      <span className="btn btn-primary mr-2" onClick={(e)=>handleLink(e, row.invoice_number, '/Download/' )}><i className="fa fa-download" aria-hidden="true"></i></span></>
    }
  ]


  useEffect(()=>{
    getDataFunc(props.apiKey, props.bToken)
  }, [props.apiKey, props.bToken]);
  useEffect(()=>{
    const result = estimates.filter((estimates)=>{
      return estimates.invoice_number.toLowerCase().match(search.toLowerCase());
    });
    setFiteredEstimates(result);
  }, [search, estimates]);


  const handleLink = (e, id, hlink) => {
    if(hlink === '/Download/'){
      window.open(`https://invoicies.com/Download/${id}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    }
    else{
      window.open(`https://invoicies.com/Preview/${id}`, '_blank');
    }
  }

  const handleChangeStatus = async (e, id, stat) =>{
    const token = props.bToken; 
    const apiResult = await axios({
      method: 'post',
      url: `${props.apiKey}update_invoice_status`,
      data: {
        invoice_number: id, status: stat
      },
      headers:{
          Accept: 'application/javascript',
          Authorization: `Bearer ${token}`
      }
    });
    setProgress(100);
    if(apiResult.data.status === 200){
      toast.success("Invoice Status Changed", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
      getDataFunc(props.apiKey, props.bToken);
    }
    // get_details_invoice
  }
  const handleFilerTab = (e, sta)=>{

    const result = estimates.filter((estimates)=>{
      return estimates.status.toLowerCase().match(sta);
    });
    setFiteredEstimates(result);
  }
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">All Estimates</li>
          </ul>
        </div>
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span type="button" className="btn btn-danger text-white" id="alInvoice" style={{backgroundColor: '#357abc'}} onClick={(e)=>handleFilerTab(e, 'p')}>All Estimates</span> 
                      <span type="button" className="btn btn-danger text-white" id="alunpaid" onClick={(e)=>handleFilerTab(e, 'unpaid')}>UnPaid</span> 
                      <span type="button" className="btn btn-danger text-white" id="alpaid" onClick={(e)=>handleFilerTab(e, 'paidinvoice')}>Paid</span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <DataTable 
                    columns={column} 
                    data={fiteredEstimates} 
                    pagination
                    fixedHeader
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                      <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                    }
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
