import React, { useEffect, useState } from 'react'
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
export default function Header(props) {
    const [navData, setnavData] = useState([]);
    const [imgUrl, setimgUrl] = useState('');
    const navigate = useNavigate();
    const set_Header = async (apiKey, bToken) =>{
        const token = bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_settings`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          setimgUrl(apiResult.data.src);
          setnavData(apiResult.data.list);
    }
    useEffect(()=>{
        set_Header(props.apiKey, props.bToken);
    }, [props]);
    const handleCreateInvoice = () =>{
        if(localStorage.getItem('session_status') === '200'){
            navigate('create-invoice');
        }
        else{
            navigate('login');
        }
    }
    const handleLogout = async() =>{
        const token = props.bToken;
        const apiResult = await axios({
            method: 'get',
            url: `${props.apiKey}logout`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
          });
          console.log(apiResult);
          if(apiResult.status === 200){
            localStorage.setItem('session_status', JSON.stringify(201));
            navigate("/");
          }
    }

const closeNav = () =>{
    document.getElementById("mySidenav").style.width = "0";
}
const openNav = () =>{
    document.getElementById("mySidenav").style.width = "376px";
}
  return (
    <>

        <Helmet>
          <link rel="apple-touch-icon" href={props.favicon} />
          <link rel="icon" href={props.favicon} />
          <link rel="stylesheet" href="/css/slick.css" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" integrity="sha512-6lLUdeQ5uheMFbWm3CP271l14RsX1xtx+J5x2yeIDkkiBpeVTNhTqijME7GgRKKi6hCqovwCoBTlRBEC20M8Mg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css" />
	       <link href="https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos.css" rel="stylesheet" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css" />
            <link rel="stylesheet" href="/css/style.css" />
            <link rel="stylesheet" href="/css/responsive.css" />
            <link rel="stylesheet" href="/boxicons/css/boxicons.min.css" />
            <link rel="stylesheet" href="/remixicon/remixicon.css" />
            <link rel="stylesheet" href="/css/icofont/icofont.min.css" />
            <title>Home - Invoice Maker</title>
        </Helmet>

        <div className="mainHeaderBg sticky">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6 col-6">
                        <div className="t_cY">
                            <Link aria-current="page" className="G_Q smallScreenSet" rel="home" to="/">
                            {navData.map((data)=> {
                                return <div key={data.id}>
                                    {data.priority === 'image' ? 
                                        <img src={imgUrl+data.logo_image} className="logoImg" alt="Logo" /> :
                                        <h3 id="mystyle" style={{ color: data.logo_color }}>{data.site_logo}</h3>
                                    }
                                </div>
                            })}
                            </Link>
                            <div className="t_cZ">
                                <button className="J_fd" type="button">
                                    <span className="J_fh">
                                        <span className="J_fg">
                                            <span className="J_fj">Open Menu</span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-6 col-6">
                        <div className='text-right mobileviewnav'>
                            <div id="mySidenav" className="sidenav text-left">
                                <a href="javascript:void(0)" class="closebtn" onClick={closeNav} >&times;</a>
                                <Link to="/" onClick={closeNav}>Home</Link>
                                <span onClick={handleCreateInvoice} >Create Invoice</span>
                                <Link to="/contact-us" onClick={closeNav}>Contact Us</Link>
                                <Link to="/blogs" onClick={closeNav}>Blog</Link>
                                {localStorage.getItem('session_status') === '200'?
                                    <>
                                            <Link to="/dashboard" onClick={closeNav}>Account</Link>
                                            <span onClick={handleLogout}>Logout</span>
                                    </>         
                                    :
                                    <>
                                            <Link to="/login" onClick={closeNav}>Login</Link>
                                            <Link to="/signup" onClick={closeNav}>Signup</Link>
                                    </>                        
                                }
                            </div>
                            <span style={{fontSize: '30px', cursor: 'pointer'}} onClick={openNav}>&#9776;</span>
                        </div>
                        <nav className="navbar navbar-expand-lg navbar-light mobilealign" id="myHeader">
                            <div className="container">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item px-3 mt-2">
                                        <Link className="nav-link F_d4" to="/">Home</Link>
                                        </li>
                                        <li className="nav-item px-3 mt-2">
                                        <span onClick={handleCreateInvoice} className="nav-link F_d4 cPointer">Create Invoice</span>
                                        </li>
                                        <li className="nav-item px-3 mt-2">
                                        <Link className="nav-link F_d4" to="/contact-us">Contact Us</Link>
                                        </li>
                                        <li className="nav-item px-3 mt-2">
                                        <Link className="nav-link F_d4" to="/blogs">Blog</Link>
                                        </li>
                                        {localStorage.getItem('session_status') === '200'?
                                            <>
                                                <li className="nav-item px-3 mt-2">
                                                    <Link className="nav-link F_d4" to="/dashboard">Account</Link>
                                                </li>
                                                <li className="nav-item px-3 mt-2">
                                                    <span className="nav-link F_d4" style={{cursor: 'pointer'}} onClick={handleLogout}>Logout</span>
                                                </li>
                                                
                                            </>         
                                            :
                                            <>
                                                <li className="nav-item px-3 mt-2">
                                                    <Link className="nav-link F_d4" to="/login">Login</Link>
                                                </li>
                                                <li className="nav-item px-3 mt-2">
                                                    <Link className="nav-link F_d4" to="/signup">Signup</Link>
                                                </li>
                                            </>                        
                                        }
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
