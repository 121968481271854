import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
export default function List(props) {

  const [newItem, setNewItem] = useState(201);
  const [updateItem, setUpdateItem] = useState(201);
  const [search, setSearch] = useState('');
  const [itemlist, setItemList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  useEffect(()=>{
    if(newItem === 200){
      toast.success("Item Resgisterd Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
    localStorage.setItem('item_create_msg', JSON.stringify(201));
    }
    else{
      setNewItem(JSON.parse((localStorage.getItem('item_create_msg'))));
    }
  }, [newItem]);

  useEffect(()=>{
    if(updateItem === 200){
        toast.success("Item Updated Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      localStorage.setItem('item_update_message', JSON.stringify(201));
    }
    else{
      setUpdateItem(JSON.parse(localStorage.getItem('item_update_message')));
    }
  }, [updateItem]);

  // Get List of Items
  const getItemList = async (apiKey, bToken) =>{
    setProgress(30);
    const token = bToken; 
    const apiResult = await axios({
        method: 'get',
        url: `${apiKey}fetch_items/${localStorage.getItem('user_id')}`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    setFilteredList(apiResult.data.source);
    setItemList(apiResult.data.source);
    setProgress(100);
  }
  useEffect(()=>{
    getItemList(props.apiKey, props.bToken);
  }, [props.apiKey, props.bToken])

  const column = [
    {
      name: "Sr",
      cell: (row, index) => index + 1,
    },
    {
      name: "Item Name",
      selector: (row)=>row.item_name,
      sortable: true
    },
    {
      name: "Item Price",
      selector: (row)=>row.item_price,
      sortable: true
    },
    {
      name: "Aciton",
      cell: row=> <>
      <span className="btn btn-primary mr-2" onClick={()=> handleEditItem(row.id)}>Edit</span>
      <span className="btn btn-danger" onClick={()=> handleDeleteItem(row.id)}>Delete</span></>
    }
  ];
  useEffect(()=>{
    const result = itemlist.filter((item)=>{
      return item.item_name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredList(result);
  }, [search, itemlist]);
  // Edit Item
  const handleEditItem = (item_id) =>{
    localStorage.setItem("edit_item_id", JSON.stringify(item_id));
    navigate("/dashboard/edit-item");
  }

  // Delete
  const handleDeleteItem = async (item_id) =>{
    const token = props.bToken; 
    const apiResult = await axios({
        method: 'get',
        url: `${props.apiKey}delete_item/${item_id}`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.status === 200){
      getItemList(props.apiKey, props.bToken);
      toast.success("Item Deleted Successfully", {
        position: "bottom-right",
        aposition: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });

    }
  }

  props.ptitle("All Items");
  useEffect(()=>{
    props.urlfun();
}, [props]);
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="<?php echo base_url('Dashboard'); ?>">Home</a></li>
            <li className="breadcrumb-item active">All Items</li>
          </ul>
        </div>
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4>All Items</h4>
                    </div>
                    <div>
                      <Link to="/dashboard/add-item" type="button" className="btn btn-primary">Add Item</Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                    <DataTable 
                  columns={column} 
                  data={filteredList} 
                  pagination
                  fixedHeader
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <>Search: <input type="text" className='ml-2 form-control d-flex w-25' placeholder='Search Here.............' onChange={(e)=>setSearch(e.target.value)} /></>
                  }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
