import React, { useEffect, useState } from 'react'
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function BankAccount(props) {
    const [accountHolderName, setAccountHolderName] = useState([]);
    const [bankId, setBankId] = useState([]);
    const [bankName, setBankName] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountIbanNumber, setAccountIbanNumber] = useState('');
    const [progress, setProgress] = useState(0);
    const [save, setSave] = useState('save');
    props.ptitle("Bank Account");
    useEffect(()=>{
        props.urlfun();
    }, [props]);
    const getData = async (apiKey, bToken) => {
        setProgress(40);
        const token = bToken; 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}bank_account/${JSON.parse(localStorage.getItem("user_email"))}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        if(apiResult.data.bank_details === null){
          setProgress(100);
          setSave('save');
        }else{
          let data = apiResult.data.bank_details;
          setBankId(data.id);
          setAccountHolderName(data.account_holder_name);
          setBankName(data.bank_name);
          setBranchCode(data.branch_code);
          setAccountNumber(data.account_number);
          setAccountIbanNumber(data.account_iban_number);
          setProgress(100);
          setSave('update');
        }
        
    }
    useEffect(()=>{
      getData(props.apiKey, props.bToken);
    }, [props.apiKey, props.bToken]);

  // Save
  const handleSaveBankAccountDetail = async (event) =>{
    event.preventDefault();
    setProgress(40);
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}create_bank_account`,
        data: {
            accountHolderName, bankName, branchCode, accountNumber, accountIbanNumber, email: JSON.parse(localStorage.getItem('user_email'))
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.status === 200){
      toast.success("Bank Account Details Created Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      setProgress(100);
      getData(props.apiKey, props.bToken);
      setSave('update');
    }
  };
  // Update
  const handleUpdateBankAccountDetail = async (event) =>{
    event.preventDefault();
    setProgress(40);
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}update_bank_account `,
        data: {
            accountHolderName, bankName, branchCode, accountNumber, accountIbanNumber, bankId
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    if(apiResult.data.status === 200){
      toast.success("Bank Account Details Updated Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
      });
      getData(props.apiKey, props.bToken);
      setProgress(100);
    }
  }
  return (
    <>
      <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">Bank Account Details</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Bank Account Details</h4>
                </div>
                <div className="card-body">
                    <form>
                      {save === 'update'? <>
                        <input type="hidden" value={bankId} onChange={(e)=>setBankId(e.target.value)} name="bankId" className="form-control" />
                        </>:
                        <></>
                      }
                      <div className="form-group">
                        <label><strong>Account Holder Name</strong></label>
                        <input type="text" placeholder="Account Holder Name" value={accountHolderName} onChange={(e)=>setAccountHolderName(e.target.value)} name="accountHolderName" className="form-control" required />
                      </div>
                      <div className="form-group">
                        <label><strong>Bank Name</strong></label>
                        <input type="text" placeholder="Bank Name" value={bankName} onChange={(e)=>setBankName(e.target.value)} name="bankName" className="form-control" required />
                      </div>
                      <div className="form-group">
                        <label><strong>Branch Code</strong></label>
                        <input type="text" placeholder="Branch Code" value={branchCode} onChange={(e)=>setBranchCode(e.target.value)} name="branchCode" className="form-control" required />
                      </div>
                      <div className="form-group">
                        <label><strong>Account Number</strong></label>
                        <input type="text" placeholder="Account Number" value={accountNumber} onChange={(e)=>setAccountNumber(e.target.value)} name="accountNumber" className="form-control" required />
                      </div>
                      <div className="form-group">
                        <label><strong>IBAN Number</strong></label>
                        <input type="text" placeholder="IBAN Number" value={accountIbanNumber} onChange={(e)=>setAccountIbanNumber(e.target.value)} name="accountIbanNumber" className="form-control" required />
                      </div>
                      <div className="form-group">       
                        {save === 'save'? <>
                        <input type="submit" value="Save" onClick={handleSaveBankAccountDetail} className="btn btn-primary" />
                        </>:
                        <>
                        <input type="submit" value="Update" onClick={handleUpdateBankAccountDetail} className="btn btn-primary" />
                        </>
                        }
                        
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
