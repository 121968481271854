import React, { useEffect, useState } from 'react';
import type0 from '../../../images/Templates/type0.png';
import type1 from '../../../images/Templates/type1.png';
import type2 from '../../../images/Templates/type2.png';
import type4 from '../../../images/Templates/type4.png';
import type5 from '../../../images/Templates/type5.png';
import type6 from '../../../images/Templates/type6.png';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function Template(props) {
  const [template, setTemplate] = useState('0');
  const [progress, setProgress] = useState(0);
  // First Check tthe Default template
  const checkTemplate = async(apiKey, bToken) =>{
    setProgress(40);
    const token = bToken;
    const apiResult = await axios({
        method: 'get',
        url: `${apiKey}get_user_detail/${JSON.parse(localStorage.getItem('user_id'))}`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
      });
      if(apiResult.data.success === true){
        setTemplate(apiResult.data.user.invoice_type);
        setProgress(100);
        localStorage.setItem("invoice_type", JSON.stringify(apiResult.data.user.invoice_type));
      }
  }
  useEffect(()=>{
    checkTemplate(props.apiKey, props.bToken);
  }, [props.apiKey, props.bToken]);

  // save new template
  const changeDesign = async(e, value) =>{
    setProgress(40);
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}update_user_detail`,
        data: {
            invoice_type: value, id: JSON.parse(localStorage.getItem("user_id"))
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
      });
      if(apiResult.data.status === 200){
        checkTemplate(props.apiKey, props.bToken);
        setProgress(100);
        toast.success("Template Updated Successfully", {
          position: "bottom-right",
          aposition: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        });
      }
  }
  return (
    <>
    <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
        <div className="row">
            <div className="col-lg-4">
            <h5>Default {template === 'default' || template === '0'?<i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i>:""} </h5>
            <img src={type0} alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onClick={(e)=>changeDesign(e ,'default')} />
            </div>
            <div className="col-lg-4">
            <h5>Design 1 {template === '1'?<i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i>:""} </h5>
            <img src={type1} alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onClick={(e)=>changeDesign(e, 1)} />
            </div>
            <div className="col-lg-4">
            <h5>Design 2 {template === '2'?<i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i>:""} </h5>
            <img src={type2} alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onClick={(e)=>changeDesign(e, 2)} />
            </div>
            <div className="col-lg-4 mt-5">
            <h5>Design 3 {template === '3'?<i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i>:""} </h5>
            <img src={type4} alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onClick={(e)=>changeDesign(e, 3)} />
            </div>
            <div className="col-lg-4 mt-5">
            <h5>Design 4 {template === '4'?<i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i>:""} </h5>
            <img src={type5} alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onClick={(e)=>changeDesign(e, 4)} />
            </div>
            <div className="col-lg-4 mt-5">
            <h5>Design 5 {template === '5'?<i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i>:""} </h5>
            <img src={type6} alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onClick={(e)=>changeDesign(e, 5)} />
            </div>
            {/* <?php $num = 6;
            foreach ($design as $designs) {?> */}
                {/* <div className="col-lg-4 mt-5">
                <h5>Design <i className="fa fa-check-circle checkedIcon" aria-hidden="true"></i> </h5>
                <img src="<?php echo base_url('uploads/'.$designs->design_image); ?>" alt="template Design" style={{width: '100%', height: '364px', cursor: 'pointer'}} onclick="changeDesign(this)" value="<?php echo  $designs->design_number; ?>" />
                </div> */}
        </div>
        <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover />
    </>
  )
}
