import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
export default function Edit(props) {
    
    const [countryList, setCountryList] = useState([]);
    const [name, setName] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const[country, setCountry] = useState('170');
    const [progress, setProgress] = useState(0);
    const [msg, setMsg] = useState(201);
    const navigate = useNavigate();
  props.ptitle("Edit Client");
  useEffect(()=>{
    props.urlfun();
}, [props]);

// Fetch Countries
const countries = async (apiKey, bToken) =>{
    const token = bToken; 
    setProgress(10);
    const apiResult = await axios({
        method: 'get',
        url: `${apiKey}fetch_countries`,
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    setProgress(70);
    setCountryList(apiResult.data.countries);
  };
useEffect(()=>{
  countries(props.apiKey, props.bToken);
}, [props.apiKey, props.bToken]);

// Client Data
    const getData = async (apiKey, bToken) => {
        setProgress(40);
        const token = bToken; 
        const apiResult = await axios({
            method: 'get',
            url: `${apiKey}get_client/${localStorage.getItem("edit_client_id")}`,
            headers:{
                Accept: 'application/javascript',
                Authorization: `Bearer ${token}`
            }
        });
        let data = apiResult.data.client;
        setAddress(data.address);
        setCity(data.city);
        setCountry(data.country);
        setMobileNumber(data.mobile_number);
        setName(data.name);
        setStreet(data.street);
        setProgress(100);
    }
useEffect(()=>{
    getData(props.apiKey, props.bToken);
}, [props.apiKey, props.bToken]);

// Update
  const handleEditClient = async (event) =>{
    event.preventDefault();
    const token = props.bToken;
    const apiResult = await axios({
        method: 'post',
        url: `${props.apiKey}update_clients `,
        data: {
            name, mobile_number, country, address, city, street, id: JSON.parse(localStorage.getItem('edit_client_id'))
        },
        headers:{
            Accept: 'application/javascript',
            Authorization: `Bearer ${token}`
        }
    });
    setMsg(apiResult.data.status);
  }
  useEffect(()=>{
    if(msg === 200){
      localStorage.setItem('client_update_message', JSON.stringify(msg));
      navigate('/dashboard/client-list');
    }
  }, [msg, navigate]);
  return (
    <>
        <LoadingBar 
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        />
        {/* <!-- Breadcrumb--> */}
      <div className="breadcrumb-holder">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active">Edit Client</li>
          </ul>
        </div>
      </div> 
      <section className="forms">
        <div className="container-fluid">
          {/* <!-- Page Header--> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center">
                  <h4>Edit Client</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label><strong>Name</strong></label>
                      <input type="text" placeholder="Name" onChange={(e)=>setName(e.target.value)} name="name" className="form-control" value={name} />
                    </div>
                    <div className="form-group">
                      <label><strong>Mobile Number</strong></label>
                      <input type="text" placeholder="Mobile Number" onChange={(e)=>setMobileNumber(e.target.value)} name="mobile_number" className="form-control" value={mobile_number} />
                    </div>
                    
                    <div className="form-group">
                      <label><strong>City</strong></label>
                      <input type="text" placeholder="City" onChange={(e)=>setCity(e.target.value)} name="city" className="form-control" value={city} />
                    </div>
                    <div className="form-group">
                      <label><strong>Street Address</strong></label>
                      <input type="text" placeholder="Street Address" onChange={(e)=>setStreet(e.target.value)} name="street" className="form-control" value={street} />
                    </div>
                    <div className="form-group">
                      <label><strong>Country</strong></label>
                      <select className="form-control" style={{cursor: 'pointer'}} name="country" value={country} onChange={(e)=>setCountry(e.target.value)}>
                        { countryList.map((country)=>{
                          return <option key={country.id} value={country.id} >{country.country_name}</option>
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label><strong>Full Address</strong></label>
                      <input type="text" placeholder="Address" onChange={(e)=>setAddress(e.target.value)} name="address" className="form-control" value={address} />
                    </div>
                    <div className="form-group">       
                      <input type="submit" value="Update" className="btn btn-primary" onClick={handleEditClient} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
