import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default function Signup(props) {
    props.ptitle('Signup');
    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [btn, setBtn] = useState('201');
    const navigate = useNavigate();
    useEffect(()=>{
        props.urlfun();
    }, [props])
    const handleSignUp = async (event) =>{
        event.preventDefault();
        if( (name === "" || email === "" ) ){
            toast.error("Please fill all the credentials", {
                position: "bottom-right",
                aposition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            });
        }
        else
        {
            if(email.search('@') === -1){
                toast.error("Please use valid email!", {
                    position: "bottom-right",
                    aposition: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });
            }
            else{
                const token = props.bToken;
                const apiResult = await axios({
                    method: 'post',
                    url: `${props.apiKey}create_user`,
                    data: {
                        name, email, password
                    },
                    headers:{
                        Accept: 'application/javascript',
                        Authorization: `Bearer ${token}`
                    }
                });
                if(apiResult.data.status === 200 ){
                    localStorage.setItem("user_create_status", JSON.stringify(apiResult.data.status));
                    navigate('/login');
                }
                else{
                    toast.error("Email already Existed!", {
                        position: "bottom-right",
                        aposition: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark'
                    });
                }
            }
        }
    }

    const handleConfirmPassword = (e) =>{
        if(password === e.target.value){
            window.$('#password_response').html('<span style="color: #4289ff; font-size: 16px;">Password Matched</span>');
            setBtn('200');
        }
        else{
            window.$('#password_response').html('<span style="color: #4289ff; font-size: 16px;">Password Not Matched</span>');
            setBtn('201');
        }
    }
  return (
    <>
        <div className="page login-page">
            <div className="container resInvoiceCont">
                <div className="form-outer text-center d-flex align-items-center">
                <div className="form-inner">
                    <div className="logo text-uppercase"><span style={{ color:'#357abc !important' }}>Create</span> <strong className="text-primary" style={{color: '#b1b1b1 !important' }}>Account</strong></div>
                    <form className="text-left form-validate">
                    <div className="form-group-material">
                        <input id="login-name" type="text" onChange={(e)=>setname(e.target.value)} name="name" required data-msg="Please enter your name" className="input-material" placeholder="Name" />
                        <div id="email_response" ></div>
                    </div>
                    <div className="form-group-material">
                        <input id="login-Email" type="email" onChange={(e)=>setemail(e.target.value)} name="email" required data-msg="Please enter your email" className="input-material" placeholder="Email" />
                        <div id="email_response" ></div>
                    </div>
                    <div className="form-group-material">
                        <input id="login-password" type="password" onChange={(e)=>setpassword(e.target.value)} name="password" required data-msg="Please enter your password" className="input-material checkPassword" placeholder="Password" />
                    </div>
                    <div className="form-group-material">
                        <input id="login-confirm-password" type="password" name="confirm_password" required data-msg="Please enter your password" className="input-material confirmPassword" placeholder="Re-Enter Password" onChange={handleConfirmPassword} />
                        <div id="password_response" ></div>
                    </div>
                    <div className="form-group text-center">
                        <input type="submit" name="Signup" style={{background:'#1a90c6 !important', borderColor: '#1a90c6 !important', borderRadius: '0'}} value="Signup" className="btn btn-primary lg-bt signUpButn res-login-btn" onClick={handleSignUp} disabled={btn==='201'? true : false} />
                    </div>
                    <div className="form-group text-center">
                        <small>Already Have Account ?</small><Link id="login" to="/login" className="btn btn-primary fgtbtn"><small style={{color: '#357abc', fontSize: '15px' }}>SignIn</small></Link>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            <ToastContainer position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
draggable
 />
    </>
  )
}
